import { MouseEvent, useCallback, useMemo } from 'react'
import { useAppSelector } from '@hooks/reduxHooks'
import { Link } from 'react-router-dom'
import Badge from './Badge'
import dateFormat from '@utils/dateFormat'
import isEqual from 'lodash/isEqual'
import { ShortEvent } from '@customTypes/event'
import logo from '@images/logo-light.svg'
import Icon from '@components/Icon'

type EventItemProps = {
  event: ShortEvent
}

const EventItem = ({ event }: EventItemProps) => {
  const userTimeZone =
    useAppSelector(state => state.user.user?.timezone, isEqual) ||
    Intl.DateTimeFormat().resolvedOptions().timeZone

  const date = useMemo(
    () => userTimeZone && dateFormat.formatInTimeZone(event.startDate, 'dd.MM.yyyy', userTimeZone),
    [event, userTimeZone]
  )

  const onCoordinatesClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      // e.preventDefault()
      e.stopPropagation()

      window.open(
        `https://www.google.com/maps/search/?api=1&query=${event.coordinates}`,
        '_blank',
        'noopener,noreferrer'
      )
    },
    [event.coordinates]
  )

  return (
    <article className="ms-default-card">
      <div className="ms-default-card-flip">
        <div className="ms-default-card-front">
          <Link to={`/events/${event.url}`}>
            <Badge type={event.isOnline ? 'watch' : 'buy'} />
            {/* <Icon name="crown" size={34} /> */}
            <div className="ms-default-card-media">
              {event.verticalImg ? (
                <img src={event.verticalImg} alt="" />
              ) : (
                <img
                  src="https://mtzfront.s3.eu-west-3.amazonaws.com/default_vertical.jpg"
                  alt=""
                />
              )}
            </div>
          </Link>
        </div>
        <div className="ms-default-card-back">
          <img className="card-back-logo" src={logo} alt="" />
          <Link className="card-back-link" to={`/events/${event.url}`}></Link>
          <div className="ms-default-card-artist">{event.artist}</div>
          <div className="ms-default-card-description">{event.description}</div>
          <div className="ms-default-card-title">Live on {date}</div>
          <div className="ms-default-card-location">
            {event.title}, {event.city}
            {/* <button onClick={onCoordinatesClick} className="icon-button transparent">
              <Icon name={'logout'} size={24} />
            </button> */}
          </div>
        </div>
      </div>

      <div className="ms-default-card-body mt-8">
        <div className="ms-default-card-title">Live on {date}</div>
        <div className="ms-default-card-artist">{event.artist}</div>
        <div className="ms-default-card-location">
          {event.title}, {event.city}
        </div>
      </div>
    </article>
  )
}

export default EventItem
