import agentServices from "@api/services/agentServices"
import SuccessfulBlockForNewAgent from "@components/agent/SuccessfulBlockForNewAgent"
import { BecomeAgentDataForm } from "@customTypes/agent"
import { CustomErrorResponse } from "@customTypes/common"
import { createAsyncThunk } from "@reduxjs/toolkit"
import { Slide, toast } from "react-toastify"

const addNewAgent = createAsyncThunk(
  'auth/addNewAgent',
  async (body: BecomeAgentDataForm, { fulfillWithValue, rejectWithValue }) => {
    try {
      await agentServices.addNewAgent(body)

      toast.success(SuccessfulBlockForNewAgent,
        {
          closeOnClick: false,
          position: 'top-right',
          transition: Slide,
          pauseOnFocusLoss: false,
          autoClose: 3000,
        })

      return fulfillWithValue('success')
    } catch (e) {
      const err = e as CustomErrorResponse


      toast.error(err.message || 'Something went wrong!',
        {
          position: 'top-right',
          transition: Slide,
          pauseOnFocusLoss: false,
          autoClose: 3000,
        })

      return rejectWithValue('error')
    }
  })

const getEventListForAgent = createAsyncThunk(
  'event/getEventListForAgent', async (_, { rejectWithValue, dispatch }) => {

    try {
      const res = await agentServices.getEventListForAgent()


      return res
    } catch (e) {
      const err = e as CustomErrorResponse

      return rejectWithValue('error')
    }
  }
)

const getOrderListForAgent = createAsyncThunk(
  'event/getOrderListForAgent', async (_, { rejectWithValue, dispatch }) => {

    try {
      const res = await agentServices.getOrderListForAgent()


      return res
    } catch (e) {
      const err = e as CustomErrorResponse

      return rejectWithValue('error')
    }
  }
)

const sendMailAgain = createAsyncThunk(
  'event/sendMailAgain', async (orderId: number, { rejectWithValue, fulfillWithValue }) => {

    try {
      await agentServices.sendMailAgain(orderId)

      toast.success('Email has been sent!',
        {
          position: 'top-right',
          transition: Slide,
          pauseOnFocusLoss: false,
          autoClose: 3000,
        })

      return fulfillWithValue('success')
    } catch (e) {
      const err = e as CustomErrorResponse

      toast.error(`${err.errorCode} ${err.message}` || 'Something went wrong!',
        {
          position: 'top-right',
          transition: Slide,
          pauseOnFocusLoss: false,
          autoClose: 3000,
        })

      return rejectWithValue('error')
    }
  }
)


const agentActionCreators = {
  addNewAgent,
  getEventListForAgent,
  getOrderListForAgent,
  sendMailAgain,
}

export default agentActionCreators