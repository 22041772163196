import { useCallback } from 'react'

type SocialMediaButtonProps = {
  id: 'google' | 'tikTok'
  svg: JSX.Element
  text: string
  onParentClick: (id: 'google' | 'tikTok') => void
}

const SocialMediaButton = ({ id, svg, text, onParentClick }: SocialMediaButtonProps) => {
  const onClick = useCallback(() => {
    onParentClick(id)
  }, [id, onParentClick])

  return (
    <button
      className="button secondary large"
      style={{ maxWidth: '320px', minWidth: '320px' }}
      onClick={onClick}
    >
      {svg}
      <span className="h5">{text}</span>
    </button>
  )
}

export default SocialMediaButton
