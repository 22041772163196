import React, { useMemo } from 'react'
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share'
import CopyButton from './CopyButton'
import { SocialMediaIcons } from '@helpers/socialMediaIcons'

type ShareSocialMediaListProps = {
  linkPart: string
}

const ShareSocialMediaList = ({ linkPart }: ShareSocialMediaListProps) => {
  const shareUrl = useMemo(() => `https://${window.location.hostname}/events/${linkPart}`, [linkPart])

  return (
    <div className="share text-center">
      <div className="share-divider" />
      <div className="h5 mb-16">Share via:</div>
      <div className="display-flex justify-content-center gap-20">
        <FacebookShareButton url={shareUrl}>
          <div className="icon-button share-icon">
            <SocialMediaIcons.Facebook width={60} height={60} />
          </div>
        </FacebookShareButton>
        <WhatsappShareButton url={shareUrl}>
          <div className="icon-button share-icon">
            <SocialMediaIcons.WhatsApp width={75} height={75} />
          </div>
        </WhatsappShareButton>
        <TwitterShareButton url={shareUrl}>
          <div className="icon-button share-icon">
            <SocialMediaIcons.X width={60} height={60} />
          </div>
        </TwitterShareButton>

        <CopyButton text={shareUrl} />
      </div>
    </div>
  )
}

export default ShareSocialMediaList
