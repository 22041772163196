import { Dispatch, SetStateAction, useCallback, useEffect, useRef } from 'react'

const useCountdown = (
  targetDate: string,
  withMilliseconds: boolean,
  stopTimerTime: number,
  setIsCountDownFinished: Dispatch<SetStateAction<boolean>>
) => {
  const dayRef = useRef<HTMLParagraphElement>(null)
  const hoursRef = useRef<HTMLParagraphElement>(null)
  const minutesRef = useRef<HTMLParagraphElement>(null)
  const secondsRef = useRef<HTMLParagraphElement>(null)
  const msRef = useRef<HTMLParagraphElement>(null)

  const getReturnValues = useCallback(() => {
    const dateFormat = (val: number) => val.toString().padStart(2, '0')
    const countDown = new Date(targetDate).getTime() - new Date().getTime()

    if (dayRef.current) {
      const days = dateFormat(Math.floor(countDown / (1000 * 60 * 60 * 24)))

      if (dayRef.current.innerHTML !== days) {
        dayRef.current.innerHTML = days
        dayRef.current.setAttribute('data-text', days)
      }
    }

    if (hoursRef.current) {
      const hours = dateFormat(Math.floor((countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)))

      if (hoursRef.current.innerHTML !== hours) {
        hoursRef.current.innerHTML = hours
        hoursRef.current.setAttribute('data-text', hours)
      }
    }

    if (minutesRef.current) {
      const minutes = dateFormat(Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60)))

      if (minutesRef.current.innerHTML !== minutes) {
        minutesRef.current.innerHTML = minutes
        minutesRef.current.setAttribute('data-text', minutes)
      }
    }

    if (secondsRef.current) {
      const seconds = dateFormat(Math.floor((countDown % (1000 * 60)) / 1000))
      secondsRef.current.innerHTML = seconds
      secondsRef.current.setAttribute('data-text', seconds)
    }

    if (msRef.current) {
      const ms = dateFormat(Math.floor(countDown % 1000))
        .toString()
        .substring(0, 2)
      msRef.current.innerHTML = ms
      msRef.current.setAttribute('data-text', ms)
    }

    if (countDown < stopTimerTime) {
      setIsCountDownFinished(true)
    }
  }, [setIsCountDownFinished, stopTimerTime, targetDate])

  useEffect(() => {
    getReturnValues()
  }, [getReturnValues])

  useEffect(() => {
    const intervalTime = withMilliseconds ? 0 : 1000
    const interval = setInterval(getReturnValues, intervalTime)

    return () => {
      clearInterval(interval)
    }
  }, [getReturnValues, withMilliseconds])

  return [dayRef, hoursRef, minutesRef, secondsRef, msRef]
}

export { useCountdown }
