import purchaseActionCreators from '@actionCreators/purchaseActionCreators'
import Icon from '@components/Icon'
import { EOrderPaymentStatus, PayViaMobileMoneySectionState } from '@customTypes/purchase'
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks'
import { Dispatch, SetStateAction, useCallback } from 'react'

type InstructionForPayingViaMobileMoneyProps = {
  setPayViaMobileMoneySection: Dispatch<SetStateAction<PayViaMobileMoneySectionState>>
}

const InstructionForPayingViaMobileMoney = ({
  setPayViaMobileMoneySection,
}: InstructionForPayingViaMobileMoneyProps) => {
  const dispatch = useAppDispatch()

  const instructionText = useAppSelector(state => state.purchase.instructionForMobileMoneyPaying)

  const redirectToFinalMobileMoneySection = useCallback(
    (status: EOrderPaymentStatus) => {
      if (status === EOrderPaymentStatus.Canceled || status === EOrderPaymentStatus.Paid) {
        setPayViaMobileMoneySection('final')
      }
    },
    [setPayViaMobileMoneySection]
  )

  const onClickCheckPayment = useCallback(() => {
    dispatch(purchaseActionCreators.checkPaymentViaMobileMoney())
      .unwrap()
      .then(res => redirectToFinalMobileMoneySection(res.status))
      .catch(() => {})
  }, [dispatch, redirectToFinalMobileMoneySection])

  const onBack = useCallback(() => {
    setPayViaMobileMoneySection('form')
  }, [setPayViaMobileMoneySection])
  return (
    <div className="order-balance">
      <div className="display-flex align-items-center gap-8">
        <button className="icon-button transparent" onClick={onBack} type="button">
          <Icon name="arrow-right" size={20} />
        </button>
        Use mobile money:
      </div>
      <p>{instructionText}</p>
      <button
        className="button primary large"
        onClick={onClickCheckPayment}
        //  disabled={isDisabledButton}
      >
        Check payment status
      </button>
    </div>
  )
}

export default InstructionForPayingViaMobileMoney
