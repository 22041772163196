import { FieldValues, UseControllerProps, useController } from 'react-hook-form'
import MaskedInput from 'react-text-mask'

type PhoneNumberTextFieldProps<T extends FieldValues> = UseControllerProps<T> & {
  placeholder: string
  type: 'tel'
  mask: Array<RegExp>
}

const PhoneNumberTextField = <T extends FieldValues>({
  name,
  control,
  placeholder,
  type,
  mask,
}: PhoneNumberTextFieldProps<T>) => {
  const {
    field: { onChange, value },
    fieldState: { error: inputError },
  } = useController({
    name,
    control,
  })

  return (
    <div className="form-control">
      {/* <label htmlFor={name}>{label}</label> */}
      <MaskedInput
        className="form-input"
        onChange={onChange}
        value={value}
        mask={mask}
        guide={false}
        type={type}
        placeholder={placeholder}
        autoComplete="off"
      />
      <div className="form-control-error">{inputError?.message}</div>
    </div>
  )
}

export default PhoneNumberTextField
