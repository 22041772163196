import { Dispatch, SetStateAction, useCallback, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks'
import { EPaymentMethod, PaymentSectionState } from '@customTypes/purchase'
import purchaseActionCreators from '@actionCreators/purchaseActionCreators'
import eventUtils from '@utils/event'
import PaymentOption from './PaymentOption'

const cardPaymentProvider = import.meta.env.VITE_CARD_PAYMENT_PROVIDER

type PaymentOptionListProps = {
  setCurrentPaymentSection: Dispatch<SetStateAction<PaymentSectionState>>
  isSubscribed: boolean
}

const PaymentOptionList = ({ setCurrentPaymentSection, isSubscribed }: PaymentOptionListProps) => {
  const dispatch = useAppDispatch()

  const userEmail = useAppSelector(state => state.user.user?.email)
  const product = useAppSelector(state => state.purchase.selectedProduct)
  const paymentMethods = useAppSelector(state => state.event.currentEvent?.paymentProviders)

  const modifiedPaymentMethod = useMemo(
    () => (paymentMethods ? eventUtils.getPaymentMethodList(paymentMethods) : []),
    [paymentMethods]
  )

  const linkToPaymentProvider = useMemo(() => {
    if (cardPaymentProvider === 'stripe') {
      return `${product?.paymentLink}${userEmail ? `?prefilled_email=${userEmail}` : ''}`
    } else {
      return product?.paymentLink || ''
    }
  }, [product?.paymentLink, userEmail])

  const onClick = useCallback(
    (paymentMethodId: EPaymentMethod) => {
      if (paymentMethodId === EPaymentMethod.PayNowMobile) {
        setCurrentPaymentSection('mobile-payment')
      }
      if (paymentMethodId === EPaymentMethod.InnBucks) {
        setCurrentPaymentSection('innBucks')
      }
      if (paymentMethodId === EPaymentMethod.Voucher) {
        setCurrentPaymentSection('voucher-payment')
      }
      if (paymentMethodId === EPaymentMethod.StripeIntent) {
        const body = {
          paymentProviderId: EPaymentMethod.StripeIntent,
          products: [{ productId: product?.id || 0, qty: 1 }],
          // isSubscribed,
        }

        dispatch(purchaseActionCreators.payViaCard(body))
          .unwrap()
          .then(() => setCurrentPaymentSection('card'))
      }
    },
    [dispatch, product?.id, setCurrentPaymentSection]
  )

  return (
    <div className="order-balance payment-option-list">
      {modifiedPaymentMethod.map(paymentMethod => (
        <PaymentOption
          key={paymentMethod.paymentMethodId}
          paymentMethod={paymentMethod}
          linkToPaymentProvider={linkToPaymentProvider}
          onParentClick={onClick}
        />
      ))}
    </div>
  )
}

export default PaymentOptionList
