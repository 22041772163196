import { anonymousRequest } from "@api/anonymousRequest"
import { authorizedRequest } from "@api/authorizeRequest"
import { BecomeAgentDataForm } from "@customTypes/agent"
import { EventListResponse, GetOrderList } from "@customTypes/event"
import { Order } from "@customTypes/purchase"

const addNewAgent = (body: BecomeAgentDataForm) => {
  const path = ['agents']

  return anonymousRequest(path, "POST", body)
}

const getEventListForAgent = () => {
  const path = ['agent', 'events']

  return authorizedRequest<null, EventListResponse>('v1', path)
}

const getOrderListForAgent = () => {
  const path = ['agent', 'orders']

  return authorizedRequest<null, GetOrderList>('v1', path)
}

const sendMailAgain = (orderId: number) => {
  const path = ['agent', 'orders', String(orderId), 'sendtickets']

  return authorizedRequest<null, null>('v1', path)
}



const agentServices = {
  addNewAgent,
  getEventListForAgent,
  getOrderListForAgent,
  sendMailAgain,
}

export default agentServices