import eventServices from "@api/services/eventServices"
import { CustomErrorResponse, TokenStructure } from "@customTypes/common"
import { AddLikesByVideoIdRequestArg, BuyViaVoucherBodyRequest, GetEventListRequestParams, Video, VideoRotation } from "@customTypes/event"
import { createAsyncThunk } from "@reduxjs/toolkit"
import { parseJwt } from "@utils/common"
import localStorageData from "@utils/localStorage"


// const getPublicEvent = createAsyncThunk(
//   'event/getPublicEvent', async (_, { rejectWithValue, dispatch }) => {
//     try {

//       const res = await eventServices.getPublicEvent()

//       return res
//     } catch (e) {
//       const err = e as CustomErrorResponse

//       return rejectWithValue('error')
//     }
//   })

const getEventById = createAsyncThunk(
  'event/getEventById', async (eventId: number | string, { rejectWithValue, dispatch }) => {
    const authData = localStorageData.getData('authData')

    try { 
      let res

      if(authData) {
        res = await eventServices.getAuthEventById(eventId)
      } else {
        res = await eventServices.getNonAuthEventById(eventId)
      }
      

      return res
    } catch (e) {
      const err = e as CustomErrorResponse

      return rejectWithValue('error')
    }
  })

// const getActiveEventForAuthorizedUser = createAsyncThunk(
//   'event/getActiveEventForAuthorizedUser', async (_, { rejectWithValue }) => {

//     try {
//       const res = await eventServices.getActiveEventForAuthorizedUser()

//       return res
//     } catch (e) {
//       const err = e as CustomErrorResponse

//       return rejectWithValue('error')
//     }
//   })

const getEventList = createAsyncThunk(
  'event/getEventList', async (args: GetEventListRequestParams, { rejectWithValue, dispatch }) => {
    const { type, searchValue } = args

    try {
      const res = await eventServices.getEventList(type, searchValue)


      return res
    } catch (e) {
      const err = e as CustomErrorResponse

      return rejectWithValue('error')
    }
  }
)

const getVideoById = createAsyncThunk(
  'event/getVideoById',
  async (videoId: number | string, { rejectWithValue }) => {
    const token = localStorageData.getData("authData")

    const parsedToken = parseJwt(token) as TokenStructure
    const remainingMsUntilExpToken = (parsedToken.exp * 1000) - new Date().getTime()
    const limitForTokenReRequest = 1000 * 60 * 25

    try {
      let res: Video
      if (remainingMsUntilExpToken > limitForTokenReRequest) {
        res = await eventServices.getVideoById(videoId)
      } else {
        res = await eventServices.getVideoByIdWithAuthorize(videoId) as Video
      }


      return res
    } catch (e) {
      const err = e as CustomErrorResponse

      return rejectWithValue('error')
    }
  })

const getLikesByVideoId = createAsyncThunk(
  'event/getLikesByVideoId', async (videoId: number, { rejectWithValue, dispatch }) => {
    try {

      const res = await eventServices.getLikesByVideoId(videoId)



      return res
    } catch (e) {
      const err = e as CustomErrorResponse

      return rejectWithValue('error')
    }
  })

const addLikesByVideoId = createAsyncThunk(
  'event/addLikesByVideoId', async (arg: AddLikesByVideoIdRequestArg, { rejectWithValue, dispatch }) => {
    const { videoId, count } = arg
    const body = {
      count
    }
    try {

      const res = await eventServices.addLikesByVideoId(videoId, body)

      // dispatch(getLikesByVideoId(videoId))

      return res
    } catch (e) {
      const err = e as CustomErrorResponse

      return rejectWithValue('error')
    }
  })

const buyViaVoucher = createAsyncThunk('event/buyViaVoucher', async (body: BuyViaVoucherBodyRequest, { rejectWithValue, dispatch }) => {
  try {

    const res = await eventServices.buyViaVoucher(body)


    // dispatch(get)

    return res
  } catch (e) {
    const err = e as CustomErrorResponse

    return rejectWithValue(err)
  }
})

const eventActionCreators = {
  getEventList,
  getEventById,

  // getPublicEvent,
  getLikesByVideoId,
  addLikesByVideoId,
  buyViaVoucher,
  getVideoById,
  // getActiveEventForAuthorizedUser,
}

export default eventActionCreators