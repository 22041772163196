import { ScaleLoader } from 'react-spinners'

type LoaderProps = {
  className: string
}

const Loader = ({ className }: LoaderProps) => {
  return (
    <div className={`spinner${className ? ' ' + className : ''}`}>
      <ScaleLoader color="#3171d8" height={55} width={8}/>
    </div>
  )
}

export default Loader
