import eventActionCreators from '@actionCreators/eventActionCreators'
import CountDown from '@components/CountDown'
import Icon from '@components/Icon'
import ShortEventInfo from '@components/event/ShortEventInfo'
import Loader from '@components/ui/Loader'
import { EEventType, EProductType, Product } from '@customTypes/event'
import { CommonIcons } from '@helpers/commonIcons'
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks'
import { clearCurrentEvent } from '@sliceActionCreators/eventSlice'
import dateFormat from '@utils/dateFormat'
import localStorageData from '@utils/localStorage'
import { isEqual } from 'lodash'
import { SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'

const EventDetailsPage = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { id } = useParams()

  const user = useAppSelector(state => state.user.user)
  const eventData = useAppSelector(state => state.event.currentEvent)
  const getEventByIdIsLoading = useAppSelector(state => state.event.getEventByIdLoadState.isLoading)
  const userTimeZone =
    useAppSelector(state => state.user.user?.timezone, isEqual) ||
    Intl.DateTimeFormat().resolvedOptions().timeZone
  const [isCountDownFinished, setIsCountDownFinished] = useState<boolean>(false)
  const [isShownTimer, setIsShownTimer] = useState(true)

  const isConference = useMemo(() => id === 'ASAP-HealinginAfrica', [id])

  const countDownDate = useMemo(
    () =>
      (eventData &&
        userTimeZone &&
        dateFormat.formatInTimeZone(eventData.startDate, 'yyyy-MM-dd HH:mm:ss', userTimeZone)) ||
      '',
    [eventData, userTimeZone]
  )

  const endDateWithTimeZone = useMemo(
    () =>
      (eventData &&
        dateFormat.formatInTimeZone(eventData.endDate, 'yyyy-MM-dd HH:mm:ss', userTimeZone!)) ||
      '',
    [eventData, userTimeZone]
  )

  useEffect(() => {
    if (id) {
      dispatch(eventActionCreators.getEventById(id))
        .unwrap()
        .catch(() => {
          navigate('/')
        })
    } else {
      navigate('/')
    }

    return () => {
      dispatch(clearCurrentEvent())
    }
  }, [dispatch, id, navigate])

  useEffect(() => {
    const currentCountDown = new Date(countDownDate).getTime() - new Date().getTime()

    if (currentCountDown < 500) {
      setIsShownTimer(false)
    }
  }, [countDownDate])

  useEffect(() => {
    if (isCountDownFinished) {
      setIsShownTimer(false)
    }
  }, [isCountDownFinished])

  const groupedCurrentEventProducts = useMemo(() => {
    const groupedProducts: Record<string, Array<Product>> = {
      main: [],
      firstHotel: [],
      secondHotel: [],
      transfer: [],
    }

    eventData &&
      eventData.products.forEach(product => {
        if (product.title.includes("A'Zambezi River Lodge")) {
          groupedProducts.firstHotel.push(product)
          return
        }
        if (product.title.includes('Victoria Falls Rainbow')) {
          groupedProducts.secondHotel.push(product)
          return
        }
        if (product.shortDescription.includes('return')) {
          groupedProducts.transfer.push(product)
          return
        }

        groupedProducts.main.push(product)
      })

    return groupedProducts
  }, [eventData])

  const date = useMemo(() => {
    if (eventData?.isOnline) {
      return (
        eventData &&
        userTimeZone &&
        dateFormat.formatInTimeZone(eventData.startDate, 'dd.MM.yyyy HH:mm:ss', userTimeZone)
      )
    } else {
      return eventData && dateFormat.formatDate(eventData.startDate, 'dd.MM.yyyy HH:mm:ss')
    }
  }, [eventData, userTimeZone])

  const onBack = useCallback(() => {
    if (eventData?.type === EEventType.Music) {
      navigate('/events?activeTab=music')
    }
    if (eventData?.type === EEventType.Conferences) {
      navigate('/events?activeTab=conferences')
    }
    if (eventData?.type === EEventType.Film) {
      navigate('/events?activeTab=film')
    }
  }, [eventData?.type, navigate])

  const redirectToPaymentPage = useCallback(
    (productId: number) => {
      localStorageData.setData('selectedProductId', productId)

      if (user) {
        navigate(`/events/${id}/payment`)
      } else {
        navigate('/login', { state: { from: `/events/${id}/payment` } })
      }
    },
    [id, navigate, user]
  )

  const onClick = useCallback((event: SyntheticEvent) => {
    event.preventDefault()

    const target = event.target as HTMLAnchorElement
    const targetId = target.getAttribute('href') as string
    const currentElement = document.querySelector(targetId) as HTMLHRElement
    const mainTag = document.getElementById('main-v2') as HTMLElement

    mainTag.scrollTo({
      top: currentElement.offsetTop,
      behavior: 'smooth',
    })
  }, [])

  const renderCountDownSection = useMemo(() => {
    if (!eventData?.isOnline) {
      return null
    }

    if (isShownTimer) {
      return (
        <div className="count-down-container">
          <CountDown
            countDownDate={countDownDate}
            type="small-countdown"
            stopTimerTime={500}
            withMilliseconds={false}
            setIsCountDownFinished={setIsCountDownFinished}
          />
        </div>
      )
    } else {
      const valueUntilStart = new Date(countDownDate).getTime() - new Date().getTime()
      const valueUntilEnd = new Date(endDateWithTimeZone).getTime() - new Date().getTime()
      const video = eventData.products.filter(product => product.type === EProductType.Video)[0]
        .video

      if (valueUntilStart < 500 && valueUntilEnd > 0) {
        return (
          <>
            <div className="event-message h3 text-center animation mt-16 mb-16">
              Event has started!
            </div>
            {user && video.isPurchased && (
              <div className="display-flex justify-content-center mt-16 mb-16">
                <Link
                  className="button large primary"
                  to={`/events/${eventData.url}/video/${video.id}`}
                >
                  Go To Video
                </Link>
              </div>
            )}
          </>
        )
      }
      if (valueUntilEnd < 0) {
        return <div className="event-message h3 text-center animation">Event has finished!</div>
      }
    }
  }, [
    countDownDate,
    endDateWithTimeZone,
    eventData?.isOnline,
    eventData?.products,
    eventData?.url,
    isShownTimer,
    user,
  ])

  if (!eventData) {
    return null
  }

  return (
    <>
      {getEventByIdIsLoading && <Loader className="spinner-fixed" />}
      {eventData && (
        <div className="centered-page-content light-background">
          <>
            <section className="event-detail-section page-navigation-section">
              <button className="icon-button transparent auto-size" onClick={onBack}>
                <Icon name="arrow-right" size={20} />
              </button>
            </section>
            <section className="event-detail-section">
              <div className="event-detail-header">
                <div className="event-detail-media">
                  <img src={eventData.verticalImg} alt="" />
                </div>

                <div className="event-detail-info">
                  <h2 className="event-detail-title text-uppercase">
                    {eventData.type === EEventType.Film ||
                    eventData.url === 'zimfest-live-zimfest-medley'
                      ? eventData.title
                      : `${eventData.artist} ${eventData.title}`}
                  </h2>
                  {(eventData.type === EEventType.Film ||
                    eventData.url === 'zimfest-live-zimfest-medley') && (
                    <p className="h4 mb-16">{eventData.artist}</p>
                  )}
                  {renderCountDownSection}
                  <p className="event-detail-description">{eventData.description}</p>
                  <p className="h4 mb-16">Live on {date}</p>
                  {eventData.coordinates && (
                    <a
                      className="button-link display-flex align-items-end gap-8"
                      href={`https://www.google.com/maps/search/?api=1&query=${eventData.coordinates}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ marginBottom: '16px' }}
                    >
                      <CommonIcons.Location width={18} height={23} color="#0000FF" />
                      {eventData.country}, {eventData.city}, {eventData.address}
                    </a>
                  )}

                  <div className="display-flex flex-wrap gap-8">
                    <a className="button secondary outline" href="#tickets" onClick={onClick}>
                      Tickets
                    </a>
                    {isConference && (
                      <>
                        <a className="button secondary outline" href="#experts" onClick={onClick}>
                          Experts
                        </a>
                        {/* <a
                          className="button secondary outline"
                          href="#accommodation"
                          onClick={onClick}
                        >
                          Accommodation
                        </a>
                        <a className="button secondary outline" href="#transfer" onClick={onClick}>
                          Transfer
                        </a> */}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </section>
            <section id="tickets" className="event-detail-section">
              <h3 className="mb-16 px-16">Tickets</h3>
              {groupedCurrentEventProducts.main.length > 0 &&
                groupedCurrentEventProducts.main.map(product => (
                  <ShortEventInfo
                    key={product.id}
                    // title={product.title}
                    btnType={product?.isPurchased ? 'paid' : 'unpaid'}
                    // text={product.shortDescription}
                    // price={product.price}
                    product={product}
                    onParentClick={redirectToPaymentPage}
                    color="grey"
                  />
                ))}
            </section>
            {isConference && (
              <>
                <section id="experts" className="event-detail-section">
                  <h3 className="mb-16 px-16">LONG COVID & CANCER EXPERTS</h3>
                  <div className="experts-grid mt-48">
                    <div className="experts-grid-item">
                      <div className="expert-card">
                        <img src="/expert-1.png" alt="" />
                        <div className="expert-card-title">Dr Jackie Stone</div>
                        <div className="expert-card-message">Aviation Medicine</div>
                      </div>
                    </div>
                    <div className="experts-grid-item">
                      <div className="expert-card">
                        <img src="/expert-2.png" alt="" />
                        <div className="expert-card-title">Dr Martin Wucher</div>
                        <div className="expert-card-message">REGENERATIVE MEDICINE</div>
                      </div>
                    </div>
                    <div className="experts-grid-item">
                      <div className="expert-card">
                        <img src="/expert-3.png" alt="" />
                        <div className="expert-card-title">Dr Susan Vosloo</div>
                        <div className="expert-card-message">Cardiothoracic Surgeon</div>
                      </div>
                    </div>
                    <div className="experts-grid-item">
                      <div className="expert-card">
                        <img src="/expert-4.png" alt="" />
                        <div className="expert-card-title">Dr Shankara Chetty</div>
                        <div className="expert-card-message">Natural Science & Medical Doctor</div>
                      </div>
                    </div>
                    <div className="experts-grid-item">
                      <div className="expert-card">
                        <img src="/expert-5.png" alt="" />
                        <div className="expert-card-title">Dr Erika Drewes</div>
                        <div className="expert-card-message">Functional Medicine</div>
                      </div>
                    </div>
                    <div className="experts-grid-item">
                      <div className="expert-card">
                        <img src="/expert-6.png" alt="" />
                        <div className="expert-card-title">Dr Nathi Mdladla</div>
                        <div className="expert-card-message">Cardiothoracic Anaesthetist</div>
                      </div>
                    </div>
                    <div className="experts-grid-item">
                      <div className="expert-card">
                        <img src="/expert-7.png" alt="" />
                        <div className="expert-card-title">Dr Hector Carvallo</div>
                        <div className="expert-card-message">Internal Medicine Specialist</div>
                      </div>
                    </div>
                    <div className="experts-grid-item">
                      <div className="expert-card">
                        <img src="/expert-8.png" alt="" />
                        <div className="expert-card-title">Dr Herman Edeling</div>
                        <div className="expert-card-message">
                          Neurosurgeon & Medicolegal Specialist
                        </div>
                      </div>
                    </div>
                    <div className="experts-grid-item">
                      <div className="expert-card">
                        <img src="/expert-9.png" alt="" />
                        <div className="expert-card-title">Dr Stef Snyman</div>
                        <div className="expert-card-message">
                          Occupational & Functional Medicine
                        </div>
                      </div>
                    </div>
                    <div className="experts-grid-item">
                      <div className="expert-card">
                        <img src="/expert-10.png" alt="" />
                        <div className="expert-card-title">Dr Hannetjie Van Zyl Edeling</div>
                        <div className="expert-card-message">
                          Counselling Psychologist & dietican
                        </div>
                      </div>
                    </div>
                    <div className="experts-grid-item">
                      <div className="expert-card">
                        <img src="/expert-11.png" alt="" />
                        <div className="expert-card-title">Carl Joshua Ncube</div>
                        <div className="expert-card-message">Celebrity Chef</div>
                      </div>
                    </div>
                    <div className="experts-grid-item">
                      <div className="expert-card">
                        <img src="/expert-12.png" alt="" />
                        <div className="expert-card-title">Dr Debru Negash</div>
                        <div className="expert-card-message">
                          Infectious Disease & tropical medicine Specialist
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                {/* <section id="accommodation" className="event-detail-section">
                  <h3 className="mb-16 px-16">Accommodation</h3>
                  {groupedCurrentEventProducts.firstHotel.length > 0 &&
                    groupedCurrentEventProducts.firstHotel.map(product => (
                      <ShortEventInfo
                        key={product.id}
                        // title={product.title}
                        btnType={product?.isPurchased ? 'paid' : 'unpaid'}
                        // text={product.shortDescription}
                        // price={product.price}
                        product={product}
                        onParentClick={redirectToPaymentPage}
                        // {...(!isPurchased && { onClick: redirectToNextSection })}
                        color="grey"
                      />
                    ))}
                  {groupedCurrentEventProducts.secondHotel.length > 0 &&
                    groupedCurrentEventProducts.secondHotel.map(product => (
                      <ShortEventInfo
                        key={product.id}
                        // title={product.title}
                        btnType={product?.isPurchased ? 'paid' : 'unpaid'}
                        // text={product.shortDescription}
                        // price={product.price}
                        product={product}
                        onParentClick={redirectToPaymentPage}
                        // {...(!isPurchased && { onClick: redirectToNextSection })}
                        color="grey"
                      />
                    ))}
                </section> */}
                {/* <section id="transfer" className="event-detail-section">
                  <h3 className="mb-16 px-16">Transfer</h3>
                  {groupedCurrentEventProducts.transfer.length > 0 &&
                    groupedCurrentEventProducts.transfer.map(product => (
                      <ShortEventInfo
                        key={product.id}
                        // title={product.title}
                        btnType={product?.isPurchased ? 'paid' : 'unpaid'}
                        // text={product.shortDescription}
                        // price={product.price}
                        product={product}
                        onParentClick={redirectToPaymentPage}
                        // {...(!isPurchased && { onClick: redirectToNextSection })}
                        color="grey"
                      />
                    ))}
                </section> */}
              </>
            )}
          </>
        </div>
      )}
    </>
  )
}

export default EventDetailsPage
