import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'
import Icon from '@components/Icon'
import TextFieldForm from '@components/ui/TextFieldForm'
import { Credentials, SignUpArgsRequest } from '@customTypes/auth'
import { EmailFormType, LoginScreenState } from '@customTypes/login'
import { yupResolver } from '@hookform/resolvers/yup'
import { useAppSelector } from '@hooks/reduxHooks'
import { trimObjectFields } from '@utils/common'
import loginYupSchemes from '@utils/yupSchemes/loginYupSchemes'
import { isEmpty } from 'lodash'
import { useForm } from 'react-hook-form'

type SignInFormProps = {
  onParentSubmit: (formData: EmailFormType) => void
  userData: SignUpArgsRequest
  setCurrentScreen: Dispatch<SetStateAction<LoginScreenState>>
}

const SignInForm = ({ onParentSubmit, setCurrentScreen, userData }: SignInFormProps) => {
  const signInViaEmailIsLoading = useAppSelector(
    state => state.auth.signInViaEmailAndPasswordLoadState.isLoading
  )

  const [isDisabledButton, setIsDisabledButton] = useState(false)

  const { handleSubmit, control, formState, setValue } = useForm<Credentials>({
    resolver: yupResolver(loginYupSchemes.signInFormScheme),
  })

  const setFormValue = useCallback(() => {
    setValue('email', userData.email)
  }, [setValue, userData.email])

  useEffect(() => {
    setFormValue()
  }, [setFormValue])

  useEffect(() => {
    if (isEmpty(formState.errors)) {
      setIsDisabledButton(false)
    } else {
      setIsDisabledButton(true)
    }
  }, [formState])

  const onSubmit = (dataForm: Credentials) => {
    const trimmedDataForm = trimObjectFields(dataForm) as Credentials

    onParentSubmit(trimmedDataForm)
  }

  const onBack = useCallback(() => {
    setCurrentScreen('email')
  }, [setCurrentScreen])

  const onClickResetPassword = useCallback(() => {
    setCurrentScreen('reset-password')
  }, [setCurrentScreen])

  return (
    <>
      <div className="form-title">
        <button className="icon-button transparent back-button" onClick={onBack}>
          <Icon name="arrow-right" size={20} />
        </button>
        <h4>Sign In</h4>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextFieldForm
          name="email"
          control={control}
          placeholder="Enter email"
          type="string"
          label="Email"
          maxLength={64}
          autoComplete="on"
        />
        <TextFieldForm
          name="password"
          control={control}
          placeholder="Enter password"
          type="password"
          label="Password"
          maxLength={32}
        />
        <button
          className="button primary large w-100"
          disabled={isDisabledButton || signInViaEmailIsLoading}
        >
          Sign In
        </button>
      </form>
      <div className="display-flex justify-content-center mt-16">
        <button className="button-link" onClick={onClickResetPassword}>
          Forgot password?
        </button>
      </div>
    </>
  )
}

export default SignInForm
