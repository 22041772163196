import React, { useEffect, useState, createContext, useCallback, useRef } from 'react'
// import PropTypes from 'prop-types'
// import { getImages } from '../../../imagesApi'

// import Dots from './components/Controls/Dots'

// import SlidesList from './components/SlidesList'
import Slider from 'react-slick'
import Arrows from './Arrows'
import { ShortEvent } from '@customTypes/event'
import Badge from '../Badge'
import Icon from '@components/Icon'
import { Link } from 'react-router-dom'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import CarouselItem from './CarouselItem'

export const SliderContext = createContext({})

type CarouselProps = {
  autoPlay: boolean
  autoPlayTime: number
  dataList: Array<ShortEvent>
}

const Carousel = function ({ autoPlay, autoPlayTime, dataList }: CarouselProps) {
  const [items, setItems] = useState<Array<ShortEvent>>([])
  const [slide, setSlide] = useState(0)
  const [touchPosition, setTouchPosition] = useState(null)

  useEffect(() => {
    setItems(dataList)
  }, [dataList])

  const changeSlide = useCallback(
    (direction = 1) => {
      let slideNumber = 0

      if (slide + direction < 0) {
        slideNumber = items.length - 1
      } else {
        slideNumber = (slide + direction) % items.length
      }

      setSlide(slideNumber)
    },
    [items.length, slide]
  )

  const goToSlide = number => {
    setSlide(number % items.length)
  }

  const handleTouchStart = e => {
    const touchDown = e.touches[0].clientX

    setTouchPosition(touchDown)
  }

  const handleTouchMove = e => {
    if (touchPosition === null) {
      return
    }

    const currentPosition = e.touches[0].clientX
    const direction = touchPosition - currentPosition

    if (direction > 10) {
      changeSlide(1)
    }

    if (direction < -10) {
      changeSlide(-1)
    }

    setTouchPosition(null)
  }

  useEffect(() => {
    if (!autoPlay) return

    const interval = setInterval(() => {
      changeSlide(1)
    }, autoPlayTime)

    return () => {
      clearInterval(interval)
    }
  }, [autoPlay, autoPlayTime, changeSlide]) // when images uploaded or slide changed manually we start timer

  const settings = {
    // dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    // slidesToScroll: 1,
    arrows: true,
    autoplaySpeed: 5000,
    autoplay: true,
  }

  return (
    <div
      // style={{ width, height }}
      className="slider"
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
    >
      {/* <div className="slide-list" style={{ transform: `translateX(-${slide * 100}%)` }}> */}

      {dataList.length > 1 ? (
        <Slider {...settings}>
          {dataList.map(item => (
            <CarouselItem data={item} key={item.id}/>
          ))}
        </Slider>
      ) : (
        <CarouselItem data={dataList[0]} />
      )}
      {/* </div> */}

      {/* <Arrows /> */}
      {/* <button onClick={() => changeSlide(-1)}> - </button> */}
      {/* <button onClick={changeSlide(1)}> + </button>
        <button onClick={next}> + </button> */}
      {/* <SlidesList /> */}
      {/* <Dots /> */}
    </div>
  )
}

export default Carousel
