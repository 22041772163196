import purchaseActionCreators from '@actionCreators/purchaseActionCreators'
import Loader from '@components/ui/Loader'
import PhoneNumberTextField from '@components/ui/PhoneNumberTextField'
import { EPaymentMethod, PayViaInnBucksSectionState } from '@customTypes/purchase'
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks'
import { getPhoneNumberMaskForPayViaMobileMoney } from '@utils/common'
import { Dispatch, SetStateAction, useMemo } from 'react'
import { useForm } from 'react-hook-form'

type PayViaInnBucksFormProps = {
  setPayViaInnBucksSection: Dispatch<SetStateAction<PayViaInnBucksSectionState>>
  isSubscribed: boolean
}

const PayViaInnBucksForm = ({
  setPayViaInnBucksSection,
  isSubscribed,
}: PayViaInnBucksFormProps) => {
  const dispatch = useAppDispatch()

  const productId = useAppSelector(state => state.purchase.selectedProduct?.id) || 0
  const { payViaInnBucksLoadState } = useAppSelector(state => state.purchase)

  const phoneNumberMask = useMemo(() => getPhoneNumberMaskForPayViaMobileMoney(), [])

  const { handleSubmit, control } = useForm<{ phoneNumber: string }>()

  const onSubmit = (dataForm: { phoneNumber: string }) => {
    const body = {
      paymentProviderId: EPaymentMethod.InnBucks,
      products: [{ productId, qty: 1 }],
      paymentProviderOptions: {
        phoneNumber: dataForm.phoneNumber.trim(),
      },
      // isSubscribed,
    }

    dispatch(purchaseActionCreators.payViaInnBucks(body))
      .unwrap()
      .then(() => setPayViaInnBucksSection('qr'))
      .catch(() => {})
  }

  return (
    <>
      {payViaInnBucksLoadState.isLoading && <Loader className="spinner-fixed" />}
      <form onSubmit={handleSubmit(onSubmit)} className="order-balance">
        <div className="display-flex align-items-center gap-8">Pay via Innbucks:</div>
        <PhoneNumberTextField
          name="phoneNumber"
          control={control}
          placeholder="Enter phone number"
          type="tel"
          mask={phoneNumberMask}
        />
        <button className="button primary large" type="submit">
          Next
        </button>
      </form>
    </>
  )
}

export default PayViaInnBucksForm
