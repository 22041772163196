import chatActionCreators from "@actionCreators/chatActionCreators"
import { ChatMessage } from "@customTypes/chat"
import { createSlice } from "@reduxjs/toolkit"
import { uniqBy } from "lodash"

type ChatSlice = {
  chatList: Array<ChatMessage>
  // newChatList: Array<ChatMessage>
  currentMessageCounter: number
}

const initialState: ChatSlice = {
  chatList: [],
  currentMessageCounter: -1
  // newChatList: []
}


export const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(chatActionCreators.getChatListByEventId.pending, (state) => {
        state.currentMessageCounter = state.chatList.length > 0 ? state.chatList[state.chatList.length - 1].index : -1
      })
      .addCase(chatActionCreators.getChatListByEventId.fulfilled, (state, action) => {
        const updatedChatList: Array<ChatMessage> = uniqBy([...state.chatList, ...action.payload], 'index').sort((firstOperand, secondOperand) =>
          firstOperand.index - secondOperand.index
        )

        state.chatList = updatedChatList
        // state.currentMessageCounter = updatedChatList.length > 0 ? updatedChatList[updatedChatList.length - 1].index : -1
      })
      .addCase(chatActionCreators.addMessageToChat.pending, state => {
        state.currentMessageCounter = state.chatList.length > 0 ? state.chatList[state.chatList.length - 1].index : -1
      })
      .addCase(chatActionCreators.addMessageToChat.fulfilled, (state, action) => {
        state.chatList = uniqBy([...state.chatList, action.payload], 'index').sort((firstOperand, secondOperand) =>
          firstOperand.index - secondOperand.index
        )
        // state.currentMessageCounter = action.payload.index
      })
  }
})

export default chatSlice.reducer