import { ChangeEvent, useCallback } from 'react'
import { FieldValues, UseControllerProps, useController } from 'react-hook-form'
import MaskedInput from 'react-text-mask'

type VoucherFormTextFieldProps<T extends FieldValues> = UseControllerProps<T> & {
  placeholder: string
  type: 'text'

}

const VoucherFormTextField = <T extends FieldValues>({
  name,
  control,
  placeholder,
  type,
}: VoucherFormTextFieldProps<T>) => {

  const {
    field: { onChange, value },
    fieldState: { error: inputError },
  } = useController({
    name,
    control,
  })

  const onVoucherFormTextFieldChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value.toLocaleUpperCase()

      onChange(value)
    },
    [onChange]
  )
  return (
    <div className="voucher-form-text-field">
      <MaskedInput
        className="form-input"
        onChange={onVoucherFormTextFieldChange}
        value={value}
        mask={[
          /[0-9a-zA-Z]/,
          /[0-9a-zA-Z]/,
          /[0-9a-zA-Z]/,
          '-',
          /[0-9a-zA-Z]/,
          /[0-9a-zA-Z]/,
          /[0-9a-zA-Z]/,
          '-',
          /[0-9a-zA-Z]/,
          /[0-9a-zA-Z]/,
          /[0-9a-zA-Z]/,
        ]}
        guide={false}
        type={type}
        placeholder={placeholder}
        autoComplete="off"
      />
      <div className="form-control-error">{inputError?.message}</div>
    </div>
  )
}

export default VoucherFormTextField
