import Icon from '@components/Icon'
import TextFieldForm from '@components/ui/TextFieldForm'
import { SignUpArgsRequest } from '@customTypes/auth'
import { EmailFormType, LoginScreenState } from '@customTypes/login'
import { yupResolver } from '@hookform/resolvers/yup'
import { useAppSelector } from '@hooks/reduxHooks'
import loginYupSchemes from '@utils/yupSchemes/loginYupSchemes'
import { isEmpty } from 'lodash'
import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

type ResetPasswordFormProps = {
  onParentSubmit: (formData: EmailFormType) => void
  userData: SignUpArgsRequest
  setCurrentScreen: Dispatch<SetStateAction<LoginScreenState>>
}

const ResetPasswordForm = ({
  onParentSubmit,
  userData,
  setCurrentScreen,
}: ResetPasswordFormProps) => {
  const sendEmailToResetPasswordIsLoading = useAppSelector(
    state => state.auth.sendEmailToRestPasswordLoadState.isLoading
  )

  const [isDisabledButton, setIsDisabledButton] = useState(false)

  const { handleSubmit, control, formState, setValue } = useForm<EmailFormType>({
    resolver: yupResolver(loginYupSchemes.emailFormScheme),
  })

  const setFormValue = useCallback(() => {
    setValue('email', userData.email)
  }, [setValue, userData.email])

  useEffect(() => {
    setFormValue()
  }, [setFormValue])

  useEffect(() => {
    if (isEmpty(formState.errors)) {
      setIsDisabledButton(false)
    } else {
      setIsDisabledButton(true)
    }
  }, [formState])

  const onSubmit = (dataForm: EmailFormType) => {
    onParentSubmit(dataForm)
  }

  const onBack = useCallback(() => {
    setCurrentScreen('sign-in')
  }, [setCurrentScreen])

  return (
    <>
      <div className="form-title">
        <button className="icon-button transparent back-button" onClick={onBack}>
          <Icon name="arrow-right" size={20} />
        </button>
        <h4>Reset Password</h4>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextFieldForm
          name="email"
          control={control}
          placeholder="Enter email"
          type="string"
          label="Email"
          maxLength={64}
          autoComplete="on"
        />
        <button
          className="button primary large w-100"
          disabled={isDisabledButton || sendEmailToResetPasswordIsLoading}
        >
          Reset Password
        </button>
      </form>
    </>
  )
}

export default ResetPasswordForm
