import TextFieldForm from '@components/ui/TextFieldForm'
import { SignUpArgsRequest } from '@customTypes/auth'
import { EmailFormType, LoginScreenState } from '@customTypes/login'
import { yupResolver } from '@hookform/resolvers/yup'
import { trimObjectFields } from '@utils/common'
import loginYupSchemes from '@utils/yupSchemes/loginYupSchemes'
import { isEmpty } from 'lodash'
import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

type EmailFormProps = {
  onParentSubmit: (formData: EmailFormType) => void
  userData: SignUpArgsRequest
  setCurrentScreen: Dispatch<SetStateAction<LoginScreenState>>
}

const EmailForm = ({ onParentSubmit, userData, setCurrentScreen }: EmailFormProps) => {
  const [isDisabledButton, setIsDisabledButton] = useState(false)

  const { handleSubmit, control, formState, setValue } = useForm<EmailFormType>({
    resolver: yupResolver(loginYupSchemes.emailFormScheme),
  })

  const setFormValue = useCallback(() => {
    setValue('email', userData.email)
  }, [setValue, userData.email])

  useEffect(() => {
    setFormValue()
  }, [setFormValue])

  useEffect(() => {
    if (isEmpty(formState.errors)) {
      setIsDisabledButton(false)
    } else {
      setIsDisabledButton(true)
    }
  }, [formState])

  const onSubmit = (dataForm: EmailFormType) => {
    const trimmedDataForm = trimObjectFields(dataForm) as EmailFormType
    
    onParentSubmit(trimmedDataForm)
  }

  const onClickSignUp = useCallback(()=>{
    setCurrentScreen('sign-up')
  },[setCurrentScreen])

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-title">
          <h4>Sign In</h4>
        </div>
        <TextFieldForm
          name="email"
          control={control}
          placeholder="Enter email"
          type="string"
          label="Email"
          maxLength={64}
          autoComplete="on"
        />
        <button className="button primary large w-100" disabled={isDisabledButton}>
          Next
        </button>
      </form>
      <div className="display-flex justify-content-center mt-16">
        <button className="button-link" onClick={onClickSignUp}>Create an account</button>
      </div>
    </>
  )
}

export default EmailForm
