import React, { memo, useMemo } from 'react'
import { Link } from 'react-router-dom'

const SuccessfulBlockForNewAgent = () => {
  const currentHost = `https://${window.location.hostname}`


  return (
    <>
      Thank you for your agent application. A member of our team will be in touch soon. Yours, in
      the Streets. <Link className='button-link' to='/'>{currentHost}</Link>
    </>
  )
}

export default SuccessfulBlockForNewAgent
