import { ChangeEvent, useCallback } from 'react'
import { FieldValues, UseControllerProps, useController } from 'react-hook-form'
import MaskedInput from 'react-text-mask'

type TextFieldFormProps<T extends FieldValues> = UseControllerProps<T> & {
  placeholder: string
  type: string
  label: string
  maxLength: number
  autoComplete?: 'on' | 'off'
}

const TextFieldForm = <T extends FieldValues>({
  name,
  control,
  placeholder,
  type,
  label,
  maxLength,
  autoComplete = 'off',
}: TextFieldFormProps<T>) => {
  const {
    field: { onChange, value },
    fieldState: { error: inputError },
  } = useController({
    name,
    control,
  })

  const onVoucherFormTextFieldChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value

      onChange(value)
    },
    [onChange]
  )
  return (
    <div className="form-control">
      <label htmlFor={name}>{label}</label>
      <input
        id={name}
        className="form-input"
        onChange={onVoucherFormTextFieldChange}
        value={typeof value === 'undefined' ? '' : value}
        type={type}
        placeholder={placeholder}
        autoComplete={autoComplete}
        maxLength={maxLength}
      />
      <div className="form-control-error">{inputError?.message}</div>
    </div>
  )
}

export default TextFieldForm
