import { PayViaMobileMoneySectionState } from '@customTypes/purchase'
import { useMemo, useState } from 'react'
import PayViaMobileMoneyForm from './PayViaMobileMoneyForm'
import InstructionForPayingViaMobileMoney from './InstructionForPayingViaMobileMoney'
import FinalScreenForPayViaMobileMoney from './FinalScreenForPayViaMobileMoney'

type PayViaMobileMoneyProps = {
  isSubscribed: boolean
}

const PayViaMobileMoney = ({ isSubscribed }: PayViaMobileMoneyProps) => {
  const [payViaMobileMoneySection, setPayViaMobileMoneySection] =
    useState<PayViaMobileMoneySectionState>('form')

  const renderView = useMemo(() => {
    switch (payViaMobileMoneySection) {
      case 'form':
        return (
          <PayViaMobileMoneyForm
            isSubscribed={isSubscribed}
            setPayViaMobileMoneySection={setPayViaMobileMoneySection}
          />
        )
      case 'instruction':
        return (
          <InstructionForPayingViaMobileMoney
            setPayViaMobileMoneySection={setPayViaMobileMoneySection}
          />
        )
      case 'final':
        return (
          <FinalScreenForPayViaMobileMoney
            setPayViaMobileMoneySection={setPayViaMobileMoneySection}
          />
        )
    }
  }, [isSubscribed, payViaMobileMoneySection])

  return <>{renderView}</>
}

export default PayViaMobileMoney
