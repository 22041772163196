import purchaseActionCreators from '@actionCreators/purchaseActionCreators'
import Loader from '@components/ui/Loader'
import PhoneNumberTextField from '@components/ui/PhoneNumberTextField'
import {
  EPaymentMethod,
  PayViaMobileMoneySectionState,
} from '@customTypes/purchase'
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks'
import { getPhoneNumberMaskForPayViaMobileMoney } from '@utils/common'
import { Dispatch, SetStateAction, useMemo } from 'react'
import { useForm } from 'react-hook-form'

type PayViaMobileMoneyFormProps = {
  setPayViaMobileMoneySection: Dispatch<SetStateAction<PayViaMobileMoneySectionState>>
  isSubscribed: boolean
}

const PayViaMobileMoneyForm = ({
  setPayViaMobileMoneySection,
  isSubscribed,
}: PayViaMobileMoneyFormProps) => {
  const dispatch = useAppDispatch()

  const productId = useAppSelector(state => state.purchase.selectedProduct?.id) || 0
  const { payViaMobileMoneyLoadState } = useAppSelector(state => state.purchase)

  const phoneNumberMask = useMemo(() => getPhoneNumberMaskForPayViaMobileMoney(), [])

  const { handleSubmit, control, formState, setError } = useForm<{ phoneNumber: string }>({
    // resolver: yupResolver(usingVoucherScheme),
  })

  const onSubmit = (dataForm: { phoneNumber: string }) => {
    const body = {
      paymentProviderId: EPaymentMethod.PayNowMobile,
      products: [{ productId, qty: 1 }],
      paymentProviderOptions: {
        phoneNumber: dataForm.phoneNumber.trim(),
      },
      // isSubscribed,
    }

    dispatch(purchaseActionCreators.payViaMobileMoney(body))
      .unwrap()
      .then(() => setPayViaMobileMoneySection('instruction'))
      .catch(() => {})
    // buyProduct(dataForm)
  }

  return (
    <>
      {payViaMobileMoneyLoadState.isLoading && <Loader className="spinner-fixed" />}
      <form onSubmit={handleSubmit(onSubmit)} className="order-balance">
        <div className="display-flex align-items-center gap-8">Use mobile money:</div>
        <PhoneNumberTextField
          name="phoneNumber"
          control={control}
          placeholder="Enter phone number"
          type="tel"
          mask={phoneNumberMask}
        />
        <button
          className="button primary large"
          //  disabled={isDisabledButton}
          type="submit"
        >
          Next
        </button>
      </form>
    </>
  )
}

export default PayViaMobileMoneyForm
