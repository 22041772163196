import eventActionCreators from '@actionCreators/eventActionCreators'
import Icon from '@components/Icon'
import EventList from '@components/event/EventList'
import EventMainList from '@components/event/EventMainList'
import { EEventType } from '@customTypes/event'
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks'

import { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import isEqual from 'lodash/isEqual'
import Loader from '@components/ui/Loader'
import debounce from 'lodash/debounce'

const getEventType = (type: string | null) => {
  // if (type === 'conferences') {
  //   return EEventType.Conferences
  // }
  if (type === 'film') {
    return EEventType.Film
  }

  return EEventType.Music
}

type EventListTabs = 'music' | 'conferences' | 'film'

const EventListPage = () => {
  const dispatch = useAppDispatch()

  const eventList = useAppSelector(state => state.event.eventList, isEqual)
  const getEventListIsLoading = useAppSelector(state => state.event.getEventListLoadState.isLoading)

  const [queryParams, setQueryParams] = useSearchParams()
  const [searchValue, setSearchValue] = useState('')
  const [isShownSearchInput, setIsShownSearchInput] = useState(false)

  const [tabValue, setTabValue] = useState<EventListTabs>('music')

  const searchInputRef = useRef<HTMLInputElement>(null)

  const onClickAway = useCallback((event: any) => {
    if (
      !(
        searchInputRef.current?.contains(event.target) ||
        event.target.closest('.clear-input') ||
        event.target.closest('.close-input')
      )
    ) {
      setIsShownSearchInput(false)
    }
  }, [])

  useEffect(() => {
    // if (isShown && isMobile) {
    document.addEventListener('click', onClickAway, { capture: true })

    return () => {
      document.removeEventListener('click', onClickAway, { capture: true })
    }
    // }
  }, [onClickAway])

  useEffect(() => {
    // if (queryParams.get('activeTab') === 'conferences') {
    //   setTabValue('conferences')
    //   return
    // }
    // if (queryParams.get('activeTab') === 'film') {
    //   setTabValue('film')
    //   return
    // }

    setTabValue('music')
    setQueryParams({ activeTab: 'music' })
  }, [queryParams, setQueryParams])

  const getEventList = useCallback(() => {
    const eventType = getEventType(queryParams.get('activeTab'))

    dispatch(eventActionCreators.getEventList({ type: eventType }))
  }, [dispatch, queryParams])

  const getEventListT = useCallback(
    (value: string) => {
      const eventType = getEventType(queryParams.get('activeTab'))

      dispatch(eventActionCreators.getEventList({ type: eventType, searchValue: value }))
    },
    [dispatch, queryParams]
  )

  const debounceFunction = useMemo(() => debounce(getEventListT, 600), [getEventListT])

  useEffect(() => {
    getEventList()
  }, [getEventList])

  const mainEventList = useMemo(() => {
    return eventList.filter(event => event.isMainEvent)
  }, [eventList])

  const simpleEventList = useMemo(() => {
    return eventList.filter(event => !event.isMainEvent)
  }, [eventList])

  const onSearchInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value

      setSearchValue(value)
      debounceFunction(value.trim())
    },
    [debounceFunction]
  )

  const onTabClick = useCallback(
    (tabValue: string) => {
      setQueryParams({ activeTab: tabValue })
      setSearchValue('')
    },
    [setQueryParams]
  )

  const onSearchBtnClick = useCallback(() => {
    setIsShownSearchInput(true)
    searchInputRef?.current?.focus()
  }, [])
  const onCloseBtnClick = useCallback(() => {
    setIsShownSearchInput(false)
  }, [])

  const onClearSearchInput = useCallback(() => {
    setSearchValue('')
    debounceFunction('')
  }, [debounceFunction])
  return (
    <>
      {getEventListIsLoading && <Loader className="spinner-fixed" />}

      {/* <div className="centered-page-content light-background"> */}
      <div className="ms-filters">
        <nav className="ms-tabs">
          <button
            className={`ms-tab ${tabValue === 'music' ? 'is-active' : ''}`}
            onClick={() => onTabClick('music')}
          >
            Music
          </button>
          {/* <button
            className={`ms-tab ${tabValue === 'conferences' ? 'is-active' : ''}`}
            onClick={() => onTabClick('conferences')}
          >
            Conferences
          </button> */}
          {/* <button
            className={`ms-tab ${tabValue === 'film' ? 'is-active' : ''}`}
            onClick={() => onTabClick('film')}
          >
            Featured Film
          </button> */}
        </nav>

        <div className="search-box ml-auto">
          <input
            ref={searchInputRef}
            className={isShownSearchInput ? 'show-input' : ''}
            type="text"
            placeholder="Find events, artists and more"
            value={searchValue}
            onChange={onSearchInputChange}
          />
          {isShownSearchInput ? (
            <>
              {searchValue && (
                <button
                  className="icon-button transparent clear-input"
                  onClick={onClearSearchInput}
                >
                  <Icon name="delete" size={32} />
                </button>
              )}
              <button className="icon-button transparent close-input" onClick={onCloseBtnClick}>
                <Icon name="arrow-right-to-line" size={32} />
              </button>
            </>
          ) : (
            <button className="icon-button transparent" onClick={onSearchBtnClick}>
              <Icon name="search" size={32} />
            </button>
          )}
        </div>
      </div>
      {/* {!getEventListIsLoading && ( */}
      <div className="events-grid">
        {mainEventList.length > 0 && (
          <div className="banner-cards">
            <EventMainList eventList={mainEventList} />
          </div>
        )}
        <EventList eventList={simpleEventList} />
      </div>
      {/* )} */}
      {/* </div> */}
    </>
  )
}

export default EventListPage
