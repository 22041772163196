import EventItem from './EventItem'
import { ShortEvent } from '@customTypes/event'

type EventListProps = {
  eventList: Array<ShortEvent>
}

const EventList = ({ eventList }: EventListProps) => {
  return eventList.map(eventItem => <EventItem event={eventItem} key={eventItem.id} />)
}

export default EventList
