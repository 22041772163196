// import AnimationText from '@components/AnimationText'
import LoginViaEmailAndPassword from '@components/login/loginViaEmailAndPassword/LoginViaEmailAndPassword'
import LoginViaSocialMedia from '@components/login/LoginViaSocialMedia'
import { useLayoutEffect } from 'react'
import { useLocation } from 'react-router-dom'

type LocationState = {
  state: {
    from: string
  }
}

const LoginPage = () => {
  const location = useLocation() as LocationState
  const urlFrom = location?.state?.from || '/events'

  useLayoutEffect(() => {
    const mainLayout = document.querySelector('.main-page-layout')

    mainLayout!.classList.add('no-background')
    return () => {
      mainLayout!.classList.remove('no-background')
    }
  }, [])

    return (
      <div className="span-all-areas">
        <div className="centered-content align-self-start">
          {/* <h2 className="event-title text-uppercase shining-text mb-32" style={{textAlign: 'center'}}>
            <AnimationText text="A$AP: African solutions to African problems" /> 
          </h2> */}
          <div className="event-data-wrapper align-self-center mt-32">
            <LoginViaEmailAndPassword urlFrom={urlFrom}/>
            <div className="title-with-lines">
              <hr/><span>OR</span><hr/>
            </div>
            <LoginViaSocialMedia urlFrom={urlFrom}/>
            <div className="display-flex gap-16 mt-16 mb-32" style={{ maxWidth: '320px' }}>
              <span style={{ fontSize: '12px', textAlign: 'center' }}>
              By logging into your account or creating a new one, you agree to our Rules, Practices
              and{' '}
              <a
                className="link"
                href="https://mtzfront.s3.eu-west-3.amazonaws.com/MastreetzTermsOfUse%26PrivacyPolicy.pdf"
                target="_blank"
                rel="noreferrer noopener"
              >
                Privacy Policy
              </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    )
}

export default LoginPage
