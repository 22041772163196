import { useCountdown } from '@hooks/useCountdown'
import { Dispatch, SetStateAction } from 'react'

type CountDownProps = {
  countDownDate: string
  type: 'full-screen-countdown' | 'small-countdown'
  stopTimerTime: number
  withMilliseconds: boolean
  setIsCountDownFinished: Dispatch<SetStateAction<boolean>>
}

const CountDown = ({ countDownDate, type, stopTimerTime, withMilliseconds, setIsCountDownFinished }: CountDownProps) => {
  const [dayRef, hoursRef, minutesRef, secondsRef, msRef] = useCountdown(
    countDownDate,
    withMilliseconds,
    stopTimerTime,
    setIsCountDownFinished,
  )

  return (
    <div
      className={`count-down-wrapper${
        type === 'full-screen-countdown' ? ' full-screen-countdown' : ''
      }`}
    >
      {type === 'small-countdown' && (
        <>
          <div>
            <span>Days</span>
            <p className="count-down count-down-left count-down-number" ref={dayRef} />
          </div>

          <p className="count-down count-down-left count-down-divider" data-text=":">
            :
          </p>
        </>
      )}
      {type === 'small-countdown' && (
        <>
          <div>
            <span>Hours</span>
            <p className="count-down count-down-left count-down-number" ref={hoursRef} />
          </div>

          <p className="count-down count-down-center count-down-divider" data-text=":">
            :
          </p>
        </>
      )}

      <div>
        {type === 'small-countdown' && <span>Minutes</span>}
        <p
          className={`count-down count-down-number ${
            type === 'small-countdown' ? 'count-down-right' : 'count-down-left'
          }`}
          ref={minutesRef}
        />
      </div>
      <p
        className={`count-down count-down-divider ${
          type === 'small-countdown' ? 'count-down-right' : 'count-down-left'
        }`}
        data-text=":"
      >
        :
      </p>
      <div>
        {type === 'small-countdown' && <span>Seconds</span>}
        <p
          className={`count-down count-down-number ${
            type === 'small-countdown' ? 'count-down-right' : 'count-down-center'
          }`}
          ref={secondsRef}
        />
      </div>

      {type === 'full-screen-countdown' && (
        <>
          <p className="count-down count-down-divider count-down-right" data-text=":">
            :
          </p>
          <p className="count-down count-down-number count-down-right" ref={msRef} />
        </>
      )}
    </div>
  )
}

export default CountDown
