import * as yup from 'yup'
import { checkEmail } from './utils'

const becomeAgentFormScheme = yup.object({
  firstName: yup
    .string().trim()
    .typeError('Required field')
    .required('Required field')
    .matches(
      /^[\s]*([a-zA-Z]{1,}[\s]{0,1}[-.`‘’']{0,1}[\s]{0,1})+[a-zA-Z.]*[\s]*$/,
      'Please, use letters only'
    ),
  lastName: yup
    .string().trim()
    .typeError('Required field')
    .required('Required field')
    .matches(
      /^[\s]*([a-zA-Z]{1,}[\s]{0,1}[-.`‘’']{0,1}[\s]{0,1})+[a-zA-Z.]*[\s]*$/,
      'Please, use letters only'
    ),
  email: yup
    .string().trim()
    .test('validateEmail', 'Email is invalid', checkEmail),
  address: yup
    .string().trim()
    .typeError('Required field')
    .required('Required field')
    .matches(
      /^[\s]*([a-zA-Z0-9]{1,}[\s]{0,1}[-,./]{0,2}[\s]{0,1})+[\s]*$/,
      "Address is invalid"
    ),
  phoneNumber: yup
    .string().trim()
    .typeError('Required field')
    .required('Required field')
    .matches(
      /^[0-9+\s()]{5,}$/,
      'Phone Number is invalid'
    )
  ,
  documentNumber: yup
    .string().trim()
    .typeError('Required field')
    .required('Required field')
    .min(7, `Please enter a complete document number.`)
    .matches(
      /^[\s]*([a-zA-Z0-9]{1,}[\s]{0,1}[-]{0,1}[\s]{0,1})+[a-zA-Z0-9]*[\s]*$/,
      'Please, use letters and numbers only'
    )
})

const agentYupSchemes = {
  becomeAgentFormScheme
}

export default agentYupSchemes