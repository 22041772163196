import { memo, useCallback, useEffect, useState } from 'react'
import splashGif from '@images/Mastreetz.gif'

const SplashScreen = memo(() => {
  const [isShow, setIsShow] = useState(true)

  const hideSplashScreen = useCallback(() => {
    setIsShow(false)
  }, [])

  useEffect(()=>{
    const timer = setTimeout(hideSplashScreen, 2000)

    return () => {
      clearTimeout(timer)
    }
  },[hideSplashScreen])

  if (!isShow) {
    return null
  }
  return (
    <div className="player full-screen">
      <div className="video-container">
        {/* <video
          autoPlay
          muted
          playsInline
          style={{ width: '100%' }}
          // src="https://mtzfront.s3.eu-west-3.amazonaws.com/Mastreetz+3.mp4"
          src="https://mtzfront.s3.eu-west-3.amazonaws.com/splash.mov"
          // onEnded={onEndedVideo}
        /> */}
       <img src={splashGif}/>
      </div>
    </div>
  )
})

export default SplashScreen
