import Icon from '@components/Icon'
import { PaymentProvider } from '@customTypes/event'
import { EPaymentMethod } from '@customTypes/purchase'
import { CommonIcons } from '@helpers/commonIcons'
import { FinanceIcons } from '@helpers/financeIcons'
import innBucksLogo from '@images/640px-InnBucks_MicroBank_Limited.jpg'

const getPaymentMethodList = (paymentMethods: Array<PaymentProvider>) => {
  const methods = {
    [EPaymentMethod.Wallet]: {
      title: 'Wallet:',
      paymentMethodId: EPaymentMethod.Wallet,
      mainIcon: <></>,
      additionalIcons: [],
    },
    [EPaymentMethod.StripePaymentLink]: {
      title: 'Card payment:',
      paymentMethodId: EPaymentMethod.StripePaymentLink,
      mainIcon: <Icon name="card" size={20} />,
      additionalIcons: [
        <Icon name="visa" size={32} />,
        <Icon name="mastercard" size={32} />,
        <Icon name="apple-pay" size={32} />,
        <Icon name="google-pay" size={32} />,
      ],
    },
    [EPaymentMethod.StripeIntent]: {
      title: 'Card payment:',
      paymentMethodId: EPaymentMethod.StripeIntent,
      mainIcon: <Icon name="card" size={20} />,
      additionalIcons: [
        <Icon name="visa" size={32} />,
        <Icon name="mastercard" size={32} />,
        <Icon name="apple-pay" size={32} />,
        <Icon name="google-pay" size={32} />,
      ],
    },
    [EPaymentMethod.ByMeACoffee]: {
      title: 'Card payment:',
      paymentMethodId: EPaymentMethod.ByMeACoffee,
      mainIcon: <Icon name="card" size={20} />,
      additionalIcons: [
        <Icon name="visa" size={32} />,
        <Icon name="mastercard" size={32} />,
        <Icon name="apple-pay" size={32} />,
        <Icon name="google-pay" size={32} />,
      ],
    },
    [EPaymentMethod.PayNowMobile]: {
      title: 'Mobile money:',
      paymentMethodId: EPaymentMethod.PayNowMobile,
      mainIcon: <CommonIcons.MobileMoney width={20} height={20} />,
      additionalIcons: [<FinanceIcons.EcoCash width={50} height={32} />],
    },
    [EPaymentMethod.InnBucks]: {
      title: 'Pay via Innbucks:',
      paymentMethodId: EPaymentMethod.InnBucks,
      mainIcon: <CommonIcons.MobileMoney width={20} height={20} />,
      additionalIcons: [<img style={{width: '50px'}} src={innBucksLogo} alt="InnBucks Logo"/>],
    },
    [EPaymentMethod.Voucher]: {
      title: 'Voucher:',
      paymentMethodId: EPaymentMethod.Voucher,
      mainIcon: <Icon name="voucher" size={20} />,
      additionalIcons: [],
    },
  }

  const activePaymentMethods = paymentMethods.filter(paymentMethod => paymentMethod.isActive)

  return activePaymentMethods.map(activePaymentMethod => methods[activePaymentMethod.providerId])
}

const eventUtils = {
  getPaymentMethodList,
}

export default eventUtils
