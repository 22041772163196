import { EPaymentMethod, Order } from "./purchase"

export enum EProductType {
  Video = 1,
  Ticket = 2,
  Thing = 3,
  Food = 4,
}

export type EventPageSection =
  | 'main'
  | 'payment'
  | 'after-payment'
  | 'full-screen-countdown'
  // | 'finish-countdown'
  | 'player'
  | 'end'

export enum VideoRotation {
  Horizontal = 1,
  Vertical = 2,
}

export type Video = {
  id: number
  previewUrl: string
  rotation: VideoRotation
  url?: string
  isPurchased: boolean
  chat: Chat
}

type Chat = {
  id: number
  title: string
}

type Currency = {
  sign: string
  code: string
}

export type Product = {
  id: number
  imgUrl: string
  isPurchased: boolean
  price: string
  title: string
  shortDescription: string
  paymentLink: string
  qty?: number
  eventId: number
  currency: Currency
  type: EProductType
  video: Pick<Video, 'id' | 'isPurchased'>
}

export type PaymentProvider = {
  id: number
  providerId: EPaymentMethod
  isActive: boolean
}

export enum EEventType {
  Music = 1,
  Conferences = 2,
  Film = 3,
}

export type ShortEvent = {
  id: number
  artist: string
  title: string
  shortDescription: string
  description: string
  startDate: string
  endDate: string
  isMainEvent: boolean
  isOnline: boolean
  url: string
  verticalImg: string
  horizontalImg: string
  country: string
  city: string
  street: string
  address: string
  coordinates: string
  type: EEventType
}

export type Event = {
  id: number
  artist: string
  title: string
  shortDescription: string
  description: string
  startDate: string
  endDate: string
  products: Array<Product>
  paymentProviders: Array<PaymentProvider>
  isMainEvent: boolean
  isOnline: boolean
  url: string
  verticalImg: string
  horizontalImg: string
  type: EEventType
  country: string
  city: string
  address: string
  coordinates: string

  // video: Video
  // chat: Chat
}

export type EventListResponse = {
  data: Array<ShortEvent>
}



export type GetOrderList = {
  data: Array<Order>
}

export type AddLikesByVideoIdRequestBody = {
  // videoId: number
  count: number
}

export type AddLikesByVideoIdRequestArg = AddLikesByVideoIdRequestBody & {
  videoId: number
}

export type UsingVoucherFormType = {
  code: string
}

export type GetEventListRequestParams = {
  type: EEventType
  searchValue?: string
}