import { memo, useMemo } from 'react'
type BadgeProps = {
  type: 'buy' | 'watch'
}
const Badge = memo(({ type }: BadgeProps) => {
  const renderView = useMemo(() => {
    switch (type) {
      case 'buy':
        return <div className="ms-default-badge ms-buy-badge">Buy tickets</div>
      case 'watch':
        return <div className="ms-default-badge">View</div>
    }
  }, [type])
  return <>{renderView}</>
})

export default Badge
