import { EPaymentMethod } from '@customTypes/purchase'
import { Fragment, useCallback } from 'react'

type PaymentOptionProps = {
  paymentMethod: {
    title: string
    paymentMethodId: EPaymentMethod
    mainIcon: JSX.Element
    additionalIcons: Array<JSX.Element>
  }
  linkToPaymentProvider: string
  onParentClick: (paymentMethodId: EPaymentMethod) => void
}

const PaymentOption = ({
  paymentMethod,
  linkToPaymentProvider,
  onParentClick,
}: PaymentOptionProps) => {
  const onClick = useCallback(() => {
    onParentClick(paymentMethod.paymentMethodId)
  }, [onParentClick, paymentMethod.paymentMethodId])

  return paymentMethod.paymentMethodId === EPaymentMethod.ByMeACoffee ||
    paymentMethod.paymentMethodId === EPaymentMethod.StripePaymentLink ? (
    <div
      className="display-flex align-items-center gap-8 justify-content-center"
      style={{ minHeight: '55px' }}
    >
      <a href={linkToPaymentProvider} style={{ textDecoration: 'none' }}>
        <div className="display-flex align-items-center gap-8 justify-content-center">
          {paymentMethod.mainIcon}
          {paymentMethod.title}
        </div>
        <div className="display-flex align-items-center gap-8 justify-content-center">
          {paymentMethod.additionalIcons.map((icon, index) => (
            <Fragment key={index}>{icon}</Fragment>
          ))}
        </div>
      </a>
    </div>
  ) : (
    <div
      className="display-flex align-items-center gap-8 justify-content-center"
      style={{ minHeight: '55px' }}
      onClick={onClick}
    >
      <div>
        <div className="display-flex align-items-center gap-8 justify-content-center">
          {paymentMethod.mainIcon}
          {paymentMethod.title}
        </div>
        {paymentMethod.additionalIcons.length !== 0 && (
          <div className="display-flex align-items-center gap-8 justify-content-center">
            {paymentMethod.additionalIcons.map((icon, index) => (
              <Fragment key={index}>{icon}</Fragment>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default PaymentOption
