import { SocialMediaIcons } from '@helpers/socialMediaIcons'
import React, { memo } from 'react'

const SocialMediaSection = memo(() => {
  return (
    <div className="display-flex justify-content-center gap-48">
      <a
        className="icon-button social-icon facebook-social-icon"
        href="https://www.facebook.com/profile.php?id=61558470034210"
        target="_blank"
        rel="noreferrer noopener"
      >
        <SocialMediaIcons.Facebook width={70} height={70} />
      </a>
      <a
        className="icon-button social-icon youtube-social-icon"
        href="https://www.youtube.com/@Mastreetz"
        target="_blank"
        rel="noreferrer noopener"
      >
        <SocialMediaIcons.YouTube width={50} height={50} />
      </a>
      <a
        className="icon-button social-icon instagram-social-icon"
        href="https://www.instagram.com/ma_streetz?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
        target="_blank"
        rel="noreferrer noopener"
      >
        <SocialMediaIcons.Instagram width={58} height={58} />
      </a>
    </div>
  )
})

export default SocialMediaSection
