import { Product } from "./event"

export type PaymentSectionState =
  | 'payment-option-list'
  | 'mobile-payment'
  | 'innBucks'
  | 'card'
  | 'voucher-payment'

export type PayViaMobileMoneySectionState =
  | 'form'
  | 'instruction'
  | 'final'

export type PayViaInnBucksSectionState =
  | 'form'
  | 'qr'
  | 'final'

export type PayViaVoucherSectionState =
  | 'form'
  | 'final'

export enum EProductType {
  Video = 1,
  Ticket = 2,
  Wallet = 3,
}

export enum EPaymentMethod {
  Wallet = 1,
  StripePaymentLink = 2,
  StripeIntent = 3,
  ByMeACoffee = 4,
  PayNowMobile = 5,
  Voucher = 6,
  InnBucks = 7,
}

enum EOrderStatus {
  Pending = 1,
  Processing = 5,
  Completed = 10,
  Cancelled = 15,
}

export enum EOrderPaymentStatus {
  Pending = 1,
  Paid = 5,
  Refunded = 10,
  PartiallyRefunded = 15,
  Canceled = 20,

}

export type PayViaVoucherBodyRequest = {
  paymentProviderId: number,
  products: Array<{
    productId: number,
    qty: number
  }>

  paymentProviderOptions: {
    voucherCode: string,
  }
}





export type CreateOrderRequestBody = {

  paymentProviderId: number,
  products: Array<{
    productId: number,
    qty: number
  }>

  paymentProviderOptions?: {
    phoneNumber?: string,
    buyerInfo?: {
      idVerified: boolean,
      // email: string,
    }
    recipientEmail?: string
  }
}

export type PayViaCardRequestBody = {
  paymentProviderId: number,
  products: Array<{
    productId: number,
    qty: number
  }>
  // isSubscribed: boolean
}

export type PayViaCardResponseBody = {
  clientSecret: string
}

export type PayViaMobileMoneyRequestBody = {
  paymentProviderId: number,
  products: Array<{
    productId: number,
    qty: number
  }>

  paymentProviderOptions: {
    phoneNumber: string,
  }
}

export type PayViaInnBucksRequestBody = {
  paymentProviderId: number,
  products: Array<{
    productId: number,
    qty: number
  }>

  paymentProviderOptions: {
    phoneNumber: string,
  }
}


export type PayViaInnBucksResponseBody = {
  authorizationcode: string,
  deep_link_url: string,
  qr_code: string,
  expires_at: string,
}

export type OrderItem = {

  id: number
  imgUrl: string
  price: string
  title: string
  qty: number

}

export type Order = {
  id: number
  createdAt: string
  eventTitle: string
  totalAmount: string
  orderItems: Array<OrderItem>
}


