import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'
import Icon from '@components/Icon'
import TextFieldForm from '@components/ui/TextFieldForm'
import { SignUpArgsRequest, SignUpFormType } from '@customTypes/auth'
import { LoginScreenState } from '@customTypes/login'
import { yupResolver } from '@hookform/resolvers/yup'
import { useAppSelector } from '@hooks/reduxHooks'
import loginYupSchemes from '@utils/yupSchemes/loginYupSchemes'
import { useForm } from 'react-hook-form'
import { trimObjectFields } from '@utils/common'
import PasswordTextFieldForm from '@components/ui/PasswordTextFieldForm'
import omit from 'lodash/omit'
import isEmpty from 'lodash/isEmpty'

type SignUpFormProps = {
  onParentSubmit: (formData: SignUpArgsRequest) => void
  userData: SignUpArgsRequest
  setCurrentScreen: Dispatch<SetStateAction<LoginScreenState>>
}

const SignUpForm = ({ onParentSubmit, setCurrentScreen, userData }: SignUpFormProps) => {
  const signUpIsLoading = useAppSelector(state => state.auth.signUpLoadState.isLoading)

  const [isDisabledButton, setIsDisabledButton] = useState(false)
  const [isExistLowercaseChar, setIsExistLowercaseChar] = useState(false)
  const [isExistUppercaseChar, setIsExistUppercaseChar] = useState(false)
  const [isExistNumber, setIsExistNumber] = useState(false)
  const [isMinCharNumber, setIsMinCharNumber] = useState(false)

  const { handleSubmit, control, formState, setValue, watch } = useForm<SignUpFormType>({
    resolver: yupResolver(loginYupSchemes.signUpFormScheme),
  })

  const watchedPassword = watch('password')

  const checkPassword = useCallback(() => {
    const lowercasePattern = /[a-z]/
    const uppercasePattern = /[A-Z]/
    const numberPattern = /\d/
    const isMinCharNumber = watchedPassword?.length >= 8

    setIsExistLowercaseChar(lowercasePattern.test(watchedPassword))

    setIsExistUppercaseChar(uppercasePattern.test(watchedPassword))

    setIsExistNumber(numberPattern.test(watchedPassword))

    setIsMinCharNumber(isMinCharNumber)
  }, [watchedPassword])

  useEffect(() => {
    checkPassword()
  }, [checkPassword])

  const setFormValue = useCallback(() => {
    setValue('email', userData.email)
    setValue('firstName', userData.firstName)
    setValue('lastName', userData.lastName)
    setValue('password', '')
  }, [setValue, userData.email, userData.firstName, userData.lastName])

  useEffect(() => {
    setFormValue()
  }, [setFormValue])

  useEffect(() => {
    if (isEmpty(formState.errors)) {
      setIsDisabledButton(false)
    } else {
      setIsDisabledButton(true)
    }
  }, [formState])

  const onSubmit = (dataForm: SignUpFormType) => {
    const trimmedDataForm = trimObjectFields(
      omit(dataForm, ['confirmPassword'])
    ) as SignUpArgsRequest

    onParentSubmit(trimmedDataForm)
  }

  const onBack = useCallback(() => {
    setCurrentScreen('email')
  }, [setCurrentScreen])

  return (
    <>
      <div className="form-title">
        <button className="icon-button transparent back-button" onClick={onBack}>
          <Icon name="arrow-right" size={20} />
        </button>
        <h4>Sign Up</h4>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextFieldForm
          name="email"
          control={control}
          placeholder="Enter email"
          type="string"
          label="Email"
          maxLength={64}
          autoComplete="on"
        />
        {/* <TextFieldForm
          name="password"
          control={control}
          placeholder="Enter password"
          type="password"
          label="* Password"
          maxLength={32}
        /> */}
        <PasswordTextFieldForm
          name="password"
          control={control}
          placeholder="Enter password"
          label="* Password"
          maxLength={32}
          isShownVisibleBtn
        />
        <p
          style={{ color: isExistLowercaseChar ? 'green' : '#D6D6D6', fontSize: '12px' }}
          className="display-flex align-items-center gap-8 mt-8 mb-8 px-8"
        >
          <Icon
            size={16}
            name="check-mark"
            style={
              isExistLowercaseChar ? { '--icon-color': 'green' } : { '--icon-color': '#D6D6D6' }
            }
          />{' '}
          One lowercase character
        </p>
        <p
          style={{ color: isExistUppercaseChar ? 'green' : '#D6D6D6', fontSize: '12px' }}
          className="display-flex align-items-center gap-8 mt-8 mb-8 px-8"
        >
          <Icon
            size={16}
            name="check-mark"
            style={
              isExistUppercaseChar ? { '--icon-color': 'green' } : { '--icon-color': '#D6D6D6' }
            }
          />
          One uppercase character
        </p>
        <p
          style={{ color: isExistNumber ? 'green' : '#D6D6D6', fontSize: '12px' }}
          className="display-flex align-items-center gap-8 mt-8 mb-8 px-8"
        >
          <Icon
            size={16}
            name="check-mark"
            style={isExistNumber ? { '--icon-color': 'green' } : { '--icon-color': '#D6D6D6' }}
          />
          One number
        </p>
        <p
          style={{ color: isMinCharNumber ? 'green' : '#D6D6D6', fontSize: '12px' }}
          className="display-flex align-items-center gap-8 mt-8 mb-16 px-8"
        >
          <Icon
            size={16}
            name="check-mark"
            style={isMinCharNumber ? { '--icon-color': 'green' } : { '--icon-color': '#D6D6D6' }}
          />
          8 characters minimum
        </p>
        <PasswordTextFieldForm
          name="confirmPassword"
          control={control}
          placeholder="Enter confirm password"
          label="Confirm password"
          maxLength={32}
          isShownVisibleBtn={false}
        />
        <TextFieldForm
          name="firstName"
          control={control}
          placeholder="Enter first name"
          type="text"
          label="First Name"
          maxLength={64}
        />
        <TextFieldForm
          name="lastName"
          control={control}
          placeholder="Enter last name"
          type="text"
          label="Last Name"
          maxLength={64}
        />
        <div className="mb-24 px-8" style={{ fontStyle: 'italic' }}>
          * Password must contain at least one: number (0-9), upper case letter [A-Z], lower case
          letter [a-z] and have min 8 symbols
        </div>
        <button
          className="button primary large w-100"
          disabled={
            isDisabledButton ||
            signUpIsLoading ||
            !isExistLowercaseChar ||
            !isExistUppercaseChar ||
            !isExistNumber ||
            !isMinCharNumber
          }
          type="submit"
        >
          Sign Up
        </button>
      </form>
    </>
  )
}

export default SignUpForm
