import { Product } from '@customTypes/event'
import React, { useCallback, useMemo, useState } from 'react'

type AgentCreateOrderTableRowProps = {
  product: Product
  toParentComp: (productId: number, quantity: number, productTotal: number) => void
}

const AgentCreateOrderTableRow = ({ product,toParentComp }: AgentCreateOrderTableRowProps) => {
  const [quantity, setQuantity] = useState(0)

  const imgUrl = useMemo(() => {
    if (product.imgUrl === 'image' || !product.imgUrl) {
      return 'https://mtzfront.s3.eu-west-3.amazonaws.com/default_vertical.jpg'
    }

    return product.imgUrl
  }, [product.imgUrl])

  const onMinusClick = useCallback(()=>{
    setQuantity(prev => prev - 1)
    const qty = quantity - 1
    const productTotal = qty * Number(product.price)

    toParentComp(product.id, qty, productTotal)
  },[product.id, product.price, quantity, toParentComp])

  const onPlusClick = useCallback(()=>{
    setQuantity(prev => prev + 1)
    const qty = quantity + 1
    const productTotal = qty * Number(product.price)

    toParentComp(product.id, qty, productTotal)
  },[product.id, product.price, quantity, toParentComp])

  return (
    <div className="table-row agent-create-order-grid-columns">
      <div className="table-cell table-cell-img">
        {/* <div className="table-cell-img"> */}
        <img
          src={imgUrl}
        />
        {/* </div> */}
      </div>
      <div className="table-cell">{product.title}</div>
      <div className="table-cell">{product.shortDescription}</div>
      <div className="table-cell">{product.currency.sign}{Number(product.price).toFixed(2)}</div>
      <div className="table-cell" style={{display: 'flex', gap: '10px', alignItems: 'center'}}>
        <button disabled={quantity <= 0} onClick={onMinusClick}>-</button>
        {quantity}
        <button onClick={onPlusClick}>+</button>
      </div>
    </div>
  )
}

export default AgentCreateOrderTableRow
