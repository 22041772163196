import { ShortEvent } from '@customTypes/event'
import React, { useCallback, useEffect, useMemo } from 'react'
import { Link } from 'react-router-dom'

type AgentEventTableProps = {
  list: Array<ShortEvent>
}

const AgentEventTable = ({ list }: AgentEventTableProps) => {
  const getTitle = useCallback((event: ShortEvent) => {
    if (event.artist && event.title) {
      return `${event.artist} - ${event.title}`
    }

    if (event.artist) {
      return `${event.artist}`
    }

    if (event.title) {
      return `${event.title}`
    }

    return ''
  }, [])
  return (
    <div className="table">
      <div className="table-header agent-events-grid-columns">
        <div className="table-cell"></div>
        {/* <div className="table-cell">Artist</div> */}
        <div className="table-cell">Title</div>
        <div className="table-cell">Short Description</div>
        <div className="table-cell"></div>
      </div>
      <div className="table-body-agent-events">
        {list.map(event => (
          <div key={event.id} className="table-row agent-events-grid-columns">
            <div className="table-cell table-cell-img">
              {/* <div className="table-cell-img"> */}
              <img
                src={
                  event.verticalImg ||
                  'https://mtzfront.s3.eu-west-3.amazonaws.com/default_vertical.jpg'
                }
              />
              {/* </div> */}
            </div>
            {/* <div className="table-cell">{event.artist}</div> */}
            <div className="table-cell">{getTitle(event)}</div>
            <div className="table-cell">{event.shortDescription}</div>
            <div className="table-cell display-flex align-items-center justify-content-center">
              <Link className="button middle primary" to={`/agent/create-order/${event.url}`}>
                Sell
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default AgentEventTable
