import authActionCreators from "@actionCreators/authActionCreators"
import purchaseActionCreators from "@actionCreators/purchaseActionCreators"
import { CustomErrorResponse, LoadState } from "@customTypes/common"
import { Product } from "@customTypes/event"
import { EOrderPaymentStatus, PayViaInnBucksResponseBody } from "@customTypes/purchase"
import { createSlice } from "@reduxjs/toolkit"
import { StripeError } from "@stripe/stripe-js"
import localStorageData from "@utils/localStorage"

type PurchaseSlice = {
  error: CustomErrorResponse | null
  isPayed: boolean
  selectedProduct: Product | null
  instructionForMobileMoneyPaying: string
  payViaMobileMoneyLoadState: LoadState
  checkPaymentViaMobileMoneyLoadState: LoadState
  currentPurchaseStatus: EOrderPaymentStatus | null
  currentClientSecret: string
  currentPurchaseError: StripeError | null
  getProductByIdLoadState: LoadState

  buyViaAgentLoadState: LoadState

  dataAfterCreationOrderViaInnBucks: PayViaInnBucksResponseBody | null
  payViaInnBucksLoadState: LoadState

  payViaVoucherLoadState: LoadState
}

const initialState: PurchaseSlice = {
  error: null,
  isPayed: false,
  instructionForMobileMoneyPaying: '',
  payViaMobileMoneyLoadState: {
    isLoading: false,
    isLoaded: false,
  },
  checkPaymentViaMobileMoneyLoadState: {
    isLoading: false,
    isLoaded: false,
  },
  getProductByIdLoadState: {
    isLoading: false,
    isLoaded: false,
  },
  currentPurchaseStatus: null,
  currentClientSecret: '',
  currentPurchaseError: null,
  selectedProduct: null,

  buyViaAgentLoadState: {
    isLoading: false,
    isLoaded: false,
  },

  dataAfterCreationOrderViaInnBucks: null,
  payViaInnBucksLoadState: {
    isLoading: false,
    isLoaded: false,
  },

  payViaVoucherLoadState: {
    isLoading: false,
    isLoaded: false,
  },
}


export const purchaseSlice = createSlice({
  name: 'purchase',
  initialState,
  reducers: {
    setCurrentPurchaseError: (state, action) => {
      state.currentPurchaseError = action.payload
    },

    setSelectedProductToStore: (state, action) => {
      state.selectedProduct = action.payload
    },
    clearPurchaseError: state => {
      state.error = null
    }
  },
  extraReducers: builder => {
    builder
      .addCase(purchaseActionCreators.payViaMobileMoney.pending, state => {
        state.payViaMobileMoneyLoadState = {
          isLoading: true,
          isLoaded: false,
        }
      })
      .addCase(purchaseActionCreators.payViaMobileMoney.fulfilled, (state, action) => {
        state.payViaMobileMoneyLoadState = {
          isLoading: false,
          isLoaded: true,
        }
        state.instructionForMobileMoneyPaying = action.payload
      })
      .addCase(purchaseActionCreators.payViaMobileMoney.rejected, state => {
        state.payViaMobileMoneyLoadState = {
          isLoading: false,
          isLoaded: false,
        }
      })
      .addCase(purchaseActionCreators.checkPaymentViaMobileMoney.pending, state => {
        state.currentPurchaseStatus = null
        state.checkPaymentViaMobileMoneyLoadState = {
          isLoading: true,
          isLoaded: false,
        }
      })
      .addCase(purchaseActionCreators.checkPaymentViaMobileMoney.fulfilled, (state, action) => {
        state.currentPurchaseStatus = action.payload.status
        state.checkPaymentViaMobileMoneyLoadState = {
          isLoading: false,
          isLoaded: true,
        }
      })
      .addCase(purchaseActionCreators.checkPaymentViaMobileMoney.rejected, state => {
        state.checkPaymentViaMobileMoneyLoadState = {
          isLoading: false,
          isLoaded: false,
        }
      })
      .addCase(purchaseActionCreators.payViaCard.pending, state => {

      })
      .addCase(purchaseActionCreators.payViaCard.fulfilled, (state, action) => {
        state.currentClientSecret = action.payload.clientSecret
      })
      .addCase(purchaseActionCreators.payViaCard.rejected, state => {

      })

      .addCase(purchaseActionCreators.getProductById.pending, state => {
        state.getProductByIdLoadState = {
          isLoading: true,
          isLoaded: false,
        }
      })
      .addCase(purchaseActionCreators.getProductById.fulfilled, (state, action) => {
        state.getProductByIdLoadState = {
          isLoading: false,
          isLoaded: true,
        }
        state.selectedProduct = action.payload.product
      })
      .addCase(purchaseActionCreators.getProductById.rejected, state => {
        state.getProductByIdLoadState = {
          isLoading: false,
          isLoaded: false,
        }
      })
      .addCase(purchaseActionCreators.buyViaAgent.pending, state => {
        state.buyViaAgentLoadState = {
          isLoading: true,
          isLoaded: false,
        }
      })
      .addCase(purchaseActionCreators.buyViaAgent.fulfilled, state => {
        state.buyViaAgentLoadState = {
          isLoading: false,
          isLoaded: true,
        }
      })
      .addCase(purchaseActionCreators.buyViaAgent.rejected, state => {
        state.buyViaAgentLoadState = {
          isLoading: false,
          isLoaded: false,
        }
      })

      .addCase(purchaseActionCreators.payViaInnBucks.pending, state => {
        state.payViaInnBucksLoadState = {
          isLoading: true,
          isLoaded: false,
        }
      })
      .addCase(purchaseActionCreators.payViaInnBucks.fulfilled, (state, action) => {
        state.payViaInnBucksLoadState = {
          isLoading: false,
          isLoaded: true,
        }
        state.dataAfterCreationOrderViaInnBucks = action.payload
      })
      .addCase(purchaseActionCreators.payViaInnBucks.rejected, state => {
        state.payViaInnBucksLoadState = {
          isLoading: false,
          isLoaded: false,
        }
      })

      .addCase(purchaseActionCreators.payViaVoucher.pending, state => {
        state.payViaVoucherLoadState = {
          isLoading: true,
          isLoaded: false,
        }

        state.error = null
      })
      .addCase(purchaseActionCreators.payViaVoucher.fulfilled, (state, action) => {
        state.payViaVoucherLoadState = {
          isLoading: false,
          isLoaded: true,
        }
      })
      .addCase(purchaseActionCreators.payViaVoucher.rejected, (state, action) => {
        state.payViaVoucherLoadState = {
          isLoading: false,
          isLoaded: false,
        }
        state.error = action.payload as CustomErrorResponse
      })

      .addCase(authActionCreators.logOut.fulfilled, () => initialState)



  }
})

export const { setCurrentPurchaseError, setSelectedProductToStore, clearPurchaseError } = purchaseSlice.actions

export default purchaseSlice.reducer