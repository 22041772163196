import Icon from '@components/Icon'
import {
  ChangeEvent,
  Dispatch,
  FormEvent,
  KeyboardEvent,
  MutableRefObject,
  SetStateAction,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import Hearts from './Hearts'
import { ChatMessage } from '@customTypes/chat'
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks'
import chatActionCreators from '@actionCreators/chatActionCreators'

type ChatProps = {
  isChatVisible: boolean
  chatMessageList: Array<ChatMessage>
  localLikesCountRef: MutableRefObject<number>
  currentMessageCounter: number
  isFullScreen: boolean
  setChatVisible: Dispatch<SetStateAction<boolean>>
}

const Chat = memo<ChatProps>(
  ({
    isChatVisible,
    chatMessageList,
    localLikesCountRef,
    currentMessageCounter,
    isFullScreen,
    setChatVisible,
  }) => {
    const dispatch = useAppDispatch()

    const [listForFullScreen, setListForFullScreen] = useState<Array<ChatMessage>>([])

    const newChatListRef = useRef<Array<ChatMessage>>([])

    const formRef = useRef<HTMLFormElement>(null)

    const chatId = useAppSelector(state => state.event?.video?.chat.id) || 0

    useEffect(() => {
      if (currentMessageCounter === -1) {
        newChatListRef.current = [...chatMessageList]
      } else {
        const testArr = chatMessageList.filter(message => message.index > currentMessageCounter)

        newChatListRef.current = [...newChatListRef.current, ...testArr]
      }
    }, [chatMessageList, currentMessageCounter])

    const changeListForFullScreen = useCallback(() => {
      const shiftedElemFromCurrentNewChatList = newChatListRef.current.shift()

      const testlistForFullScreen = [...listForFullScreen]

      if (
        testlistForFullScreen.some(item => item.index === shiftedElemFromCurrentNewChatList?.index)
      ) {
        return
      }

      if (listForFullScreen.length >= 4) {
        testlistForFullScreen.shift()
        shiftedElemFromCurrentNewChatList &&
          testlistForFullScreen.push(shiftedElemFromCurrentNewChatList)

        setListForFullScreen(testlistForFullScreen)
      } else {
        if (shiftedElemFromCurrentNewChatList) {
          testlistForFullScreen.push(shiftedElemFromCurrentNewChatList)

          setListForFullScreen(testlistForFullScreen)
        } else {
          testlistForFullScreen.shift()

          setListForFullScreen(testlistForFullScreen)
        }
      }
    }, [listForFullScreen])

    useEffect(() => {
      let interval: NodeJS.Timeout | string | number | undefined
      if (newChatListRef.current.length === 0 && listForFullScreen.length <= 4) {
        interval = setInterval(changeListForFullScreen, 3000)
      } else {
        interval = setInterval(changeListForFullScreen, 1000)
      }

      return () => {
        interval && clearInterval(interval)
      }
    }, [changeListForFullScreen, listForFullScreen.length])

    const [inputValue, setInputValue] = useState('')

    const onChangeInput = useCallback((event: ChangeEvent<HTMLTextAreaElement>) => {
      const value = event.target.value

      setInputValue(value)
    }, [])

    const onTextAreaKeyDown = useCallback((event: KeyboardEvent<HTMLTextAreaElement>) => {
      if (event.keyCode == 13 && event.shiftKey == false) {
        event.preventDefault()

        formRef.current?.requestSubmit()
      }
    }, [])

    const onSubmitForm = useCallback(
      (event: FormEvent) => {
        event.preventDefault()

        const trimmedValue = inputValue.trim()

        if (trimmedValue) {
          const body = {
            message: inputValue,
            chatId,
          }
          dispatch(chatActionCreators.addMessageToChat(body))
          setInputValue('')
        }
      },
      [chatId, dispatch, inputValue]
    )

    const onCloseChat = useCallback(() => {
      setChatVisible(false)
    }, [setChatVisible])

    if (!isChatVisible) {
      return null
    }
    // console.log(listForFullScreen)

    return (
      <div className="ms-chat">
        <div className="ms-chat-header">
          <div className="display-flex align-items-center gap-24">
            <button className="icon-button transparent" onClick={onCloseChat}>
              <Icon name="arrow-right" size={20} />
            </button>
            <div className="display-flex align-items-center gap-4">
              <Icon name="chat" size={20} />
              <div className="h4">Live chat</div>
            </div>
          </div>
        </div>
        <div className="ms-chat-body">
          {isFullScreen
            ? listForFullScreen.length > 0 &&
              listForFullScreen.map((chatMessage, index, array) => (
                <div
                  className={`ms-chat-message${
                    newChatListRef?.current?.length === 0 &&
                    // array.length <= 4 &&
                    array.length > 1 &&
                    index === 0
                      ? ' opacity-out'
                      : ''
                  }`}
                  key={chatMessage.index}
                  id={String(chatMessage.index)}
                >
                  <div className="media">
                    <div className="media-image">
                      {chatMessage.user?.photoUrl ? (
                        <img src={chatMessage.user?.photoUrl} width={40} height={40} alt="" />
                      ) : (
                        <div className="user-icon-wrap">
                          <Icon name="crown" size={34} />
                        </div>
                      )}
                    </div>
                    <div className="media-body">
                      <div className="media-title">{chatMessage.user?.name}</div>
                      <div className="media-message">{chatMessage.message}</div>
                    </div>
                  </div>
                </div>
              ))
            : chatMessageList.length > 0 &&
              chatMessageList.map(chatMessage => (
                <div className="ms-chat-message" key={chatMessage.index}>
                  <div className="media">
                    <div className="media-image">
                      {chatMessage.user?.photoUrl ? (
                        <img src={chatMessage.user?.photoUrl} width={40} height={40} alt="" />
                      ) : (
                        <div className="user-icon-wrap">
                          <Icon name="crown" size={34} />
                        </div>
                      )}
                    </div>
                    <div className="media-body">
                      <div className="media-title">{chatMessage.user?.name}</div>
                      <div className="media-message">{chatMessage.message}</div>
                    </div>
                  </div>
                </div>
              ))}
        </div>
        <div className="ms-chat-footer display-flex gap-16">
          <form className="ms-chat-message-input-control" ref={formRef} onSubmit={onSubmitForm}>
            <textarea
              onChange={onChangeInput}
              onKeyDown={onTextAreaKeyDown}
              className="ms-chat-message-input"
              placeholder="Add a comment"
              value={inputValue}
              maxLength={150}
            ></textarea>
            <button className="icon-button transparent send-button" type="submit">
              <Icon name="send" size={20} />
            </button>
          </form>
          <Hearts localLikesCountRef={localLikesCountRef} />
        </div>
      </div>
    )
  }
)

export default Chat
