import { PayViaInnBucksSectionState } from '@customTypes/purchase'
import { useMemo, useState } from 'react'
import PayViaInnBucksForm from './PayViaInnBucksForm'
import QrSectionForPayingViaInnBucks from './QrSectionForPayingViaInnBucks'
import FinalScreenForPayViaInnBucks from './FinalScreenForPayViaInnBucks'

type PayViaInnBucksProps = {
  isSubscribed: boolean
}

const PayViaInnBucks = ({ isSubscribed }: PayViaInnBucksProps) => {
  const [payViaInnBucksSection, setPayViaInnBucksSection] =
    useState<PayViaInnBucksSectionState>('form')

  const renderView = useMemo(() => {
    switch (payViaInnBucksSection) {
      case 'form':
        return (
          <PayViaInnBucksForm
            isSubscribed={isSubscribed}
            setPayViaInnBucksSection={setPayViaInnBucksSection}
          />
        )
      case 'qr':
        return <QrSectionForPayingViaInnBucks setPayViaInnBucksSection={setPayViaInnBucksSection} />
      case 'final':
        return <FinalScreenForPayViaInnBucks setPayViaInnBucksSection={setPayViaInnBucksSection} />
    }
  }, [isSubscribed, payViaInnBucksSection])

  return <>{renderView}</>
}

export default PayViaInnBucks
