import authActionCreators from '@actionCreators/authActionCreators';
import Icon from '@components/Icon';
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks';
import React, { useCallback } from 'react';
import logo from '@images/logo-light.svg'
import logoDark from '@images/logo-dark.png'
import logoMobile from '@images/logo-mobile.svg'
import { Link } from 'react-router-dom';

const HeaderV2 = () => {
  const dispatch = useAppDispatch()
  const user = useAppSelector(state => state.user.user)

  const logOut = useCallback(async () => {
    dispatch(authActionCreators.logOut())
  }, [dispatch])
  return (
    <header className="centered-page-header">
      <Link className="centered-page-logo" to={`/`}>
        <picture>
          <source srcSet={logo} media="(min-width: 592px)" />
          <img src={logoDark} alt="logo" />
        </picture>
        {/* <img src={logo} alt="" /> */}
      </Link>
      <div className="ml-auto">
        {user ? (
          <button onClick={logOut} className="icon-button transparent auto-size">
            <Icon name={'logout'} size={24} />
          </button>
        ) : (
          <Link to={'/login'} className="icon-button transparent auto-size">
            <Icon name={'login'} size={24} />
          </Link>
        )}
      </div>
    </header>
  )
};

export default HeaderV2;