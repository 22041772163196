import { PayViaVoucherSectionState } from '@customTypes/purchase'
import { useMemo, useState } from 'react'
import PayViaVoucherForm from './PayViaVoucherForm'
import FinalScreenForPayViaVoucherForm from './FinalScreenForPayViaVoucherForm'

type PayViaVoucherProps = {
  isSubscribed: boolean
}

const PayViaVoucher = ({ isSubscribed }: PayViaVoucherProps) => {
  const [payVoucherSection, setPayViaVoucherSection] = useState<PayViaVoucherSectionState>('form')

  const renderView = useMemo(() => {
    switch (payVoucherSection) {
      case 'form':
        return (
          <PayViaVoucherForm
            isSubscribed={isSubscribed}
            setPayViaVoucherSection={setPayViaVoucherSection}
          />
        )
      case 'final':
        return (
          <FinalScreenForPayViaVoucherForm
          />
        )
    }
  }, [isSubscribed, payVoucherSection])

  return <>{renderView}</>
}

export default PayViaVoucher
