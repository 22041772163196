import { ShortEvent } from '@customTypes/event'
import Carousel from './carousel/Carousel'

type EventMainListProps = {
  eventList: Array<ShortEvent>
}

const EventMainList = ({ eventList }: EventMainListProps) => {
  return <Carousel autoPlay={true} autoPlayTime={4000} dataList={eventList} />
}

export default EventMainList
