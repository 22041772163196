import { authorizedRequest } from "@api/authorizeRequest"
import { Product } from "@customTypes/event"
import {
  CreateOrderRequestBody,
  EOrderPaymentStatus,
  PayViaCardRequestBody,
  PayViaCardResponseBody,
  PayViaInnBucksRequestBody,
  PayViaInnBucksResponseBody,
  PayViaMobileMoneyRequestBody,
  PayViaVoucherBodyRequest
} from "@customTypes/purchase"

const getOrdersList = () => {
  const path = ["orders"]

  return authorizedRequest<null, any>('v1', path)
}

const createOrder = (body: CreateOrderRequestBody) => {
  const path = ["orders"]

  return authorizedRequest<CreateOrderRequestBody, any>('v1', path, 'POST', body)
}


const buyViaAgent = (body: CreateOrderRequestBody) => {
  const path = ['agent', "orders"]

  return authorizedRequest<PayViaCardRequestBody, any>('v1', path, 'POST', body)
}


const payViaCard = (body: PayViaCardRequestBody) => {
  const path = ["orders"]

  return authorizedRequest<PayViaCardRequestBody, PayViaCardResponseBody>('v1', path, "POST", body)
}

const payViaMobileMoney = (body: PayViaMobileMoneyRequestBody) => {
  const path = ["orders"]

  return authorizedRequest<PayViaMobileMoneyRequestBody, string>('v1', path, "POST", body)
}

const payViaInnBucks = (body: PayViaInnBucksRequestBody) => {
  const path = ["orders"]

  return authorizedRequest<PayViaInnBucksRequestBody, PayViaInnBucksResponseBody>('v1', path, "POST", body)
}

const getProductById = (id: string) => {
  const path = ["products", id]

  return authorizedRequest<null, Product>('v1', path)
}

const checkPaymentViaMobileMoney = () => {
  const path = ['paynow', 'check-last']

  return authorizedRequest<PayViaMobileMoneyRequestBody, { status: EOrderPaymentStatus }>('v1', path)
}

const payViaVoucher = (body: PayViaVoucherBodyRequest) => {
  const path = ["orders"]

  return authorizedRequest<PayViaVoucherBodyRequest, null>('v1', path, 'POST', body)

}

const purchaseServices = {
  getOrdersList,
  createOrder,
  getProductById,
  payViaCard,
  payViaMobileMoney,
  checkPaymentViaMobileMoney,
  buyViaAgent,
  payViaInnBucks,
  payViaVoucher,
}

export default purchaseServices