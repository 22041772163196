import authActionCreators from "@actionCreators/authActionCreators"
import eventActionCreators from "@actionCreators/eventActionCreators"
import purchaseActionCreators from "@actionCreators/purchaseActionCreators"
import { CustomErrorResponse, LoadState } from "@customTypes/common"

import { Event, EventPageSection, Product, ShortEvent, Video } from "@customTypes/event"
import { createSlice } from "@reduxjs/toolkit"

type EventSlice = {
  error: CustomErrorResponse | null
  eventPageSection: EventPageSection

  eventList: Array<ShortEvent>
  getEventListLoadState: LoadState

  currentEvent: Event | null
  getEventByIdLoadState: LoadState






  initializedLikesForVideo: number
  displayedLikesQuantity: number

  video: Video | null
  selectedProduct: Product | null
}

const initialState: EventSlice = {
  error: null,
  eventPageSection: "main",

  eventList: [],
  getEventListLoadState: {
    isLoading: false,
    isLoaded: false
  },

  currentEvent: null,
  getEventByIdLoadState: {
    isLoading: false,
    isLoaded: false
  },

  initializedLikesForVideo: 0,
  displayedLikesQuantity: 0,


  selectedProduct: null,
  video: null,
}


export const eventSlice = createSlice({
  name: 'event',
  initialState,
  reducers: {
    setEventPageSection: (state, action) => {
      state.eventPageSection = action.payload
    },
    clearCurrentEvent: state => {
      state.getEventByIdLoadState = {
        isLoaded: false,
        isLoading: false
      }
      state.currentEvent = null
    },
    // setIsPurchaseForCurrentEvent: (state) => {
    //   const modifiedProducts = state.event?.products.map(product => {
    //     if (product.id === state.selectedProduct?.id) {
    //       return { ...product, ...{ isPurchased: true } }
    //     } else {
    //       return product
    //     }
    //   })

    //   state.event = state.event && modifiedProducts ? { ...state.event, ...{ products: [...modifiedProducts] } } : null
    // },

    setSelectedProduct: (state, action) => {
      state.selectedProduct = state.currentEvent?.products
        .find(product => product.id === action.payload) || null
      // console.log(state.event?.products
      //   .find(product => product.id === action.payload) || null)
    },
    clearSelectedProduct: state => {
      state.selectedProduct = null
    },
    clearDisplayedLikesQuantity: (state) => {
      state.displayedLikesQuantity = 0
    },
    clearEventError: state => {
      state.error = null
    }

  },
  extraReducers: builder => {
    builder
      .addCase(eventActionCreators.getEventList.pending, state => {
        state.getEventListLoadState = {
          isLoading: true,
          isLoaded: false,
        }
      })
      .addCase(eventActionCreators.getEventList.fulfilled, (state, action) => {
        state.getEventListLoadState = {
          isLoading: false,
          isLoaded: true,
        }
        state.eventList = action.payload.data
      })
      .addCase(eventActionCreators.getEventList.rejected, state => {
        state.getEventListLoadState = {
          isLoading: false,
          isLoaded: false,
        }
      })
      .addCase(eventActionCreators.getEventById.pending, (state) => {
        state.getEventByIdLoadState = {
          isLoading: true,
          isLoaded: false,
        }
      })
      .addCase(eventActionCreators.getEventById.fulfilled, (state, action) => {
        // state.event = omit(action.payload, ['video.url']) as Event
        state.currentEvent = action.payload
        state.getEventByIdLoadState = {
          isLoading: false,
          isLoaded: true,
        }
      })
      .addCase(eventActionCreators.getEventById.rejected, state => {
        state.getEventByIdLoadState = {
          isLoading: false,
          isLoaded: false,
        }
      })
      .addCase(purchaseActionCreators.getProductById.fulfilled, (state, action) => {

        state.currentEvent = action.payload.event
      })



      .addCase(eventActionCreators.getLikesByVideoId.fulfilled, (state, action) => {
        state.displayedLikesQuantity = state.initializedLikesForVideo ? action.payload - state.initializedLikesForVideo : state.initializedLikesForVideo
        state.initializedLikesForVideo = action.payload
      })
      .addCase(eventActionCreators.buyViaVoucher.pending, state => {
        state.error = null
      })
      // .addCase(eventActionCreators.buyViaVoucher.fulfilled, state => {
      //   state.event = { ...state.event, video: { ...state.event?.video, isPurchased: true } } as Event
      // })
      .addCase(eventActionCreators.buyViaVoucher.rejected, (state, action) => {
        state.error = action.payload as CustomErrorResponse
      })
    .addCase(eventActionCreators.getVideoById.fulfilled, (state, action) => {
      state.video = action.payload
    })

    //To clear slice state
    // .addCase(authActionCreators.logOut.fulfilled, () => initialState)
  }
})


export const {
  clearSelectedProduct,
  // setIsPurchaseForCurrentEvent,
  // setSelectedProduct,
  setEventPageSection,
  clearDisplayedLikesQuantity,
  clearCurrentEvent,
  clearEventError
} = eventSlice.actions

export default eventSlice.reducer