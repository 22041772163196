import purchaseActionCreators from '@actionCreators/purchaseActionCreators'
import Loader from '@components/ui/Loader'
import VoucherFormTextField from '@components/ui/VoucherTextField'
import { UsingVoucherFormType } from '@customTypes/event'
import { EPaymentMethod, PayViaVoucherSectionState } from '@customTypes/purchase'
import { yupResolver } from '@hookform/resolvers/yup'
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks'
import { clearPurchaseError } from '@sliceActionCreators/purchaseSlice'
import isEmpty from 'lodash/isEmpty'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

const voucherFormScheme = yup.object({
  code: yup
    .string()
    .typeError('Voucher is invalid')
    .min(11, 'Voucher is invalid')
    .required('Voucher is invalid'),
})

type PayViaVoucherFormProps = {
  setPayViaVoucherSection: Dispatch<SetStateAction<PayViaVoucherSectionState>>
  isSubscribed: boolean
}

const PayViaVoucherForm = ({ isSubscribed, setPayViaVoucherSection }: PayViaVoucherFormProps) => {
  const dispatch = useAppDispatch()

  const productId = useAppSelector(state => state.purchase.selectedProduct?.id) || 0
  const { payViaVoucherLoadState, error } = useAppSelector(state => state.purchase)

  const [isDisabledButton, setIsDisabledButton] = useState(false)

  const { handleSubmit, control, formState, setError } = useForm<UsingVoucherFormType>({
    resolver: yupResolver(voucherFormScheme),
  })

  useEffect(() => {
    if (error?.errorCode === '404054550') {
      setError('code', { message: 'Voucher is invalid' })
    }

    return () => {
      dispatch(clearPurchaseError())
    }
  }, [dispatch, error, setError])

  useEffect(() => {
    if (isEmpty(formState.errors)) {
      setIsDisabledButton(false)
    } else {
      setIsDisabledButton(true)
    }
  }, [formState])

  const onSubmit = (dataForm: UsingVoucherFormType) => {
    const body = {
      paymentProviderId: EPaymentMethod.Voucher,
      products: [{ productId, qty: 1 }],
      paymentProviderOptions: {
        voucherCode: dataForm.code,
      },
      // isSubscribed,
    }

    dispatch(purchaseActionCreators.payViaVoucher(body))
      .unwrap()
      .then(() => setPayViaVoucherSection('final'))
      .catch(() => {})
  }

  return (
    <>
      {payViaVoucherLoadState.isLoading && <Loader className="spinner-fixed" />}

      <form onSubmit={handleSubmit(onSubmit)} className="order-balance">
        <div className="display-flex align-items-center gap-8">
          Use voucher:
        </div>
        <VoucherFormTextField
          name="code"
          control={control}
          placeholder="Enter voucher number"
          type="text"
        />
        <button className="button primary large" disabled={isDisabledButton} type="submit">
          Buy with voucher
        </button>
      </form>
    </>
  )
}

export default PayViaVoucherForm
