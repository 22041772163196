import { authorizedRequest } from "@api/authorizeRequest"
import { AddLikesByVideoIdRequestBody, BuyViaVoucherBodyRequest, EEventType, Event, EventListResponse, Video } from "@customTypes/event"
import { auth } from "@fire-base/mainFirebase"
import authServices from "./authServices"
import localStorageData from "@utils/localStorage"
import { anonymousRequest } from "@api/anonymousRequest"

const getEventList = (type: EEventType, searchValue?: string) => {
  const path = ['events']

  const query = {
    type,
    ...(searchValue && { search: searchValue })
  }

  return authorizedRequest<null, EventListResponse>('v1', path, 'GET', null, query)
}

const getAuthEventById = (eventId: number | string) => {
  const path = ['events', String(eventId)]

  return authorizedRequest<null, Event>('v1', path)
}

const getNonAuthEventById = (eventId: number | string) => {
  const path = ['events', String(eventId)]

  return anonymousRequest<null, Event>(path)
}

const getLikesByVideoId = (videoId: number) => {
  const path = ['videos', String(videoId), 'likes',]

  return authorizedRequest<null, number>('v1', path)
}

const addLikesByVideoId = (videoId: number, body: AddLikesByVideoIdRequestBody) => {
  const path = ['videos', String(videoId), 'likes',]

  return authorizedRequest<AddLikesByVideoIdRequestBody, null>('v1', path, 'POST', body)
}

// const buyViaVoucher = (body: BuyViaVoucherBodyRequest) => {
//   const path = ['voucher', "buy"]

//   return authorizedRequest<BuyViaVoucherBodyRequest, null>('v1', path, 'POST', body)

// }

const getVideoById = (videoId: number | string) => {
  const path = ['videos', String(videoId)]

  return authorizedRequest<null, Video>('v1', path)
}

const getVideoByIdWithAuthorize = async (videoId: number | string) => {
  const path = ['videos', String(videoId)]
  const currentUser = auth.currentUser
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

  if (currentUser) {
    const accessToken = await currentUser.getIdToken(true)

    const body = {
      providerId: currentUser.providerData[0].providerId,
      accessToken,
      timezone: userTimeZone,
    }
    localStorageData.setData("authData", accessToken)

    await authServices.authorizeUser(body)



    return authorizedRequest<null, Video>('v1', path)
  }



}

const eventServices = {
  getEventList,
  getAuthEventById,
  getNonAuthEventById,

  getLikesByVideoId,
  addLikesByVideoId,
  // buyViaVoucher,
  getVideoById,
  getVideoByIdWithAuthorize,
}

export default eventServices