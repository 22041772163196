import purchaseServices from "@api/services/purchaseServices"
import { CustomErrorResponse } from "@customTypes/common"
import {
  CreateOrderRequestBody,
  EOrderPaymentStatus,
  PayViaCardRequestBody,
  PayViaInnBucksRequestBody,
  PayViaMobileMoneyRequestBody,
  PayViaVoucherBodyRequest
} from "@customTypes/purchase"
import { createAsyncThunk } from "@reduxjs/toolkit"
import { Slide, toast } from "react-toastify"
import eventServices from "@api/services/eventServices"


const getOrdersList = createAsyncThunk(
  'purchase/getOrdersList', async (_, { rejectWithValue, dispatch }) => {
    try {
      const res = await purchaseServices.getOrdersList()

      return res
    } catch (e) {
      const err = e as CustomErrorResponse

      return rejectWithValue('error')
    }
  }
)

const payViaCard = createAsyncThunk(
  'purchase/payViaCard', async (body: PayViaCardRequestBody, { rejectWithValue, dispatch }) => {
    try {
      const res = await purchaseServices.payViaCard(body)

      return res
    } catch (e) {
      const err = e as CustomErrorResponse

      toast.error('Something went wrong, try again.',
        {
          position: 'top-right',
          transition: Slide,
          pauseOnFocusLoss: false,
          autoClose: 3000,
        })

      return rejectWithValue('error')
    }
  }
)

const payViaMobileMoney = createAsyncThunk(
  'purchase/payViaMobileMoney', async (body: PayViaMobileMoneyRequestBody, { rejectWithValue, dispatch }) => {
    try {
      const res = await purchaseServices.payViaMobileMoney(body)

      return res
    } catch (e) {
      const err = e as CustomErrorResponse

      toast.error('Something went wrong, try again.',
        {
          position: 'top-right',
          transition: Slide,
          pauseOnFocusLoss: false,
          autoClose: 3000,
        })

      return rejectWithValue('error')
    }
  }
)

const payViaInnBucks = createAsyncThunk(
  'purchase/payViaInnBucks', async (body: PayViaInnBucksRequestBody, { rejectWithValue, dispatch }) => {
    try {
      const res = await purchaseServices.payViaInnBucks(body)

      return res
    } catch (e) {
      const err = e as CustomErrorResponse

      toast.error('Something went wrong, try again.',
        {
          position: 'top-right',
          transition: Slide,
          pauseOnFocusLoss: false,
          autoClose: 3000,
        })

      return rejectWithValue('error')
    }
  }
)

const payViaVoucher = createAsyncThunk(
  'purchase/payViaVoucher', async (body: PayViaVoucherBodyRequest, { rejectWithValue, dispatch }) => {
    try {
      const res = await purchaseServices.payViaVoucher(body)

      return res
    } catch (e) {
      const err = e as CustomErrorResponse

      if (err.errorCode !== '404054550') {
        toast.error('Something went wrong, try again.',
          {
            position: 'top-right',
            transition: Slide,
            pauseOnFocusLoss: false,
            autoClose: 3000,
          })
      }

      return rejectWithValue(err)
    }
  }
)

const checkPaymentViaMobileMoney = createAsyncThunk(
  'purchase/checkPaymentViaMobileMoney', async (_, { rejectWithValue }) => {

    try {
      const res = await purchaseServices.checkPaymentViaMobileMoney()

      if (res.status === EOrderPaymentStatus.Pending) {
        toast.info('Transaction in progress.',
          {
            position: 'top-right',
            transition: Slide,
            pauseOnFocusLoss: false,
            autoClose: 3000,
          })
      }

      return res
    } catch (e) {
      toast.error('Something went wrong, try again.',
        {
          position: 'top-right',
          transition: Slide,
          pauseOnFocusLoss: false,
          autoClose: 3000,
        })

      return rejectWithValue('error')
    }

  }
)

const getProductById = createAsyncThunk(
  'purchase/getProductById', async (id: string, { rejectWithValue, dispatch }) => {
    try {
      const product = await purchaseServices.getProductById(id)

      const event = await eventServices.getAuthEventById(product.eventId)

      return { product, event }
    } catch (e) {
      const err = e as CustomErrorResponse

      return rejectWithValue('error')
    }
  }
)

// const createOrder = createAsyncThunk(
//   'purchase/createOrder', async (body: CreateOrderRequestBody, { rejectWithValue, dispatch, getState }) => {
//     try {
//       const res = await purchaseServices.createOrder(body)

//       const state = getState() as RootState
//       const currentEventId = state.event.event?.id || 0

//       await dispatch(eventActionCreators.getEventById(currentEventId))

//       return res
//     } catch (e) {
//       const err = e as CustomErrorResponse

//       return rejectWithValue('error')
//     }
//   }
// )

const buyViaAgent = createAsyncThunk(
  'purchase/buyViaAgent', async (body: CreateOrderRequestBody, { rejectWithValue, fulfillWithValue }) => {
    try {
      await purchaseServices.buyViaAgent(body)

      toast.success('Order has been created',
        {
          closeOnClick: false,
          position: 'top-right',
          transition: Slide,
          pauseOnFocusLoss: false,
          autoClose: 3000,
        })

      return fulfillWithValue('success')
    } catch (e) {
      const err = e as CustomErrorResponse

      toast.error(`${err.errorCode} ${err.message}` || 'Something went wrong!',
        {
          position: 'top-right',
          transition: Slide,
          pauseOnFocusLoss: false,
          autoClose: 3000,
        })

      return rejectWithValue('error')
    }
  }
)


const purchaseActionCreators = {
  getOrdersList,
  getProductById,

  payViaMobileMoney,
  payViaCard,
  checkPaymentViaMobileMoney,
  // createOrder,
  buyViaAgent,
  payViaInnBucks,
  payViaVoucher,
}

export default purchaseActionCreators