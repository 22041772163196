import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import { useAppSelector } from '@hooks/reduxHooks'
import { useMemo, useState } from 'react'
import CheckoutForm from './CheckoutForm'
import FinalScreenForPayViaCard from './FinalScreenForPayViaCard'

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUB_KEY)

const CardPaymentWrapper = () => {
  const clientSecret = useAppSelector(state => state.purchase.currentClientSecret)
  const [payViaCardSection, setPayViaCardSection] = useState<'form' | 'final'>('form')

  const options = useMemo(
    () => ({
      clientSecret: clientSecret,
    }),
    [clientSecret]
  )

  const renderView = useMemo(() => {
    switch (payViaCardSection) {
      case 'form':
        return (
          <>
            {clientSecret && stripePromise && (
              <Elements stripe={stripePromise} options={options}>
                <CheckoutForm setPayViaCardSection={setPayViaCardSection} />
              </Elements>
            )}
          </>
        )
      case 'final':
        return <FinalScreenForPayViaCard setPayViaCardSection={setPayViaCardSection} />
    }
  }, [clientSecret, options, payViaCardSection])

  return <>{renderView}</>
}

export default CardPaymentWrapper
