import { CommonIcons } from '@helpers/commonIcons'
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks'
import { StripeError } from '@stripe/stripe-js'
import { Dispatch, SetStateAction, useCallback, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

type FinalScreenProps = {
  onClick: () => void
  error?: StripeError
}

const SuccessFinalScreen = ({ onClick }: FinalScreenProps) => {
  return (
    <>
      <div className="display-flex align-items-center gap-8 justify-content-center flex-direction-column">
        <CommonIcons.Success />
        <p style={{ fontSize: '20px' }}>Payment was successful</p>
      </div>
      <button
        className="button primary large"
        onClick={onClick}
      >
        Back to event
      </button>
    </>
  )
}

const FailedFinalScreen = ({ onClick, error }: FinalScreenProps) => {
  return (
    <>
      <div className="display-flex align-items-center gap-8 justify-content-center flex-direction-column">
        <CommonIcons.Failure />
        <p style={{ fontSize: '20px', textAlign: 'center' }}>{error?.message}</p>
      </div>
      <button
        className="button primary large"
        onClick={onClick}
      >
        Try again
      </button>
    </>
  )
}

type FinalScreenForPayViaCardProps = {
  setPayViaCardSection: Dispatch<SetStateAction<'form' | 'final'>>
}

const FinalScreenForPayViaCard = ({ setPayViaCardSection }: FinalScreenForPayViaCardProps) => {
  const navigate = useNavigate()
  const { id } = useParams()

  const error = useAppSelector(state => state.purchase.currentPurchaseError)

  const onClickBtn = useCallback(() => {
    if (error) {
      setPayViaCardSection('form')
      return
    }

    navigate(`/events/${id}`)

  }, [error, id, navigate, setPayViaCardSection])

  const renderView = useMemo(() => {
    if (error) {
      return <FailedFinalScreen onClick={onClickBtn} error={error} />
    }

    return <SuccessFinalScreen onClick={onClickBtn} />
  }, [error, onClickBtn])
  return <div className="order-balance">{renderView}</div>
}

export default FinalScreenForPayViaCard
