import Icon from '@components/Icon'
import { memo, useCallback, useState } from 'react'

type CopyButtonProps = {
  text: string
}

const CopyButton = memo(({ text }: CopyButtonProps) => {
  const [isShownText, setIsShownText] = useState(false)

  const onCopyClick = useCallback(() => {
    setIsShownText(true)

    navigator.clipboard.writeText(text)

    setTimeout(() => {
      setIsShownText(false)
    }, 1000)
  }, [text])
  
  return (
    <div className="icon-button copy-button share-icon" onClick={onCopyClick}>
      <Icon name="copy" size={47} />
      {isShownText && <span className="copy-button-text">Copied</span>}
    </div>
  )
})

export default CopyButton
