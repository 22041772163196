import ShareSocialMediaList from '@components/event/shareSection/ShareSocialMediaList'
import { useCallback, useState } from 'react'
import { Slide, toast } from 'react-toastify'
import SocialMediaSection from './SocialMediaSection'

type EndScreenProps = {
  eventId: string | number
}

const EndScreen = ({ eventId }: EndScreenProps) => {
  const [isShownShare, setIsShownShare] = useState(false)

  const onCloseShare = useCallback(() => {
    setIsShownShare(false)
  }, [])

  const showToastMessage = useCallback(() => {
    if (!isShownShare) {
      {
        toast(<ShareSocialMediaList linkPart={`${eventId}`} />, {
          position: 'bottom-center',
          // className: 'share-toastify',
          draggable: true,
          transition: Slide,
          autoClose: false,
          closeOnClick: false,
          draggableDirection: 'y',
          draggablePercent: 40,
          onClose: onCloseShare,
        })
        setIsShownShare(true)
      }
    }
  }, [eventId, isShownShare, onCloseShare])

  return (
    <div className="span-all-areas">
      <div className="centered-content">
        <h2 className="event-title text-uppercase shining-text text-center mb-64">
          Stay tuned for more exclusives
        </h2>

        <button
          className="button large primary mb-80 w-100"
          style={{ maxWidth: '320px' }}
          onClick={showToastMessage}
        >
          Share
        </button>
        <div className="event-message-wrapper mb-16">
          <div className="event-message h2 text-center text-uppercase">Follow Mastreetz</div>
        </div>
        <SocialMediaSection />

        {/* <div className="event-message-wrapper mb-24 mt-48">
          <Link
            className="event-message simple text-center text-uppercase button-link"
            to="/agents"
          >
            Wanna become an agent?
          </Link>
        </div> */}
      </div>
    </div>
  )
}

export default EndScreen
