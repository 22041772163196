import { useAppSelector } from '@hooks/reduxHooks'
import localStorageData from '@utils/localStorage'
import isEqual from 'lodash/isEqual'
import { Navigate, Outlet } from 'react-router'

const ProtectedRoute = () => {
  const token = localStorageData.getData('authData')
  const user = useAppSelector(state => state.user.user, isEqual)


  if(!token) {
    return <Navigate to="/" />
  }
  if(token && user) {
    return <Outlet />
  }

  return null

}

export default ProtectedRoute
