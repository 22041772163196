import agentActionCreators from '@actionCreators/agentActionCreators'
import Icon from '@components/Icon'
import AgentEventTable from '@components/agent/AgentEventTable'
import AgentOrdersTable from '@components/agent/AgentOrdersTable'
import Loader from '@components/ui/Loader'
import { ShortEvent } from '@customTypes/event'
import { Order } from '@customTypes/purchase'
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks'
import agentUtils from '@utils/agent'
import isEqual from 'lodash/isEqual'
import React, { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import debounce from 'lodash/debounce'

type EventListTabs = 'events' | 'orders'

const AgentEventListPage = () => {
  const dispatch = useAppDispatch()
  const eventList = useAppSelector(state => state.agent.eventList, isEqual)
  const orderList = useAppSelector(state => state.agent.orderList)

  // useEffect(() => {
  //   dispatch(agentActionCreators.getEventListForAgent())
  // }, [dispatch])
  const [queryParams, setQueryParams] = useSearchParams()
  const [searchValue, setSearchValue] = useState('')
  const [isShownSearchInput, setIsShownSearchInput] = useState(false)

  const [eventListState, setEventListState] = useState<Array<ShortEvent>>([])
  const [orderListState, setOrderListState] = useState<Array<Order>>([])

  const [tabValue, setTabValue] = useState<EventListTabs>('events')

  const searchInputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (tabValue === 'orders') {
      setOrderListState(orderList)
      return
    }

    setEventListState(eventList)
  }, [eventList, orderList, tabValue])

  const onClickAway = useCallback((event: any) => {
    if (
      !(
        searchInputRef.current?.contains(event.target) ||
        event.target.closest('.clear-input') ||
        event.target.closest('.close-input')
      )
    ) {
      setIsShownSearchInput(false)
    }
  }, [])

  useEffect(() => {
    // if (isShown && isMobile) {
    document.addEventListener('click', onClickAway, { capture: true })

    return () => {
      document.removeEventListener('click', onClickAway, { capture: true })
    }
    // }
  }, [onClickAway])

  useEffect(() => {
    if (queryParams.get('activeTab') === 'orders') {
      setTabValue('orders')
      dispatch(agentActionCreators.getOrderListForAgent())

      return
    }

    setTabValue('events')
    setQueryParams({ activeTab: 'events' })
    dispatch(agentActionCreators.getEventListForAgent())
  }, [dispatch, queryParams, setQueryParams])

  const onTabClick = useCallback(
    (tabValue: string) => {
      setQueryParams({ activeTab: tabValue })
      setSearchValue('')
    },
    [setQueryParams]
  )

  const filterList = useCallback(
    (searchValue: string) => {
      if (tabValue === 'events') {
        const list = agentUtils.getListBySearchValue(eventList, searchValue, [
          'title',
          'artist',
          'shortDescription',
          'description',
        ])

        setEventListState(list)
      } else {
        const list = agentUtils.getListBySearchValue(orderList, searchValue, [
          'id',
          'eventTitle',
          'totalAmount',
        ])

        setOrderListState(list)
      }
    },
    [eventList, orderList, tabValue]
  )

  const debounceFunction = useMemo(() => debounce(filterList, 500), [filterList])

  const onSearchBtnClick = useCallback(() => {
    setIsShownSearchInput(true)
    searchInputRef?.current?.focus()
  }, [])
  const onCloseBtnClick = useCallback(() => {
    setIsShownSearchInput(false)
  }, [])

  const onClearSearchInput = useCallback(() => {
    setSearchValue('')
    // filterList('')
    debounceFunction('')
  }, [debounceFunction])

  const onSearchInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value

      setSearchValue(value)
      // filterList(value)
      debounceFunction(value)
    },
    [debounceFunction]
  )
  return (
    <>
      {/* {<Loader className="spinner-fixed" />} */}
      <div className="ms-filters">
        <nav className="ms-tabs">
          <button
            className={`ms-tab ${tabValue === 'events' ? 'is-active' : ''}`}
            onClick={() => onTabClick('music')}
          >
            Events
          </button>
          <button
            className={`ms-tab ${tabValue === 'orders' ? 'is-active' : ''}`}
            onClick={() => onTabClick('orders')}
          >
            Orders
          </button>
        </nav>

        <div className="search-box ml-auto">
          <input
            ref={searchInputRef}
            className={isShownSearchInput ? 'show-input' : ''}
            type="text"
            placeholder="Find events, artists and more"
            value={searchValue}
            onChange={onSearchInputChange}
          />
          {isShownSearchInput ? (
            <>
              {searchValue && (
                <button
                  className="icon-button transparent clear-input"
                  onClick={onClearSearchInput}
                >
                  <Icon name="delete" size={32} />
                </button>
              )}
              <button className="icon-button transparent close-input" onClick={onCloseBtnClick}>
                <Icon name="arrow-right-to-line" size={32} />
              </button>
            </>
          ) : (
            <button className="icon-button transparent" onClick={onSearchBtnClick}>
              <Icon name="search" size={32} />
            </button>
          )}
        </div>
      </div>
      {tabValue === 'events' && <AgentEventTable list={eventListState} />}
      {tabValue === 'orders' && <AgentOrdersTable list={orderListState} />}
    </>
  )
}

export default AgentEventListPage
