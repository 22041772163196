import Icon from '@components/Icon'
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks'
import { clearDisplayedLikesQuantity } from '@sliceActionCreators/eventSlice'
import {
  MutableRefObject,
  Ref,
  RefObject,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'

type HeartsConfigState = {
  id: number
  variant: number
}

type HeartsProps = {
  localLikesCountRef: MutableRefObject<number>
}

const Hearts = memo(({ localLikesCountRef }: HeartsProps) => {
  const displayedLikesQuantity = useAppSelector(state => state.event.displayedLikesQuantity)
  const dispatch = useAppDispatch()
  const [hearts, setHearts] = useState<Array<HeartsConfigState>>([])

  const countRef = useRef(0)
  const intervalForExternalLikesRef = useRef<NodeJS.Timeout>()
  const localDisplayedLikesQuantity = useRef(0)

  const timersRef = useRef<Array<NodeJS.Timeout>>([])

  function randomInteger(min: number, max: number) {
    const randomNumber = min + Math.random() * (max + 1 - min)
    return Math.floor(randomNumber)
  }

  const addElementToHeartsState = useCallback(() => {
    setHearts(prevState => {
      const id = countRef.current++
      const randomVariant = randomInteger(-25, 8)
      const newTimers = [...prevState, { id, variant: randomVariant }]

      return newTimers
    })
  }, [])

  const removeElementFromHeartsState = useCallback(
    (timer: NodeJS.Timeout) =>
      setHearts(prevState => {
        const newState = [...prevState]
        newState.shift()
        timersRef.current = timersRef.current.filter(timersItem => timersItem !== timer)

        return newState
      }),
    []
  )

  const onClickHeart = useCallback(
    (event?: any) => {
      // console.log(event)
      addElementToHeartsState()
      // event && setLocalLikesCount(prevState => prevState + 1)

      event && ++localLikesCountRef.current

      const timer = setTimeout(() => {
        removeElementFromHeartsState(timer)
      }, 3000)

      timersRef.current.push(timer)
    },
    [addElementToHeartsState, localLikesCountRef, removeElementFromHeartsState]
  )

  const addLikesFromServer = useCallback(() => {
    if (localDisplayedLikesQuantity.current > 0) {
      onClickHeart()
      localDisplayedLikesQuantity.current = localDisplayedLikesQuantity.current - 1
    } else {
      clearInterval(intervalForExternalLikesRef.current)
      dispatch(clearDisplayedLikesQuantity())
    }
  }, [dispatch, onClickHeart])

  useEffect(() => {
    if (displayedLikesQuantity) {
      localDisplayedLikesQuantity.current = displayedLikesQuantity

      intervalForExternalLikesRef.current = setInterval(addLikesFromServer, 100)
    }
  }, [addLikesFromServer, displayedLikesQuantity])

  // console.log(hearts)
  useEffect(() => {
    return () => {
      timersRef.current.forEach(clearTimeout)
      countRef.current = 0
    }
  }, [])

  return (
    <div className="hearts-wrapper">
      <div className="hearts-container">
        {hearts.map(item => (
          <div className={`heart`} key={item.id}>
            <Icon name="heart" size={20} style={{ left: `${item.variant}px` }} />
          </div>
        ))}
      </div>

      <button onClick={onClickHeart} className="icon-button dark oval outline">
        <Icon name="heart" size={20} />
      </button>
    </div>
  )
})

export default Hearts
