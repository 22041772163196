import chatServices from "@api/services/chatServices"
import { AddMessageToChatRequestBody } from "@customTypes/chat"
import { CustomErrorResponse } from "@customTypes/common"
import { createAsyncThunk } from "@reduxjs/toolkit"

const getChatListByEventId = createAsyncThunk(
  'chat/getChatListByEventId', async (eventId: number, { rejectWithValue, dispatch }) => {

    try {
      const res = await chatServices.getChatListByEventId(eventId)


      return res.messages
    } catch (e) {
      const err = e as CustomErrorResponse

      return rejectWithValue('error')
    }
  }
)

const addMessageToChat = createAsyncThunk(
  'chat/addMessageToChat', async (body: AddMessageToChatRequestBody, { rejectWithValue, dispatch }) => {

    try {
      const res = await chatServices.addMessageToChat(body)
      return res
    } catch (e) {
      const err = e as CustomErrorResponse

      return rejectWithValue('error')
    }
  }
)

const chatActionCreators = {
  getChatListByEventId,
  addMessageToChat
}

export default chatActionCreators