import { Action, UnknownAction, configureStore } from '@reduxjs/toolkit'

import authSlice from './slices/authSlice'
import userSlice from './slices/userSlice'
import eventSlice from './slices/eventSlice'
import purchaseSlice from './slices/purchaseSlice'
import chatSlice from './slices/chatSlice'
import agentSlice from '@sliceActionCreators/agentSlice'

const rootReducer = {
  auth: authSlice,
  user: userSlice,
  event: eventSlice,
  purchase: purchaseSlice,
  chat: chatSlice,
  agent: agentSlice
}

// const reset = (state: ReturnType<typeof rootReducer>, action: UnknownAction) => {
// 	if (action.type === 'user/reset') {
// 		return rootReducer(undefined, action)
// 	}

// 	return rootReducer(state, action)
// }

export const store = configureStore({
  reducer: rootReducer,
})

export type RootState = ReturnType<typeof store.getState>
// export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = typeof store.dispatch
