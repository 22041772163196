export const randomInteger = (min: number, max: number) => {
  const randomNumber = min + Math.random() * (max + 1 - min)
  return Math.floor(randomNumber)
}

export const trimObjectFields = (object: Record<string, string>) => {
  return Object.keys(object).reduce((acc, key) => {
    acc[key] = typeof object[key] == 'string' ? object[key].trim() : object[key]

    return acc
  }, {} as Record<string, string>)
}

export const parseJwt = (token: string) => {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
  }).join(''))

  return JSON.parse(jsonPayload)
}

export const getPhoneNumberMaskForPayViaMobileMoney = () => {
  const mask = [/[0-9+]/]
  mask.length = 14

  return mask.fill(/[0-9]/, 1)
}