import { EOrderPaymentStatus, PayViaMobileMoneySectionState } from '@customTypes/purchase'
import { CommonIcons } from '@helpers/commonIcons'
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks'
// import { setEventPageSection, setIsPurchaseForCurrentEvent } from '@sliceActionCreators/eventSlice'
import { Dispatch, SetStateAction, useCallback, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

type FinalScreenProps = {
  // status: EOrderPaymentStatus | null
  onClick: () => void
}

const SuccessFinalScreen = ({ onClick }: FinalScreenProps) => {
  return (
    <>
      <div className="display-flex align-items-center gap-8 justify-content-center flex-direction-column">
        <CommonIcons.Success />
        <p style={{ fontSize: '20px' }}>Payment was successful</p>
      </div>
      <button
        className="button primary large"
        onClick={onClick}
        //  disabled={isDisabledButton}
      >
        Back to event
      </button>
    </>
  )
}

const FailedFinalScreen = ({ onClick }: FinalScreenProps) => {
  return (
    <>
      <div className="display-flex align-items-center gap-8 justify-content-center flex-direction-column">
        <CommonIcons.Failure />
        <p style={{ fontSize: '20px' }}>Payment failed</p>
      </div>
      <button
        className="button primary large"
        onClick={onClick}
        //  disabled={isDisabledButton}
      >
        Try again
      </button>
    </>
  )
}

type FinalScreenForPayViaMobileMoneyProps = {
  // setCurrentPaymentSection: Dispatch<SetStateAction<PaymentSectionState>>
  setPayViaMobileMoneySection: Dispatch<SetStateAction<PayViaMobileMoneySectionState>>
}

const FinalScreenForPayViaMobileMoney = ({
  setPayViaMobileMoneySection,
}: FinalScreenForPayViaMobileMoneyProps) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { id } = useParams()

  const status = useAppSelector(state => state.purchase.currentPurchaseStatus)

  const onClickBtn = useCallback(() => {
    if (status === EOrderPaymentStatus.Canceled) {
      setPayViaMobileMoneySection('form')
    }
    if (status === EOrderPaymentStatus.Paid) {
      // dispatch(setIsPurchaseForCurrentEvent())
      // dispatch(setEventPageSection('main'))
      navigate(`/events/${id}`)
    }
  }, [id, navigate, setPayViaMobileMoneySection, status])

  const renderView = useMemo(() => {
    switch (status) {
      case EOrderPaymentStatus.Paid:
        return <SuccessFinalScreen onClick={onClickBtn} />
      case EOrderPaymentStatus.Canceled:
        return <FailedFinalScreen onClick={onClickBtn} />
    }
  }, [onClickBtn, status])
  return <div className="order-balance">{renderView}</div>
}

export default FinalScreenForPayViaMobileMoney
