import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks'
import { setCurrentPurchaseError } from '@sliceActionCreators/purchaseSlice'
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { Dispatch, FormEvent, SetStateAction, useCallback, useMemo, useState } from 'react'

type CheckoutFormProps = {
  setPayViaCardSection: Dispatch<SetStateAction<'form' | 'final'>>
}

const CheckoutForm = ({ setPayViaCardSection }: CheckoutFormProps) => {
  const stripe = useStripe()
  const elements = useElements()
  const dispatch = useAppDispatch()
  const user = useAppSelector(state => state.user.user)

  const [isProcessing, setIsProcessing] = useState(false)
  const [isShownSubmitBtn, setIsShownSubmitBtn] = useState(false)

  const isDisabledBtn = useMemo(
    () => isProcessing || !stripe || !elements,
    [elements, isProcessing, stripe]
  )

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (!stripe || !elements) {
      return
    }
    setIsProcessing(true)
    setCurrentPurchaseError(null)

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `https://${window.location.hostname}`,
        payment_method_data: {
          billing_details: {
            name: user?.name || '',
            email: user?.email || '',
          },
        },
      },
      redirect: 'if_required',
    })

    if (error) {
      console.log(error)

      if (error.type === 'validation_error') {
        return
      }

      dispatch(setCurrentPurchaseError(error))
      setPayViaCardSection('final')
    } else {
      setPayViaCardSection('final')
    }

    setIsProcessing(false)
  }

  const onReadyForm = useCallback(() => {
    setIsShownSubmitBtn(true)
  }, [])

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement
        onReady={onReadyForm}
        options={{
          layout: {
            type: 'tabs',
            defaultCollapsed: false,
          },
        }}
      />
      {isShownSubmitBtn && (
        <button className="button primary large w-100 mt-16" disabled={isDisabledBtn}>
          Submit
        </button>
      )}
    </form>
  )
}

export default CheckoutForm
