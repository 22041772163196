import { EmailFormType, LoginScreenState } from '@customTypes/login'
import { useCallback, useMemo, useState } from 'react'
import EmailForm from './EmailForm'
import { Credentials, SignUpArgsRequest } from '@customTypes/auth'
import SignInForm from './SignInForm'
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks'
import authActionCreators from '@actionCreators/authActionCreators'
import { omit } from 'lodash'
import SignUpForm from './SignUpForm'
import { useNavigate } from 'react-router-dom'
import Loader from '@components/ui/Loader'
import ResetPasswordForm from './ResetPasswordForm'

type LoginViaEmailAndPasswordProps = {
  urlFrom: string
}

const LoginViaEmailAndPassword = ({ urlFrom }: LoginViaEmailAndPasswordProps) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const signUpIsLoading = useAppSelector(state => state.auth.signUpLoadState.isLoading)
  const signInViaEmailIsLoading = useAppSelector(
    state => state.auth.signInViaEmailAndPasswordLoadState.isLoading
  )

  const [currentScreen, setCurrentScreen] = useState<LoginScreenState>('email')
  const [userData, setUserData] = useState<SignUpArgsRequest>({
    email: '',
    password: '',
    firstName: '',
    lastName: '',
  })

  const onSubmit = useCallback(
    (formData: EmailFormType | Credentials | SignUpArgsRequest) => {
      if (currentScreen === 'email') {
        setUserData(prevState => ({ ...prevState, ...{ email: formData.email } }))
        setCurrentScreen('sign-in')
      }

      if (currentScreen === 'sign-in') {
        setUserData(prevState => ({
          ...prevState,
          ...{ email: formData.email },
        }))

        const bodyRequest = omit(formData, ['firstName', 'lastName']) as Credentials

        dispatch(authActionCreators.signInViaEmailAndPassword(bodyRequest))
          .unwrap()
          .then(() => {
            navigate(urlFrom)
          })
      }

      if (currentScreen === 'sign-up') {
        setUserData(prevState => ({
          ...prevState,
          ...formData,
        }))
        const args = { ...(formData as SignUpArgsRequest) }

        dispatch(authActionCreators.signUp(args))
          .unwrap()
          .then(() => {
            navigate(urlFrom)
            // dispatch(authActionCreators.sendEmailVerificationAfterSignUp())
          })
      }

      if (currentScreen === 'reset-password') {
        setUserData(prevState => ({
          ...prevState,
          ...{ email: formData.email },
        }))

        dispatch(authActionCreators.sendEmailToResetPassword(formData.email))
          .unwrap()
          .then(() => {
            setCurrentScreen('sign-in')
          })
      }
    },
    [currentScreen, dispatch, navigate, urlFrom]
  )

  const renderView = useMemo(() => {
    switch (currentScreen) {
      case 'email':
        return (
          <EmailForm
            onParentSubmit={onSubmit}
            userData={userData}
            setCurrentScreen={setCurrentScreen}
          />
        )
      case 'sign-in':
        return (
          <SignInForm
            onParentSubmit={onSubmit}
            userData={userData}
            setCurrentScreen={setCurrentScreen}
          />
        )
      case 'sign-up':
        return (
          <SignUpForm
            onParentSubmit={onSubmit}
            userData={userData}
            setCurrentScreen={setCurrentScreen}
          />
        )
      case 'reset-password':
        return (
          <ResetPasswordForm
            onParentSubmit={onSubmit}
            userData={userData}
            setCurrentScreen={setCurrentScreen}
          />
        )
    }
  }, [currentScreen, onSubmit, userData])
  return (
    <>
      {(signUpIsLoading || signInViaEmailIsLoading) && <Loader className="spinner-fixed" />}

      <div className="ms-box">
        <div style={{ maxWidth: '320px' }}>{renderView}</div>
      </div>
    </>
  )
}

export default LoginViaEmailAndPassword
