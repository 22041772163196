import authServices from "@api/services/authServices"
import { AuthorizeRequestArg, AuthorizeRequestBody, Credentials, SignUpArgsRequest } from "@customTypes/auth"
import { CustomErrorResponse } from "@customTypes/common"
import { createAsyncThunk } from "@reduxjs/toolkit"
import localStorageData from "@utils/localStorage"
import eventActionCreators from "./eventActionCreators"
import { setEventPageSection } from "@sliceActionCreators/eventSlice"
import userActionCreators from "./userActionCreators"
import { Slide, toast } from "react-toastify"
import { firebaseErrors } from "@utils/errors"
import { auth } from "@fire-base/mainFirebase"

const signInFirebaseWithGoogle = createAsyncThunk(
  'auth/signInFirebaseWithGoogle', async (_, { rejectWithValue, dispatch }) => {
    try {
      await authServices.signInFirebaseWithGoogle()

      const currentUser = auth.currentUser

      if (currentUser) {
        const accessToken = await currentUser.getIdToken(false)

        localStorageData.setData("authData", accessToken)
      }


      // dispatch(clearEvent())


      return 'success'
    } catch (e) { const err = e as CustomErrorResponse }
  })

const authorizeUser = createAsyncThunk(
  'auth/authorize', async (arg: AuthorizeRequestArg, { rejectWithValue, dispatch }) => {
    const { body, navigate } = arg

    try {
      await authServices.authorizeUser(body)

      localStorageData.setData("authData", body.accessToken)
      // dispatch(eventActionCreators.getEventList())
      // dispatch(eventActionCreators.getActiveEventForAuthorizedUser())
      dispatch(userActionCreators.getProfile(navigate))
      // dispatch(purchaseActionCreators.getOrdersList())

      // return res
    } catch (e) {
      const err = e as CustomErrorResponse

      return rejectWithValue('error')
    }

  }
)

const logOut = createAsyncThunk(
  'auth/logOut', async (_, { dispatch, rejectWithValue }) => {
    try {
      await authServices.logOutFirebase()

      localStorageData.clearAllData()

      dispatch(setEventPageSection("main"))
    } catch (e) {

    }
  }
)

const sendEmailVerificationAfterSignUp = createAsyncThunk(
  'auth/sendEmailVerificationAfterSignUp', async () => {
    try {
      await authServices.sendEmailVerificationAfterSignUp()

      toast.success('Email has been sent!',
        { position: 'top-right', transition: Slide, pauseOnFocusLoss: false })

    } catch (e) {
      const err = e as { code: string }
      const errorCode = err.code
      // console.log(test)

      toast.error(firebaseErrors[errorCode] || 'Something went wrong!',
        { position: 'top-right', transition: Slide, pauseOnFocusLoss: false, autoClose: 3000, })
    }
  })

const signUp = createAsyncThunk(
  'auth/signUp', async (args: SignUpArgsRequest, { dispatch, rejectWithValue }) => {
    const signUpBodyRequest = { email: args.email, password: args.password }
    const updateUserProfileBodyRequest = { firstName: args.firstName, lastName: args.lastName }
    try {
      await authServices.signUp(signUpBodyRequest)
      // authServices.sendEmailVerificationAfterSignUp()
      const res = await authServices.updateUserProfile(updateUserProfileBodyRequest)

      const currentUser = auth.currentUser

      if (currentUser) {
        const accessToken = await currentUser.getIdToken(false)

        localStorageData.setData("authData", accessToken)
      }

      return res
    } catch (e) {
      const err = e as { code: string }
      const errorCode = err.code
      // console.log(test)

      toast.error(firebaseErrors[errorCode] || 'Something went wrong!',
        {
          position: 'top-right',
          transition: Slide,
          pauseOnFocusLoss: false,
          autoClose: 3000,
        })

      return rejectWithValue('error')
    }
  }
)

const signInViaEmailAndPassword = createAsyncThunk(
  'auth/signInViaEmailAndPassword',
  async (credentials: Credentials, { dispatch, rejectWithValue, fulfillWithValue }) => {
    try {
      await authServices.signInViaEmailAndPassword({ email: credentials.email, password: credentials.password })

      const currentUser = auth.currentUser

      if (currentUser) {
        const accessToken = await currentUser.getIdToken(false)

        localStorageData.setData("authData", accessToken)
      }

      return fulfillWithValue('success')
    } catch (e) {

      const err = e as { code: string }
      const errorCode = err.code

      toast.error(firebaseErrors[errorCode] || 'Something went wrong!',
        {
          position: 'top-right',
          transition: Slide,
          pauseOnFocusLoss: false,
          autoClose: 3000,
        })

      return rejectWithValue('error')
    }
  })

const sendEmailToResetPassword = createAsyncThunk(
  'auth/sendEmailToRestPassword',
  async (email: string, { fulfillWithValue, rejectWithValue }) => {
    try {
      await authServices.sendEmailToResetPassword(email)

      toast.success('Email has been sent!',
        {
          position: 'top-right',
          transition: Slide,
          pauseOnFocusLoss: false,
          autoClose: 3000,
        })

      return fulfillWithValue('success')
    } catch (e) {
      const err = e as { code: string }
      const errorCode = err.code

      toast.error(firebaseErrors[errorCode] || 'Something went wrong!',
        {
          position: 'top-right',
          transition: Slide,
          pauseOnFocusLoss: false,
          autoClose: 3000,
        })

      return rejectWithValue('error')
    }
  })

const authActionCreators = {
  signInFirebaseWithGoogle,
  authorizeUser,
  logOut,
  signUp,
  signInViaEmailAndPassword,
  sendEmailVerificationAfterSignUp,
  sendEmailToResetPassword,
}

export default authActionCreators