import { CommonIcons } from '@helpers/commonIcons'
import React, { useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

const FinalScreenForPayViaVoucherForm = () => {
  const navigate = useNavigate()
  const { id } = useParams()

  const onClickBtn = useCallback(() => {
    navigate(`/events/${id}`)
  }, [id, navigate])
  
  return (
    <>
      <div className="display-flex align-items-center gap-8 justify-content-center flex-direction-column">
        <CommonIcons.Success />
        <p style={{ fontSize: '20px' }}>Product was purchased successfully</p>
      </div>
      <button
        className="button primary large"
        onClick={onClickBtn}
      >
        Back to event
      </button>
    </>
  )
}

export default FinalScreenForPayViaVoucherForm
