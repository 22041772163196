import Icon from '@components/Icon'
import { memo, useCallback, useMemo } from 'react'
import { CommonIcons } from '@helpers/commonIcons'
import { Product } from '@customTypes/event'

type ShortEventInfoProps = {
  btnType: 'paid' | 'unpaid'
  product: Product
  color?: string
  onParentClick?: (productId: number) => void
}

const ShortEventInfo = memo(
  ({ onParentClick, btnType, product, color = 'dark' }: ShortEventInfoProps) => {
    const isDisabledBtn = useMemo(() => btnType === 'paid', [btnType])

    const btnContent = useMemo(
      () =>
        btnType === 'paid' ? (
          <>
            <CommonIcons.Check />
            Paid
          </>
        ) : (
          <>
            <Icon name="crown" size={32} />
            {product.currency.sign}
            {product.price}
          </>
        ),

      [btnType, product.currency.sign, product.price]
    )

    const onClick = useCallback(() => {
      onParentClick && onParentClick(product.id)
    }, [onParentClick, product.id])

    const imgUrl = useMemo(() => {
      if (product.imgUrl === 'image' || !product.imgUrl) {
        return null
      }

      return product.imgUrl
    }, [product.imgUrl])

    return (
      <div className={`box justify-content-space-between ${color}`}>
        <div className="media event-buy">
          <div className="media-image">
            {imgUrl ? (
              <img src={product.imgUrl} alt="" />
            ) : (
              <div className="user-icon-wrap">
                <Icon name="crown" size={34} />
              </div>
            )}
          </div>

          <div className="media-body">
            <div className="media-title">{product.title}</div>
            <div className="media-message">
              <span>{product.shortDescription}</span>
            </div>
          </div>
        </div>
        <button
          className="button large primary"
          disabled={isDisabledBtn}
          onClick={onClick}
          // {...(onParentClick && { onClick })}
        >
          {btnContent}
        </button>
      </div>
    )
  }
)

export default ShortEventInfo
