import axios, { AxiosError, Method } from 'axios'
// import { CustomErrorResponse } from '../types/axiosTypes'
import { combineUrl } from './utils'
import { CustomErrorResponse } from '@customTypes/common'

const ownAnonymousAxios = axios.create()

export const anonymousRequest = async <TRequest, TResponse>(
	path: string[],
	method: Method = 'GET',
	body?: TRequest,
	queryParams?: Record<string, string | number>,
): Promise<TResponse> => {
	const upperCaseMethod = method.toUpperCase()
	const apiUrl = import.meta.env.VITE_API_URL 
	const defaultApiUrl = 'v1'

	const requestUri = combineUrl(apiUrl, defaultApiUrl,  ...path)

	try {
		const response = await ownAnonymousAxios({
			method: upperCaseMethod,
			baseURL: requestUri,
			headers: {
				'Content-Type': 'application/json',
			},
			data: body,
			params: queryParams,
			responseType: 'json',
		})

		return response.data as TResponse
	} catch (error) {
		const e = error as AxiosError
		const customErrorResponse = JSON.parse(e.request.response) as CustomErrorResponse
		return Promise.reject(customErrorResponse)
	}
}
