import BecomeAgentForm from '@components/agent/BecomeAgentForm'
import Loader from '@components/ui/Loader'
import { useAppSelector } from '@hooks/reduxHooks'
import { useLayoutEffect } from 'react'

const AgentPage = () => {
  const addNewAgentIsLoading = useAppSelector(state => state.agent.addNewAgentLoadState.isLoading)

  useLayoutEffect(() => {
    const mainLayout = document.querySelector('.main-page-layout')

    mainLayout!.classList.add('no-background')
    return () => {
      mainLayout!.classList.remove('no-background')
    }
  }, [])
  return (
    <div className="span-all-areas">
      <div className="centered-content align-self-start">
        <div className="event-message-wrapper mb-24">
          <div className="event-message simple text-center text-uppercase">
            Become an official agent now to earn money from selling tickets to Mastreetz music
            shows.
          </div>
        </div>

        {addNewAgentIsLoading && <Loader className="spinner-fixed" />}
        <div className="ms-box" style={{ marginBottom: '48px' }}>
          <div className="form-container">
            <BecomeAgentForm />
          </div>
        </div>
      </div>
    </div>
  )
}

export default AgentPage
