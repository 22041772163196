import purchaseActionCreators from '@actionCreators/purchaseActionCreators'
import Icon from '@components/Icon'
import { EOrderPaymentStatus, PayViaInnBucksSectionState } from '@customTypes/purchase'
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks'
import { Dispatch, SetStateAction, useCallback } from 'react'
import QRCode from 'react-qr-code'

type QrSectionForPayingViaInnBucksProps = {
  setPayViaInnBucksSection: Dispatch<SetStateAction<PayViaInnBucksSectionState>>
}

const QrSectionForPayingViaInnBucks = ({
  setPayViaInnBucksSection,
}: QrSectionForPayingViaInnBucksProps) => {
  const dispatch = useAppDispatch()

  const dataAfterCreationOrder = useAppSelector(
    state => state.purchase.dataAfterCreationOrderViaInnBucks
  )

  const redirectToFinalMobileMoneySection = useCallback(
    (status: EOrderPaymentStatus) => {
      if (status === EOrderPaymentStatus.Canceled || status === EOrderPaymentStatus.Paid) {
        setPayViaInnBucksSection('final')
      }
    },
    [setPayViaInnBucksSection]
  )

  const onClickCheckPayment = useCallback(() => {
    dispatch(purchaseActionCreators.checkPaymentViaMobileMoney())
      .unwrap()
      .then(res => redirectToFinalMobileMoneySection(res.status))
      .catch(() => {})
  }, [dispatch, redirectToFinalMobileMoneySection])

  const onBack = useCallback(() => {
    setPayViaInnBucksSection('form')
  }, [setPayViaInnBucksSection])
  return (
    <div className="order-balance">
      <div className="display-flex align-items-center gap-8">
        <button className="icon-button transparent" onClick={onBack} type="button">
          <Icon name="arrow-right" size={20} />
        </button>
        Use mobile money:
      </div>
      <div className="display-flex align-items-center justify-content-center gap-8">
        <p>Auth code:</p>
        <p>{dataAfterCreationOrder?.authorizationcode}</p>
      </div>
      {dataAfterCreationOrder?.qr_code && (
        <div className="display-flex justify-content-center mb-16">
          <QRCode size={150} value={dataAfterCreationOrder?.authorizationcode} />
        </div>
      )}

      {/* <p>{instructionText}</p> */}
      <button className="button primary large" onClick={onClickCheckPayment}>
        Check payment status
      </button>
    </div>
  )
}

export default QrSectionForPayingViaInnBucks
