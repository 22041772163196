import chatActionCreators from '@actionCreators/chatActionCreators'
import eventActionCreators from '@actionCreators/eventActionCreators'
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks'
import localStorageData from '@utils/localStorage'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import dateFormat from '@utils/dateFormat'
import VideoPlayer from '@components/VideoPlayer'
import EndScreen from '@components/event/EndScreen'

type VideoPlayerPageScreenType = 'player' | 'end'

const VideoPlayerPage = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { id, videoId } = useParams()

  const video = useAppSelector(state => state.event.video)
  const event = useAppSelector(state => state.event.currentEvent)
  const initializedLikesForVideo = useAppSelector(state => state.event.initializedLikesForVideo)
  const userTimeZone = useAppSelector(state => state.user.user?.timezone)
  const currentMessageCounter = useAppSelector(state => state.chat.currentMessageCounter)
  const chatMessageList = useAppSelector(state => state.chat.chatList)

  const [videoPlayerPageScreen, setVideoPlayerPageScreen] =
    useState<VideoPlayerPageScreenType>('player')

  const localLikesCountRef = useRef<number>(0)

  const token = localStorageData.getData('authData')

  const currentUrl = useMemo(() => {
    if (video?.url && token && event?.id) {
      return `${video?.url}?token=${token}&eventId=${event?.id}`
    } else {
      return ''
    }
  }, [event?.id, token, video?.url])

  useEffect(() => {
    if (id) {
      dispatch(eventActionCreators.getEventById(id))
        .unwrap()
        .catch(() => {
          navigate('/')
        })
    }

    if (videoId) {
      dispatch(eventActionCreators.getVideoById(videoId))
        .unwrap()
        .catch(() => {
          navigate('/')
        })
      return
    }

    navigate('/')
  }, [dispatch, id, navigate, videoId])

  const addLikesByVideoId = useCallback(() => {
    const arg = {
      videoId: video?.id || 0,
      count: localLikesCountRef.current,
    }

    if (localLikesCountRef.current > 0) {
      dispatch(eventActionCreators.addLikesByVideoId(arg))
        .unwrap()
        .then(() => (localLikesCountRef.current = 0))
    }
  }, [dispatch, video?.id])

  const getLikesAndChatList = useCallback(() => {
    if (video) {
      video.id && dispatch(eventActionCreators.getLikesByVideoId(video.id))
      video.chat.id && dispatch(chatActionCreators.getChatListByEventId(video.chat.id))
    }
  }, [dispatch, video])

  useEffect(() => {
    const interval = setInterval(addLikesByVideoId, 2000)

    return () => {
      clearInterval(interval)
    }
  }, [addLikesByVideoId])

  useEffect(() => {
    const interval = setInterval(getLikesAndChatList, 2000)

    return () => {
      clearInterval(interval)
    }
  }, [getLikesAndChatList])

  useEffect(() => {
    if (!initializedLikesForVideo && video?.id) {
      getLikesAndChatList()
    }
  }, [getLikesAndChatList, initializedLikesForVideo, video?.id])

  const countDownDate = useMemo(
    () =>
      (event &&
        userTimeZone &&
        dateFormat.formatInTimeZone(event.startDate, 'yyyy-MM-dd HH:mm:ss', userTimeZone)) ||
      '',
    [event, userTimeZone]
  )

  const endDateWithTimeZone = useMemo(
    () => event && dateFormat.formatInTimeZone(event.endDate, 'yyyy-MM-dd HH:mm:ss', userTimeZone!),
    [event, userTimeZone]
  )

  const moveToEndScreen = useCallback(() => {
    if (typeof endDateWithTimeZone === 'string') {
      const remainingTimeFromEndDate =
        new Date(endDateWithTimeZone).getTime() - new Date().getTime()

      if (remainingTimeFromEndDate < 0) {
        setVideoPlayerPageScreen('end')
      }
    }
  }, [endDateWithTimeZone])

  useEffect(() => {
    const interval = setInterval(moveToEndScreen, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [moveToEndScreen])

  if (video && !video?.url) {
    if (event) {
      return <Navigate to={`/events/${event.url}`} />
    } else {
      return <Navigate to="/events" />
    }
  }
  if (!video || !event) {
    return null
  }

  return (
    <>
      {videoPlayerPageScreen === 'player' && countDownDate && (
        <VideoPlayer
          countDownTime={countDownDate}
          currentUrl={currentUrl}
          localLikesCountRef={localLikesCountRef}
          chatMessageList={chatMessageList}
          currentMessageCounter={currentMessageCounter}
        />
      )}
      {videoPlayerPageScreen === 'end' && <EndScreen eventId={event.url}/>}
    </>
  )
}

export default VideoPlayerPage
