import { EEventType, ShortEvent } from '@customTypes/event'
import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import Badge from '../Badge'
import Icon from '@components/Icon'
import { useAppSelector } from '@hooks/reduxHooks'
import isEqual from 'lodash/isEqual'
import dateFormat from '@utils/dateFormat'

type CarouselItemProps = {
  data: ShortEvent
}
const CarouselItem = ({ data }: CarouselItemProps) => {
  const userTimeZone =
    useAppSelector(state => state.user.user?.timezone, isEqual) ||
    Intl.DateTimeFormat().resolvedOptions().timeZone

  const titleDate = useMemo(() => {
    if (data.isOnline) {
      return (
        userTimeZone && dateFormat.formatInTimeZone(data.startDate, 'do MMMM yyyy', userTimeZone)
      )
    }

    const formattedStartedDate = dateFormat.formatDate(data.startDate, 'do MMMM yyyy')
    const formattedEndDate = dateFormat.formatDate(data.endDate, 'do MMMM yyyy')

    if (formattedStartedDate === formattedEndDate) {
      return dateFormat.formatDate(data.startDate, 'do MMMM yyyy')
    } else {
      return `${dateFormat.formatDate(data.startDate, 'do')} - ${formattedEndDate}`
    }
  }, [data.endDate, data.isOnline, data.startDate, userTimeZone])
  return (
    <article className="ms-banner-card">
      <Link to={`/events/${data.url}`}>
        <Badge type={data.isOnline ? 'watch' : 'buy'} />
        <div className="ms-banner-card-media">
          <img src={data.horizontalImg}></img>
        </div>
        <div className="ms-banner-card-body">
          {data.url === 'ladies-night-amplified' ? (
            <h2
              className="ms-banner-card-title shining-text text-uppercase"
              style={{ visibility: 'hidden' }}
            >
              <Icon name="crown" size={34} />
              {data.artist}
            </h2>
          ) : (
            <h2 className="ms-banner-card-title shining-text text-uppercase">
              <Icon name="crown" size={34} />

              {data.type === EEventType.Film ||
              data.url === 'girlfest' ||
              data.url === 'zimfest-live-zimfest-medley'
                ? data.title
                : data.artist}
            </h2>
          )}
          <div className="event-message simple">{titleDate}</div>
          {data.url === 'girlfest' || data.url === 'zimfest-live-zimfest-medley' ? (
            <p className="h4 text-uppercase mt-32 mb-32" />
          ) : (
            <p className="h4 text-uppercase mt-16 mb-16">
              {data.type === EEventType.Film ? data.artist : data.title}
            </p>
          )}
          <button className="button large primary">
            <Icon name="crown" size={32} />
            More...
          </button>
        </div>
      </Link>
    </article>
  )
}

export default CarouselItem
