const getListBySearchValue = (arr: any, searchValue: string, arrOfFieldsForSearch: Array<string>) => {
  const value = searchValue.trim().toLowerCase()

  return arr.filter(item =>
    arrOfFieldsForSearch.some(entry =>{
      // console.log(item[entry].toLowerCase())


      return String(item[entry]).toLowerCase().includes(value)
    }
      
      
    )
  )
}




const agentUtils = {
  getListBySearchValue
}

export default agentUtils