import { RouterProvider } from 'react-router-dom'
import { useCallback, useEffect } from 'react'
import { auth } from '@fire-base/mainFirebase.js'
import { User } from '@firebase/auth'
import router from '@routes/routerConfig.tsx'
import { useAppDispatch } from '@hooks/reduxHooks.ts'
import authActionCreators from '@actionCreators/authActionCreators.ts'
import { HelmetProvider } from 'react-helmet-async'
import eventActionCreators from '@actionCreators/eventActionCreators'
import SplashScreen from '@pages/SplashPage'
import ReactGA from 'react-ga4'

ReactGA.initialize(import.meta.env.VITE_GOOGLE_ANALYTICS)

const App = () => {
  // const dispatch = useAppDispatch()

  // const authorizeUser = useCallback(
  //   async (user: User) => {
  //     const userFirebaseAccessToken = await user.getIdToken(false)
  //     const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  //     const body = {
  //       providerId: user.providerData[0].providerId,
  //       accessToken: userFirebaseAccessToken,
  //       timezone: userTimeZone,
  //     }

  //     // console.log(userFirebaseAccessToken)

  //     dispatch(authActionCreators.authorizeUser(body)).unwrap()
  //     .then(()=>window.location)
  //   },
  //   [dispatch]
  // )

  // useEffect(() => {
  //   const unsubscribe = auth.onAuthStateChanged(user => {
  //     // console.log(user)
  //     if (user) {
  //       authorizeUser(user)
  //     } else {
  //       // dispatch(eventActionCreators.getPublicEvent())
  //     }
  //   })

  //   return () => unsubscribe()
  // }, [authorizeUser, dispatch])

  return (
    <HelmetProvider>
      <SplashScreen />
      <RouterProvider router={router} />
    </HelmetProvider>
  )
}

export default App
