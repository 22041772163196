import agentActionCreators from '@actionCreators/agentActionCreators'
import Icon from '@components/Icon'
import { Order } from '@customTypes/purchase'
import { CommonIcons } from '@helpers/commonIcons'
import { useAppDispatch } from '@hooks/reduxHooks'
import dateFormat from '@utils/dateFormat'
import React, { useCallback, useMemo, useState } from 'react'

type AgentOrdersTableRowProps = {
  order: Order
}

const AgentOrdersTableRow = ({ order }: AgentOrdersTableRowProps) => {
  const dispatch = useAppDispatch()

  const [isShownOrderItems, setIsShownOrderItems] = useState(false)

  const onToggleProductsClick = useCallback(() => {
    setIsShownOrderItems(prevState => !prevState)
  }, [])
  const formattedDate = useMemo(() => {
    return dateFormat.formatDate(order.createdAt, 'dd-MM-yyyy HH:mm:ss')
  }, [order.createdAt])

  const itemQuantity = useMemo(() => {
    return order.orderItems.reduce((acc, orderItem) => {
      return acc + orderItem.qty
    }, 0)
  }, [order.orderItems])

  const sendMailAgain = useCallback(() => {
    dispatch(agentActionCreators.sendMailAgain(order.id))
  }, [dispatch, order.id])

  return (
    <>
      <div className="table-row agent-orders-grid-columns">
        <div className={`table-cell${isShownOrderItems ? ' hide-border' : ''}`}>{order.id}</div>
        <div className={`table-cell${isShownOrderItems ? ' hide-border' : ''}`}>
          {formattedDate}
        </div>
        <div className={`table-cell${isShownOrderItems ? ' hide-border' : ''}`}>
          {order.eventTitle}
        </div>
        <div className={`table-cell${isShownOrderItems ? ' hide-border' : ''}`}>{itemQuantity}</div>
        <div className={`table-cell${isShownOrderItems ? ' hide-border' : ''}`}>
          ${Number(order.totalAmount).toFixed(2)}
        </div>
        <div
          className={`table-cell display-flex align-items-center${
            isShownOrderItems ? ' hide-border' : ''
          }`}
        >
          <button className="button middle primary" onClick={sendMailAgain}>
            <CommonIcons.Mail color="#ffffff" />
          </button>
        </div>
        <div
          className={`table-cell display-flex align-items-center${
            isShownOrderItems ? ' hide-border' : ''
          }`}
        >
          {isShownOrderItems ? (
            <button className="button middle primary" onClick={onToggleProductsClick}>
              <Icon name="arrows-up" size={20} /> Details
            </button>
          ) : (
            <button className="button middle primary" onClick={onToggleProductsClick}>
              <Icon name="arrows-down" size={20} /> Details
            </button>
          )}
        </div>
      </div>
      {isShownOrderItems && (
        <div className="agent-order-content">
          {order.orderItems.map(orderItem => (
            <div
              className="agent-order-content-row"
              // style={{ display: 'flex', gap: '10px', padding: '10px 10px 10px 220px' }}
            >
              <div className="agent-order-content-cell"></div>
              <div className="agent-order-content-cell"></div>
              <div className="agent-order-content-cell">Title: {orderItem.title}</div>
              {/* <div className="agent-order-content-cell"></div> */}
              <div className="agent-order-content-cell"> Qty: {orderItem?.qty}</div>
              <div className="agent-order-content-cell">
                Total: ${Number(orderItem.price).toFixed(2)}
              </div>

              <div className="agent-order-content-cell"></div>
              {/*<div></div> */}
              {/* <div></div> */}
            </div>
          ))}
        </div>
      )}
    </>
  )
}

export default AgentOrdersTableRow
