import purchaseActionCreators from '@actionCreators/purchaseActionCreators'
import Icon from '@components/Icon'
import ShortEventInfo from '@components/event/ShortEventInfo'
import ShareSocialMediaList from '@components/event/shareSection/ShareSocialMediaList'
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks'
import { setSelectedProductToStore } from '@sliceActionCreators/purchaseSlice'
import dateFormat from '@utils/dateFormat'
import localStorageData from '@utils/localStorage'
import { isEqual } from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import { Slide, toast } from 'react-toastify'

const EventSuccessfulPaymentPage = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const user = useAppSelector(state => state.user.user)

  const [isShownShare, setIsShownShare] = useState(false)
  const selectedProduct = useAppSelector(state => state.purchase.selectedProduct)
  const event = useAppSelector(state => state.event.currentEvent)
  const userTimeZone =
    useAppSelector(state => state.user.user?.timezone, isEqual) ||
    Intl.DateTimeFormat().resolvedOptions().timeZone

  useEffect(() => {
    const selectedProductIdFromLocalStorage = localStorageData.getData('selectedProductId')

    if (selectedProductIdFromLocalStorage) {
      dispatch(purchaseActionCreators.getProductById(selectedProductIdFromLocalStorage))
        .unwrap()
        .catch(err => {
          console.log(err)
          // navigate('/')
        })
    } else {
      navigate('/')
    }

    return () => {
      localStorageData.removeDataItem('selectedProductId')
    }
  }, [dispatch, navigate])

  const titleDate = useMemo(() => {
    if (!event) {
      return
    }

    if (event.isOnline) {
      return (
        userTimeZone && dateFormat.formatInTimeZone(event.startDate, 'do MMMM yyyy', userTimeZone)
      )
    }

    const formattedStartedDate = dateFormat.formatDate(event.startDate, 'do MMMM yyyy')
    const formattedEndDate = dateFormat.formatDate(event.endDate, 'do MMMM yyyy')

    if (formattedStartedDate === formattedEndDate) {
      return dateFormat.formatDate(event.startDate, 'do MMMM yyyy')
    } else {
      return `${dateFormat.formatDate(event.startDate, 'do')} - ${formattedEndDate}`
    }
  }, [event, userTimeZone])

  const currentUserName = useMemo(() => {
    if (user) {
      return `${user.name},`
    }

    return ''
  }, [user])

  const onCloseShare = useCallback(() => {
    setIsShownShare(false)
  }, [])

  const showToastMessage = useCallback(() => {
    if (!isShownShare) {
      {
        toast(<ShareSocialMediaList linkPart={`${event?.url}`} />, {
          position: 'bottom-center',
          // className: 'share-toastify',
          draggable: true,
          transition: Slide,
          autoClose: false,
          closeOnClick: false,
          draggableDirection: 'y',
          draggablePercent: 40,
          onClose: onCloseShare,
        })
        setIsShownShare(true)
      }
    }
  }, [event?.url, isShownShare, onCloseShare])

  if (!selectedProduct && !event) {
    return null
  }

  if (selectedProduct && !selectedProduct.isPurchased) {
    return <Navigate to="/" />
  }

  return (
    <div className="centered-page-content light-background successful-background pt-64 pb-64 px-16 text-center">
      <div className="centered-page-dialog centered-success-page-dialog ms-box">
        <div className="mb-32">
          <Icon
            name="crown"
            size={64}
            style={{
              '--icon-color': '#0000ff',
              filter: 'drop-shadow(2px 3px 1px rgba(0, 0, 0, 0.2))',
            }}
          ></Icon>
        </div>
        <div className="event-message h2 text-uppercase mb-16">
          {currentUserName} YOU’RE ALL SET!
        </div>
        <div className="h2 text-uppercase mb-16">{event?.artist}</div>
        <div className="event-message h2 text-uppercase mb-16">{titleDate}</div>
        <div className="h2 text-uppercase mb-16">{event?.title}</div>
        {/* <p>{event?.}</p> */}

        <div className="mt-48">
          {/* {currentEventProducts.length > 0 &&
            currentEventProducts?.map(product => ( */}
          {selectedProduct && (
            <ShortEventInfo
              key={selectedProduct.id}
              // title={product.title}
              btnType={selectedProduct?.isPurchased ? 'paid' : 'unpaid'}
              // text={product.shortDescription}
              // price={product.price}
              product={selectedProduct}
              // onParentClick={redirectToNextSection}
              // {...(!isPurchased && { onClick: redirectToNextSection })}
              color="grey"
            />
          )}
          {/* ))} */}
        </div>

        <button
          className="button large secondary outline w-100 mt-48"
          style={{ maxWidth: '320px' }}
          onClick={showToastMessage}
          // disabled={isDisabledBtn}
          // {...(onClick && { onClick })}
        >
          Share
        </button>
      </div>
    </div>
  )
}

export default EventSuccessfulPaymentPage
