import Icon from '@components/Icon'
import { ChangeEvent, useCallback, useState } from 'react'
import { FieldValues, UseControllerProps, useController } from 'react-hook-form'

type PasswordTextFieldFormProps<T extends FieldValues> = UseControllerProps<T> & {
  placeholder: string
  // type: string
  label: string
  maxLength: number
  autoComplete?: 'on' | 'off'
  isShownVisibleBtn: boolean
}

const PasswordTextFieldForm = <T extends FieldValues>({
  name,
  control,
  placeholder,
  label,
  maxLength,
  autoComplete = 'off',
  isShownVisibleBtn,
}: PasswordTextFieldFormProps<T>) => {
  const [isVisible, setIsVisible] = useState(false)

  const {
    field: { onChange, value },
    fieldState: { error: inputError },
  } = useController({
    name,
    control,
  })

  const onVoucherFormTextFieldChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value

      onChange(value)
    },
    [onChange]
  )

  const toggleVisiblePass = useCallback(() => {
    setIsVisible(prevState => !prevState)
  }, [])
  return (
    <div className="form-control">
      <label htmlFor={name}>{label}</label>
      <div style={{ position: 'relative' }}>
        <input
          id={name}
          className="form-input"
          onChange={onVoucherFormTextFieldChange}
          value={typeof value === 'undefined' ? '' : value}
          type={isVisible ? 'text' : 'password'}
          placeholder={placeholder}
          autoComplete={autoComplete}
          maxLength={maxLength}
        />
        {isShownVisibleBtn && (
          <button
            onClick={toggleVisiblePass}
            className="icon-button transparent"
            type="button"
            style={{ position: 'absolute', width: '40px', height: '40px', top: 0, right: 0 }}
          >
            <Icon name={isVisible ? 'eye-closed' : 'eye-opened'} size={20} />
          </button>
        )}
      </div>
      <div className="form-control-error">{inputError?.message}</div>
    </div>
  )
}

export default PasswordTextFieldForm
