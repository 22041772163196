import { authorizedRequest } from "@api/authorizeRequest"
import { AddMessageToChatRequestBody, ChatList, ChatMessage } from "@customTypes/chat"

const getChatListByEventId = (eventId: number) => {
  const path = ['chats', String(eventId)]

  return authorizedRequest<null, ChatList>('v1', path)
}

const addMessageToChat = (body: AddMessageToChatRequestBody) => {
  const path = ['chat-message']

  return authorizedRequest<AddMessageToChatRequestBody, ChatMessage>('v1', path, "POST", body)
}

const chatServices = {
  getChatListByEventId,
  addMessageToChat
}

export default chatServices