import { SvgIconProps } from '@customTypes/common'

export namespace FinanceIcons {
  export function EcoCash({ width = 32, height = 32 }: SvgIconProps) {
    return (
      <svg
        width={width}
        height={height}
        viewBox="0 0 1000 183"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M116.3 30.5H35.8V73.9H110C115.5 73.9 119.5 75.1 122.2 77.6C124.9 80 126.3 83.3 126.3 87.3C126.3 91.3 125 94.6 122.3 97.1C119.6 99.6 115.6 100.9 110 100.9H35.8V151.1H119.1C124.7 151.1 128.9 152.4 131.8 155C134.7 157.6 136.1 161.1 136.1 165.4C136.1 169.5 134.7 173 131.8 175.6C129 178.2 124.7 179.5 119.1 179.5H21.9C14.1 179.5 8.5 177.8 5.1 174.3C1.7 170.9 0 165.3 0 157.6V24.9C0 19.8 0.8 15.6 2.3 12.3C3.8 9.09999 6.2 6.69998 9.5 5.19998C12.8 3.69998 16.9 3 22 3H116.4C122.1 3 126.4 4.29999 129.1 6.79999C131.9 9.29999 133.3 12.6 133.3 16.7C133.3 20.9 131.9 24.2 129.1 26.8C126.3 29.3 122 30.5 116.3 30.5Z"
          fill="#00539D"
        />
        <mask
          id="mask0_636_85"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="148"
          y="47"
          width="128"
          height="136"
        >
          <path d="M275.9 47.1H148V182.6H275.9V47.1Z" fill="white" />
        </mask>
        <g mask="url(#mask0_636_85)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M274 141.8C274 145.9 272.8 150.3 270.3 154.9C267.8 159.6 264.1 164 259.1 168.2C254.1 172.4 247.8 175.8 240.1 178.4C232.5 181 223.9 182.2 214.4 182.2C194.1 182.2 178.2 176.3 166.8 164.4C155.4 152.5 149.7 136.7 149.7 116.7C149.7 103.2 152.3 91.3 157.5 81C162.7 70.7 170.3 62.6 180.1 57C190 51.4 201.8 48.5 215.5 48.5C224 48.5 231.8 49.8 238.9 52.3C246 54.8 252 58 257 61.9C261.9 65.8 265.7 70 268.3 74.5C270.9 78.9 272.2 83.1 272.2 86.9C272.2 90.8 270.7 94.2 267.8 96.9C264.9 99.7 261.3 101 257.2 101C254.5 101 252.2 100.3 250.4 98.9C248.6 97.5 246.6 95.2 244.3 92.1C240.3 86 236.1 81.4 231.7 78.4C227.4 75.4 221.8 73.8 215 73.8C205.3 73.8 197.4 77.6 191.5 85.2C185.5 92.8 182.6 103.2 182.6 116.4C182.6 122.6 183.4 128.3 184.9 133.4C186.4 138.6 188.6 143 191.5 146.6C194.4 150.3 197.9 153 202 154.9C206.1 156.8 210.6 157.7 215.5 157.7C222.1 157.7 227.7 156.2 232.4 153.1C237.1 150.1 241.3 145.4 244.9 139.1C246.9 135.4 249.1 132.5 251.4 130.4C253.7 128.3 256.6 127.2 260 127.2C264 127.2 267.3 128.8 270 131.8C272.6 135.1 274 138.4 274 141.8Z"
            fill="#00539D"
          />
        </g>
        <mask
          id="mask1_636_85"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="287"
          y="47"
          width="135"
          height="136"
        >
          <path d="M421.6 47.1H287V182.6H421.6V47.1Z" fill="white" />
        </mask>
        <g mask="url(#mask1_636_85)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M386.5 115.7C386.5 102.3 383.5 91.8001 377.6 84.4001C371.7 77.0001 363.8 73.2 353.8 73.2C347.4 73.2 341.7 74.9 336.8 78.2C331.9 81.5 328.1 86.4001 325.5 93.0001C322.9 99.5001 321.6 107.1 321.6 115.8C321.6 124.4 322.9 131.9 325.5 138.3C328.1 144.7 331.9 149.7 336.7 153.1C341.5 156.5 347.3 158.2 353.8 158.2C363.8 158.2 371.7 154.4 377.6 146.9C383.6 139.3 386.5 128.9 386.5 115.7ZM419.5 115.7C419.5 125.5 418 134.5 414.9 142.8C411.9 151.1 407.4 158.2 401.6 164.1C395.8 170 388.9 174.6 380.9 177.7C372.9 180.9 363.9 182.5 353.8 182.5C343.8 182.5 334.9 180.9 326.9 177.7C319 174.5 312.1 169.9 306.2 163.9C300.4 157.9 296 150.8 293 142.7C290 134.6 288.5 125.5 288.5 115.7C288.5 105.8 290 96.6 293.1 88.3C296.2 80 300.5 73 306.2 67.1C311.9 61.3 318.8 56.7 326.9 53.6C335 50.4 344 48.8 353.8 48.8C363.8 48.8 372.8 50.4 380.9 53.6C389 56.8 396 61.4 401.7 67.3C407.5 73.3 411.9 80.3001 414.9 88.5001C418 96.8001 419.5 105.8 419.5 115.7Z"
            fill="#00539D"
          />
        </g>
        <mask
          id="mask2_636_85"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="432"
          y="0"
          width="161"
          height="183"
        >
          <path d="M592.2 0H432.9V182.6H592.2V0Z" fill="white" />
        </mask>
        <g mask="url(#mask2_636_85)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M592 123.8C592 129.4 590.6 135.5 587.9 142C585.1 148.6 580.8 155 574.8 161.3C568.8 167.6 561.3 172.7 552 176.6C542.8 180.5 532 182.5 519.7 182.5C510.4 182.5 501.9 181.6 494.3 179.8C486.6 178.1 479.7 175.3 473.5 171.6C467.3 167.9 461.6 163 456.4 156.8C451.7 151.3 447.8 145.1 444.5 138.2C441.2 131.3 438.7 124 437.1 116.2C435.4 108.4 434.6 100.1 434.6 91.4C434.6 77.2 436.7 64.5 440.8 53.2C444.9 42 450.9 32.3 458.6 24.4C466.3 16.4 475.3 10.3 485.7 6.20001C496.1 2.00001 507.1 -0.0999756 518.8 -0.0999756C533.1 -0.0999756 545.8 2.80003 557 8.50003C568.2 14.2 576.7 21.3 582.6 29.6C588.6 38 591.5 45.9 591.5 53.4C591.5 57.5 590 61.1 587.2 64.2C584.3 67.3 580.8 68.9 576.7 68.9C572.1 68.9 568.7 67.8 566.4 65.6C564.1 63.4 561.6 59.7 558.7 54.4C554 45.6 548.6 39.1 542.3 34.8C536 30.5 528.2 28.3 519 28.3C504.3 28.3 492.6 33.9 483.9 45C475.2 56.2 470.8 72 470.8 92.6C470.8 106.3 472.7 117.7 476.6 126.9C480.5 136 485.9 142.8 493 147.3C500.1 151.8 508.3 154.1 517.8 154.1C528.1 154.1 536.8 151.5 543.9 146.4C551 141.3 556.4 133.8 560 124C561.5 119.3 563.4 115.5 565.7 112.6C568 109.6 571.5 108.2 576.5 108.2C580.7 108.2 584.4 109.6 587.5 112.6C590.4 115.6 592 119.4 592 123.8Z"
            fill="#E20A1C"
          />
        </g>
        <mask
          id="mask3_636_85"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="607"
          y="47"
          width="129"
          height="136"
        >
          <path d="M735.8 47.1H607.9V182.6H735.8V47.1Z" fill="white" />
        </mask>
        <g mask="url(#mask3_636_85)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M696.6 116.4C691.8 118.1 684.8 120.1 675.6 122C666.4 124 660 125.4 656.5 126.3C653 127.2 649.6 129.1 646.4 131.7C643.2 134.4 641.6 138.2 641.6 143C641.6 148 643.5 152.2 647.3 155.7C651 159.2 656 160.9 662.1 160.9C668.6 160.9 674.6 159.4 680.1 156.6C685.6 153.8 689.7 150.1 692.2 145.6C695.1 140.6 696.6 132.4 696.6 121.1V116.4ZM698.8 164C690.8 170.2 683.1 174.8 675.7 177.9C668.3 181 660 182.6 650.7 182.6C642.3 182.6 634.9 180.9 628.5 177.6C622.1 174.2 617.2 169.7 613.7 164C610.3 158.3 608.5 152.1 608.5 145.5C608.5 136.5 611.3 128.8 617.1 122.5C622.8 116.2 630.6 111.9 640.6 109.7C642.7 109.2 647.8 108.1 656.1 106.4C664.4 104.7 671.5 103.2 677.4 101.8C683.3 100.4 689.7 98.7 696.6 96.7C696.2 88 694.5 81.7 691.4 77.6C688.3 73.5 681.9 71.5 672.2 71.5C663.8 71.5 657.6 72.6 653.4 75C649.2 77.3 645.5 80.8 642.6 85.5C639.6 90.2 637.4 93.3 636.2 94.7C635 96.2 632.3 96.9 628.2 96.9C624.5 96.9 621.3 95.7 618.6 93.4C615.9 91 614.6 88 614.6 84.3C614.6 78.5 616.7 72.9 620.7 67.4C624.8 61.9 631.2 57.5 639.9 53.9C648.5 50.4 659.4 48.6 672.3 48.6C686.7 48.6 698.1 50.3 706.4 53.7C714.7 57.1 720.5 62.5 723.9 69.9C727.3 77.3 729 87.1 729 99.3C729 107 728.9 113.6 728.9 118.9C728.9 124.3 728.8 130.3 728.7 136.8C728.7 143 729.8 149.4 731.8 156.1C733.9 162.8 734.9 167.1 734.9 169C734.9 172.4 733.3 175.4 730.1 178.2C726.9 181 723.4 182.4 719.3 182.4C715.9 182.4 712.6 180.8 709.3 177.6C706 174.6 702.5 170 698.8 164Z"
            fill="#E20A1C"
          />
        </g>
        <mask
          id="mask4_636_85"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="749"
          y="47"
          width="115"
          height="136"
        >
          <path d="M863.6 47.1H749.2V182.6H863.6V47.1Z" fill="white" />
        </mask>
        <g mask="url(#mask4_636_85)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M862 139.5C862 148.5 859.8 156.3 855.4 162.8C851 169.2 844.5 174.2 835.8 177.5C827.2 180.8 816.7 182.5 804.3 182.5C792.5 182.5 782.4 180.7 774 177.1C765.6 173.5 759.3 169 755.3 163.5C751.3 158.1 749.3 152.7 749.3 147.2C749.3 143.6 750.6 140.5 753.2 137.9C755.8 135.3 759 134 762.9 134C766.4 134 769 134.8 770.9 136.5C772.7 138.2 774.5 140.6 776.2 143.6C779.5 149.5 783.6 153.8 788.3 156.7C793 159.6 799.4 161 807.5 161C814.1 161 819.5 159.5 823.7 156.6C827.9 153.7 830 150.3 830 146.5C830 140.7 827.8 136.5 823.5 133.9C819.1 131.3 811.9 128.7 801.9 126.3C790.6 123.5 781.4 120.6 774.3 117.4C767.2 114.3 761.5 110.3 757.3 105.2C753 100.1 750.9 93.9 750.9 86.5C750.9 79.9 752.8 73.7 756.8 67.8C760.7 61.9 766.5 57.2 774.2 53.7C781.9 50.2 791.1 48.5 802 48.5C810.5 48.5 818.2 49.4 825 51.2C831.7 53 837.4 55.3 841.9 58.3C846.4 61.3 849.9 64.6 852.2 68.2C854.6 71.8 855.8 75.4 855.8 78.8C855.8 82.6 854.5 85.7 852 88.1C849.5 90.5 845.9 91.7 841.2 91.7C837.8 91.7 834.9 90.7 832.6 88.8C830.2 86.9 827.5 84 824.5 80.1C822 76.9 819.1 74.3 815.7 72.4C812.3 70.5 807.7 69.5 802 69.5C796.1 69.5 791.1 70.8 787.2 73.3C783.3 75.8 781.3 79 781.3 82.8C781.3 86.2 782.7 89.1 785.6 91.3C788.5 93.5 792.4 95.4 797.3 96.8C802.2 98.3 809 100 817.6 102.1C827.8 104.6 836.1 107.5 842.6 111C849.1 114.5 854 118.5 857.3 123.2C860.3 128.1 862 133.5 862 139.5Z"
            fill="#E20A1C"
          />
        </g>
        <mask
          id="mask5_636_85"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="881"
          y="0"
          width="119"
          height="183"
        >
          <path d="M1000 0H881.5V182.6H1000V0Z" fill="white" />
        </mask>
        <g mask="url(#mask5_636_85)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M915.8 19.9V68.7C920 63.9 924 60.1 928 57.2C932 54.4 936.4 52.3 941.2 50.9C946 49.5 951.2 48.8 956.7 48.8C965.1 48.8 972.5 50.6 978.9 54.1C985.4 57.6 990.4 62.8 994.1 69.5C996.4 73.4 998 77.8 998.8 82.7C999.6 87.6 1000 93.2 1000 99.5V162.5C1000 169.1 998.5 174 995.5 177.4C992.5 180.8 988.5 182.5 983.5 182.5C972.7 182.5 967.2 175.9 967.2 162.5V106.9C967.2 96.4 965.7 88.3 962.5 82.7C959.4 77.1 953.4 74.2 944.7 74.2C938.8 74.2 933.5 75.9 928.9 79.2C924.2 82.5 920.7 87.1 918.4 92.8C916.6 97.7 915.7 106.4 915.7 118.9V162.4C915.7 168.9 914.2 173.8 911.3 177.3C908.4 180.7 904.3 182.4 899.1 182.4C888.3 182.4 882.9 175.8 882.9 162.4V19.9C882.9 13.2 884.3 8.20002 887.2 4.90002C890 1.60002 894 -0.0999756 899.2 -0.0999756C904.4 -0.0999756 908.5 1.60003 911.4 5.00003C914.3 8.40003 915.8 13.3 915.8 19.9Z"
            fill="#E20A1C"
          />
        </g>
      </svg>
    )
  }
}
