import AgentOrdersTableRow from './AgentOrdersTableRow'
import { Order } from '@customTypes/purchase'

type AgentOrderTableProps = {
  list: Array<Order>
}

const AgentOrdersTable = ({ list }: AgentOrderTableProps) => {
  return (
    <div className="table">
      <div className="table-header agent-orders-grid-columns">
        <div className="table-cell">Id</div>
        <div className="table-cell">Created Date</div>
        <div className="table-cell">Event Title</div>
        <div className="table-cell">Qty</div>
        <div className="table-cell">Total</div>
        <div className="table-cell"></div>
        <div className="table-cell"></div>
      </div>
      <div className="table-body-agent-events">
        {list.map(order => (
          <AgentOrdersTableRow order={order} key={order.id} />
        ))}
      </div>
    </div>
  )
}

export default AgentOrdersTable
