import Icon from '@components/Icon'
import { useCallback, useState } from 'react'
import logo from '@images/logo-light.svg'
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks'
import authActionCreators from '@actionCreators/authActionCreators'
import { Link } from 'react-router-dom'

const Header = () => {
  const dispatch = useAppDispatch()
  const user = useAppSelector(state => state.user.user)

  const logOut = useCallback(async () => {
    dispatch(authActionCreators.logOut())
  }, [dispatch])

  return (
    <header className="main-header">
      <Link className="main-header-logo" to={`/`}>
        <img src={logo} alt="" />
      </Link>
      <div className="main-header-actions">
        {user && (
          <button onClick={logOut} className="icon-button transparent">
            <Icon name={'logout'} size={24} />
          </button>
        )}
      </div>
    </header>
  )
}

export default Header
