import React from 'react'
import Icons from '@images/icon-set.svg'

type IconProps = {
  name: string
  size: number
  style?: Record<string, string>
}

const Icon = ({ name, size, style }: IconProps) => (
  <svg width={size} height={size} {...(style && { style })}>
    <use xlinkHref={`${Icons}#${name}`} />
  </svg>
)
export default Icon
