import ReactDOM from 'react-dom/client'
import '@fontsource/inter/900.css'
import '@fontsource/inter/600.css'
import '@fontsource/inter/400.css'
import '@fontsource/inter/200.css'
import '@styles/main.scss'
import App from './App'
import { Provider } from 'react-redux'
import { store } from './store'

ReactDOM.createRoot(document.getElementById('root')!).render(
  <Provider store={store}>
    <App />
  </Provider>
)
