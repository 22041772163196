import eventActionCreators from '@actionCreators/eventActionCreators'
import Icon from '@components/Icon'
import PaymentOptionList from '@components/event/paymentSection/PaymentOptionList'
import CardPaymentWrapper from '@components/event/paymentSection/payViaCard/CardPaymentWrapper'
import PayViaInnBucks from '@components/event/paymentSection/payViaInnBucks/PayViaInnBucks'
import PayViaMobileMoney from '@components/event/paymentSection/payViaMobileMoney/PayViaMobileMoney'
import PayViaVoucher from '@components/event/paymentSection/payViaVoucher/PayViaVoucher'
import UsingVoucherForm from '@components/event/voucherSection/UsingVoucherForm'
import Loader from '@components/ui/Loader'
import { Event, Product } from '@customTypes/event'
import { PaymentSectionState } from '@customTypes/purchase'
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks'
import { clearCurrentEvent } from '@sliceActionCreators/eventSlice'
import { setSelectedProductToStore } from '@sliceActionCreators/purchaseSlice'
import localStorageData from '@utils/localStorage'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Navigate, useNavigate, useParams } from 'react-router-dom'

const EventPaymentScreen = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { id } = useParams()

  const getEventByIdIsLoading = useAppSelector(state => state.event.getEventByIdLoadState.isLoading)

  const [currentPaymentSection, setCurrentPaymentSection] =
    useState<PaymentSectionState>('payment-option-list')
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null)
  const [isSubscribedChecked, setIsSubscribedChecked] = useState(true)

  const setProductToStore = useCallback(
    (event: Event) => {
      const selectedProductIdFromLocalStorage = localStorageData.getData('selectedProductId')
      const selectedProduct =
        event?.products.find(product => product.id === Number(selectedProductIdFromLocalStorage)) ||
        null

      dispatch(setSelectedProductToStore(selectedProduct))
      setSelectedProduct(selectedProduct)
    },
    [dispatch]
  )

  useEffect(() => {
    if (id) {
      dispatch(eventActionCreators.getEventById(id))
        .unwrap()
        .then(res => setProductToStore(res))
        .catch(err => {
          // console.log(err)
          // navigate('/')
        })
    } else {
      navigate('/')
    }

    return () => {
      dispatch(clearCurrentEvent())
    }
  }, [dispatch, id, navigate, setProductToStore])

  const renderView = useMemo(() => {
    switch (currentPaymentSection) {
      case 'payment-option-list':
        return (
          <PaymentOptionList
            isSubscribed={isSubscribedChecked}
            setCurrentPaymentSection={setCurrentPaymentSection}
          />
        )
      case 'voucher-payment':
        return <PayViaVoucher isSubscribed={isSubscribedChecked} />
      case 'card':
        return <CardPaymentWrapper />
      case 'mobile-payment':
        return <PayViaMobileMoney isSubscribed={isSubscribedChecked} />
      case 'innBucks':
        return <PayViaInnBucks isSubscribed={isSubscribedChecked} />
    }
  }, [currentPaymentSection, isSubscribedChecked])

  const onBack = useCallback(() => {
    switch (currentPaymentSection) {
      case 'payment-option-list':
        navigate(`/events/${id}`)
        break
      case 'card':
      case 'mobile-payment':
      case 'innBucks':
      case 'voucher-payment':
        setCurrentPaymentSection('payment-option-list')
        break
      // case 'mobile-payment':
      //   setCurrentPaymentSection('payment-option-list')
      //   break
    }
  }, [currentPaymentSection, id, navigate])

  const onSubscribeCheckboxClick = useCallback(() => {
    setIsSubscribedChecked(prev => !prev)
  }, [])

  if (selectedProduct && selectedProduct.isPurchased) {
    return <Navigate to={`/events/${id}`} />
  }

  return (
    <>
      {getEventByIdIsLoading && <Loader className="spinner-fixed" />}
      {!getEventByIdIsLoading && (
        <>
          <section className="event-detail-section page-navigation-section">
            <button className="icon-button transparent auto-size" onClick={onBack}>
              <Icon name="arrow-right" size={20} />
            </button>
          </section>
          <div className="centered-page-dialog ms-box order">
            <div className="order-product-info">
              <div>
                <p>{selectedProduct?.title}</p>
                <p>{selectedProduct?.shortDescription}</p>
              </div>
              <div></div>
            </div>
            <div className="order-total">
              <dl className="align-items-center">
                <dt className="display-flex align-items-center gap-8">
                  <Icon name="cart" size={20} />
                  Total cost
                </dt>
                <dd>
                  {selectedProduct?.price
                    ? `${selectedProduct.currency.sign}${Number(selectedProduct?.price).toFixed(2)}`
                    : ''}
                </dd>
              </dl>
            </div>
            {/* {currentPaymentSection === 'payment-option-list' && (
              <label className="checkbox checkbox-px-16">
                <input
                  type="checkbox"
                  onChange={onSubscribeCheckboxClick}
                  checked={isSubscribedChecked}
                />
                <div className="checkbox__body">Subscribe</div>
                <div className="checkbox__checkmark checkbox__checkmark-px-16"></div>
              </label>
            )} */}
            {renderView}
          </div>
        </>
      )}
    </>
  )
}

export default EventPaymentScreen
