import { useCallback, useEffect, useState } from 'react'
import { BecomeAgentDataForm } from '@customTypes/agent'
import { yupResolver } from '@hookform/resolvers/yup'
import isEmpty from 'lodash/isEmpty'
import { useForm } from 'react-hook-form'
import TextFieldForm from '@components/ui/TextFieldForm'
import { trimObjectFields } from '@utils/common'
import agentYupSchemes from '@utils/yupSchemes/agentYupSchemes'
import { useAppDispatch } from '@hooks/reduxHooks'
import agentActionCreators from '@actionCreators/agentActionCreators'
import Icon from '@components/Icon'
import { useNavigate } from 'react-router-dom'

const defaultDataForm = {
  firstName: '',
  lastName: '',
  email: '',
  address: '',
  phoneNumber: '',
  documentNumber: '',
}

const BecomeAgentForm = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const [isDisabledButton, setIsDisabledButton] = useState(false)

  const { handleSubmit, control, formState, reset } = useForm<BecomeAgentDataForm>({
    resolver: yupResolver(agentYupSchemes.becomeAgentFormScheme),
  })

  useEffect(() => {
    if (isEmpty(formState.errors)) {
      setIsDisabledButton(false)
    } else {
      setIsDisabledButton(true)
    }
  }, [formState])

  const onSubmit = (dataForm: BecomeAgentDataForm) => {
    const email = dataForm.email || ''
    const trimmedDataForm = trimObjectFields({ ...dataForm, email }) as BecomeAgentDataForm

    dispatch(agentActionCreators.addNewAgent(trimmedDataForm))
      .unwrap()
      .then(() => {
        reset(defaultDataForm)
      })
  }

  const onBack = useCallback(() => {
    navigate('/')
  }, [navigate])

  return (
    <>
      <div className="form-title">
        <button className="icon-button transparent back-button" onClick={onBack}>
          <Icon name="arrow-right" size={20} />
        </button>
        <h4>Agent SignUp</h4>
      </div>
      <form
        id="become-agent-form"
        onSubmit={handleSubmit(onSubmit)}
        style={{ marginBottom: '48px' }}
      >
        <TextFieldForm
          name="firstName"
          control={control}
          placeholder="Enter First Name"
          type="string"
          label="First Name"
          maxLength={64}
        />
        <TextFieldForm
          name="lastName"
          control={control}
          placeholder="Enter Last Name"
          type="string"
          label="Last Name"
          maxLength={64}
        />
        <TextFieldForm
          name="email"
          control={control}
          placeholder="Enter Email"
          type="string"
          label="Email"
          maxLength={64}
        />
        <TextFieldForm
          name="address"
          control={control}
          placeholder="Enter Address"
          type="string"
          label="Address"
          maxLength={64}
        />
        <TextFieldForm
          name="phoneNumber"
          control={control}
          placeholder="Enter Phone Number"
          type="tel"
          label="Phone Number"
          maxLength={15}
        />
        <TextFieldForm
          name="documentNumber"
          control={control}
          placeholder="Enter ID Number "
          type="string"
          label="ID Number"
          maxLength={32}
        />
      </form>
      <button
        form="become-agent-form"
        className="button primary large w-100"
        type="submit"
        disabled={isDisabledButton}
      >
        Sign In
      </button>
    </>
  )
}

export default BecomeAgentForm
