import parseISO from "date-fns-new/parseISO"
import format from "date-fns-tz/format"
import { format as formatDateFns } from 'date-fns-new'
import utcToZonedTime from "date-fns-tz/utcToZonedTime"


const formatInTimeZone = (date: string, fmt: string, tz: string) => format(
  utcToZonedTime(date, tz), fmt, { timeZone: tz }
)

const formatDate = (date: string, fmt: string) =>
  date ? formatDateFns(new Date(date.replace('Z', '')), fmt) : ''


const dateFormat = {
  formatInTimeZone,
  formatDate,
}

export default dateFormat