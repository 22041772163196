import Icon from '@components/Icon'
import React, { useCallback } from 'react'
import AgentCreateOrderTable from './AgentCreateOrderTable'
import { useNavigate } from 'react-router-dom'

const AgentCreateOrderPage = () => {
  const navigate = useNavigate()

  const onBack = useCallback(() => {
    navigate('/agent')
  }, [navigate])
  return (
    <div>
      <section className="event-detail-section page-navigation-section" style={{ gap: '8px' }}>
        <button className="icon-button transparent auto-size" onClick={onBack}>
          <Icon name="arrow-right" size={20} />
        </button>
        Create Order
      </section>
      <AgentCreateOrderTable />
    </div>
  )
}

export default AgentCreateOrderPage
