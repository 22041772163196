import { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import Icon from './Icon'
import Hearts from './event/videoPlayer/Hearts'
import Chat from './event/videoPlayer/Chat'
import { useAppDispatch } from '@hooks/reduxHooks'
import { ChatMessage } from '@customTypes/chat'
import { Slide, toast } from 'react-toastify'
import ShareSocialMediaList from './event/shareSection/ShareSocialMediaList'
import { useParams } from 'react-router-dom'

const isIos = () => {
  const toMatch = [/iPhone/i, /iPad/i, /iPod/i]

  return toMatch.some(toMatchItem => navigator.userAgent.match(toMatchItem))
}

type VideoPlayerProps = {
  countDownTime: string
  currentUrl: string
  localLikesCountRef: MutableRefObject<number>
  chatMessageList: Array<ChatMessage>
  currentMessageCounter: number
}

const VideoPlayer = ({
  countDownTime,
  currentUrl,
  localLikesCountRef,
  chatMessageList,
  currentMessageCounter,
}: VideoPlayerProps) => {
  const { id } = useParams()

  const videoPlayerRef = useRef<HTMLVideoElement>(null)
  const intervalRef = useRef<NodeJS.Timeout | null>(null)
  const playerWrapperRef = useRef<HTMLDivElement>(null)

  // const [volumeValue, setVolumeValue] = useState(0)
  // const [userVolumeValue, setUserVolumeValue] = useState(0)
  const [videoProgress, setVideoProgress] = useState(0)
  const [currentVideoTime, setCurrentVideoTime] = useState('0:00')
  const [durationVideoTime, setDurationVideoTime] = useState('0:00')

  const [isShownVideoLength, setShownVideoLength] = useState(false)
  const [isFullScreen, setIsFullScreen] = useState(false)
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isChatVisible, setChatVisible] = useState(false)
  const [isMuteOff, setMuteOff] = useState(true)
  const [isShownShare, setIsShownShare] = useState(false)
  const [isShowPlayBtn, setIsShowPlayBtn] = useState(false)
  const [isPortrait, setIsPortrait] = useState(false)

  const onChangeOrientation = useCallback((event: any) => {
    const portrait = event.matches

    setIsPortrait(portrait)
  }, [])

  useEffect(() => {
    const portrait = window.matchMedia('(orientation: portrait)').matches

    setIsPortrait(portrait)
  }, [])

  useEffect(() => {
    window
      .matchMedia('(orientation: portrait)')
      .addEventListener('change', onChangeOrientation, { capture: true })

    return () => {
      window.removeEventListener('click', onChangeOrientation, { capture: true })
    }
  }, [onChangeOrientation])

  // useEffect(() => {
  //   if (videoPlayerRef?.current) {
  //     videoPlayerRef.current.volume = 0 / 100
  //   }
  // }, [])

  // const onPlay = useCallback(() => {
  //   videoPlayerRef?.current?.play()
  //   // }
  // }, [])

  const currentTimeForPlayer = useMemo(() => {
    const remainingTimeMs = new Date(countDownTime).getTime() - new Date().getTime()

    return Math.abs(Math.floor(remainingTimeMs / 1000))
  }, [countDownTime])

  useEffect(() => {
    if (videoPlayerRef.current) {
      videoPlayerRef.current.currentTime = currentTimeForPlayer
    }
    videoPlayerRef?.current?.load()
    // runVideo()
  }, [currentTimeForPlayer])

  // const onChangeVolume = useCallback((event: ChangeEvent<HTMLInputElement>) => {
  //   const value = Number(event.target.value)

  //   setVolumeValue(value)
  //   setUserVolumeValue(value)
  //   if (videoPlayerRef?.current) {
  //     videoPlayerRef.current.volume = value / 100
  //   }
  // }, [])

  const onMute = useCallback(() => {
    if (videoPlayerRef.current) {
      videoPlayerRef.current.muted = !videoPlayerRef.current.muted
    }

    setMuteOff(prev => !prev)
  }, [])

  const onTimeUpdate = useCallback(() => {
    if (videoPlayerRef.current) {
      const currentTime = videoPlayerRef.current.currentTime
      const currentMinutes = Math.floor(currentTime / 60)
      const currentSeconds = String(Math.floor(currentTime - currentMinutes * 60)).padStart(2, '0')
      const currentTimeString = `${currentMinutes}:${currentSeconds}`

      const durationTime = videoPlayerRef.current.duration
      const durationMinutes = Math.floor(durationTime / 60)
      const durationSeconds = String(Math.floor(durationTime - durationMinutes * 60)).padStart(
        2,
        '0'
      )
      const durationTimeString = `${durationMinutes}:${durationSeconds}`

      const currentProgress = (currentTime / durationTime) * 100

      setCurrentVideoTime(currentTimeString)
      setDurationVideoTime(durationTimeString)
      setVideoProgress(currentProgress)
    }
  }, [])

  const onClickVideo = useCallback(() => {
    setShownVideoLength(true)

    setTimeout(() => setShownVideoLength(false), 2000)
  }, [])

  const onFullScreenVideo = useCallback(() => {
    const container = videoPlayerRef.current

    if (isIos()) {
      if (isPortrait) {
        setIsFullScreen(prev => !prev)
        return
      } else {
        // videoPlayerRef.current?.requestFullscreen()
        // return

        if (container?.requestFullscreen) {
          container!.requestFullscreen()
          return
        }
        //@ts-ignore
        if (container.webkitRequestFullscreen) {
          //@ts-ignore
          container!.webkitRequestFullscreen()
          return
        }

        //@ts-ignore
        if (container.webkitEnterFullScreen) {
          //@ts-ignore
          container.webkitEnterFullScreen()
          return
        }

        //@ts-ignore
        if (container.webkitEnterFullscreen) {
          //@ts-ignore
          container.webkitEnterFullscreen()
          return
        }

        //@ts-ignore
        if (container.webkitRequestFullscreen) {
          //@ts-ignore
          container.webkitRequestFullscreen()
          return
        }

        //@ts-ignore
        if (container.webkitEnterFullScreen) {
          //@ts-ignore
          container.webkitEnterFullScreen()
          return
        }

        //@ts-ignore
        if (container.webkitEnterFullscreen) {
          //@ts-ignore
          container.webkitEnterFullscreen()
          return
        }
      }
    } else {
      const isFullScreen = !!document.fullscreenElement

      if (!isFullScreen) {
        playerWrapperRef.current?.requestFullscreen()
      } else {
        document.exitFullscreen()
      }
    }
  }, [isPortrait])

  const onClickMenu = useCallback(() => {
    setIsMenuOpen(prev => !prev)
  }, [])

  const onFullscreenChanged = useCallback(() => {
    const isFullScreen = !!document.fullscreenElement

    if (!isIos()) {
      if (isFullScreen) {
        setIsFullScreen(true)
      } else {
        setIsFullScreen(false)
      }
    }

    
  }, [])

  useEffect(() => {
    document.addEventListener('fullscreenchange', onFullscreenChanged)

    return () => {
      document.removeEventListener('fullscreenchange', onFullscreenChanged)
    }
  }, [onFullscreenChanged])

  const onClickChat = useCallback(() => {
    setChatVisible(prev => !prev)
  }, [])

  // const onAbort = useCallback(() => {
  //   console.log('onAbort')
  // }, [])

  // const onError = useCallback((event: any) => {
  //   console.log('onError')
  //   console.log(videoPlayerRef.current?.error)
  //   console.log(videoPlayerRef.current?.networkState)
  // }, [])

  // const onLoadedData = useCallback(() => {
  //   console.log('onLoadedData')
  // }, [])

  const onCloseShare = useCallback(() => {
    setIsShownShare(false)
  }, [])

  const showToastMessage = useCallback(() => {
    if (!isShownShare) {
      {
        toast(<ShareSocialMediaList linkPart={id || ''} />, {
          position: 'bottom-center',
          // className: 'share-toastify',
          draggable: true,
          transition: Slide,
          autoClose: false,
          closeOnClick: false,
          draggableDirection: 'y',
          draggablePercent: 40,
          onClose: onCloseShare,
        })
        setIsShownShare(true)
      }
    }
  }, [id, isShownShare, onCloseShare])

  const onPlay = useCallback(() => {
    if (videoPlayerRef?.current) {
      videoPlayerRef?.current.play()
      setIsShowPlayBtn(false)
    }
  }, [])

  const onPauseBtn = () => {
    if (videoPlayerRef?.current) {
      videoPlayerRef?.current.pause()
    }
  }

  const onPauseVideo = useCallback(() => {
    setIsShowPlayBtn(true)
  }, [])

  return (
    <div className="span-all-areas">
      <div className="w-100">
        <div
          className={`player-grid ${isFullScreen ? 'full-screen' : ''} ${
            isChatVisible ? 'chat-active' : ''
          }`}
          ref={playerWrapperRef}
        >
          <div className={`player ${isFullScreen ? 'full-screen' : ''}`}>
            <div className="player-actions top-left">
              <div className="button-group flex-direction-column">
                <button onClick={onMute} className="icon-button rounded dark-transparent">
                  <Icon name={isMuteOff ? 'mute' : 'unmute'} size={20} />
                </button>
                <button
                  onClick={onFullScreenVideo}
                  className="icon-button rounded dark-transparent"
                >
                  <Icon name="fullscreen" size={20} />
                </button>
                {isShowPlayBtn && (
                  <button onClick={onPlay} className="icon-button rounded dark-transparent">
                    <Icon name="play" size={20} />
                  </button>
                )}
              </div>
            </div>
            <div className="player-actions top-right">
              <div className="button-group flex-direction-column">
                <button onClick={onClickMenu} className="icon-button rounded dark-transparent">
                  <Icon name={isMenuOpen ? 'close' : 'hamburger'} size={20} />
                </button>
                {!document.fullscreenElement && (
                  <button
                    className={`icon-button rounded dark-transparent animated fadeIn${
                      isMenuOpen ? '' : ' is-hidden'
                    }`}
                    onClick={showToastMessage}
                  >
                    <Icon name="share" size={20} />
                  </button>
                )}
                <button
                  onClick={onClickChat}
                  className={`icon-button rounded dark-transparent animated fadeIn delay-1s${
                    isMenuOpen ? '' : ' is-hidden'
                  }`}
                >
                  <Icon name="chat" size={20} />
                </button>
              </div>
            </div>
            <div className="player-actions bottom-right">
              <div className="button-group align-items-center">
                <Hearts localLikesCountRef={localLikesCountRef} />
              </div>
            </div>
            <div className="player-actions bottom-left player-progress">
              {isShownVideoLength && (
                <>
                  <progress max="100" value={videoProgress}></progress>{' '}
                  <span>{`${currentVideoTime} / ${durationVideoTime}`}</span>
                </>
              )}
            </div>
            <div className="video-container">
              <video
                ref={videoPlayerRef}
                onTimeUpdate={onTimeUpdate}
                onClick={onClickVideo}
                // onLoadedData={onLoadedData}
                controls={false}
                playsInline
                muted
                autoPlay
                src={currentUrl}
                onPause={onPauseVideo}
              />
            </div>
          </div>
          {/* <button onClick={onPauseBtn}>Pause</button> */}

          <Chat
            isChatVisible={isChatVisible}
            chatMessageList={chatMessageList}
            localLikesCountRef={localLikesCountRef}
            currentMessageCounter={currentMessageCounter}
            isFullScreen={isFullScreen}
            setChatVisible={setChatVisible}
          />
        </div>
      </div>
    </div>
  )
}

export default VideoPlayer
